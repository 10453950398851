import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { AppNavigatorService } from 'app/core/services/app-navigator.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { isVersion2 } from '@app/PlexeUtils';

/**
 *  This guard is responsible for checking whether a Customer is connected
 *  and its details are available at the DataStore.
 */
@Injectable({ providedIn: 'root' })
export class CustomerAuthenticationGuard implements CanLoad {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appNavigatorService: AppNavigatorService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    var newApplication = sessionStorage.getItem('public_new_application_start_form_data');
    var isAuthenticated =
      this.authenticationService.isAuthenticated() && this.authenticationService.isAuthenticatedValid();
    if (
      !this.authenticationService.isCustomer() ||
      (!isAuthenticated && !this.authenticationService.getSelectedAppData()?.id && !JSON.parse(newApplication))
    ) {
      this.appNavigatorService.navigateLogin();
      return false;
    }

    if (
      isVersion2(route.path) &&
      isAuthenticated &&
      !this.authenticationService.getSelectedAppData()?.id &&
      !JSON.parse(newApplication)
    ) {
      this.appNavigatorService.navigateOverview();
      return false;
    }

    return true;
  }
}
