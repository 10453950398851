import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '@app/shared/loader/loader.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';
import { getTimeout } from '@app/PlexeUtils';
import { takeUntil } from 'rxjs/internal/operators';
import { Subject } from 'rxjs';
import { SystemService } from '@app/core/services/system.service';
import { DataStore } from '@app/DataStore';

@Component({
  selector: 'app-load-data',
  templateUrl: './load-data.component.html',
  styleUrls: ['./load-data.component.scss']
})
export class LoadDataComponent implements OnInit, OnDestroy {
  private isDestroyed = new Subject();

  constructor(
    private systemService: SystemService,
    private loaderService: LoaderService,
    private navService: AppNavigatorService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  get promises() {
    return DataStore.loadingPromises;
  }

  get loadingCallbackUrl() {
    return DataStore.loadingCallbackUrl;
  }

  ngOnInit(): void {
    this.loaderService.setStatus(true);
    this.loaderService.setMessage('Loading...');

    getTimeout()
      .pipe(takeUntil(this.isDestroyed))
      .subscribe(_ => {
        this.router.navigate(['error-timeout']);
      });

    const fallbackUrl = this.route.snapshot.queryParamMap.get('fallback');

    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      const refresh = paramMap.get('refresh');
      if (refresh) {
        const loadingPromises = [...this.promises];

        this.systemService.resetLoadingPromises();

        if (loadingPromises.length > 0) {
          Promise.all(loadingPromises)
            .then(_ => {
              this.router.navigateByUrl(this.loadingCallbackUrl || fallbackUrl, { replaceUrl: true });
            })
            .catch(err => {
              let url = [];
              if (loadingPromises) {
                loadingPromises.forEach(item => {
                  url.push(item.catch(err => err));
                });
              }
              this.navService.navigateError(
                new Error('Refresh data. ' + err + ' ' + JSON.stringify(loadingPromises) + ' ' + JSON.stringify(url))
              );
            });
        } else {
          this.router.navigateByUrl(fallbackUrl, { replaceUrl: true });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.isDestroyed.next();
  }
}
