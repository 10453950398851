import { Injectable } from '@angular/core';
import { OriginationReadApi } from '@app/core/proxy/Read/originationReadApi';
import { Data, DataState, DataStore, Filter } from '@app/DataStore';
import { getDomainUrl } from '@app/PlexeUtils';
import { PAGE_SIZE, SignalREvents } from '@app/Constants';
import { HttpClient } from '@angular/common/http';
import { IpService } from '@app/core/services/ip.service';
import {
  AdvanceProjection,
  CashflowDetails,
  LoanBalances,
  LoanDebtorProjection,
  LoanNotificationProjection,
  LoanSettings,
  TransactionProjection,
  IncomeDetails,
  DisplayMessageModal,
  QuestionMessageModal,
  ApplicationListProjection,
  CustomerLoanProjection
} from '@app/core/proxy/Read/models';
import { EMPTY, from, Observable, race, Subject, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/internal/operators';
import { OriginationWriteApi } from '@app/core/proxy/Write/originationWriteApi';
import { SignalREvent, SignalrService } from './signalr.service';
import { CSVExportType } from '@app/shared/components/export-to-csv/export-to-csv.component';
import {
  WithdrawFixedRequest,
  WithdrawInvoiceRequest,
  WithdrawLineOfCreditRequest,
  WithdrawPercentageRequest
} from '../proxy/Write/models';
import { request } from 'http';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class LoanService {
  private loadSummarySubject = new Subject<string>();
  private loadIsBankSetupSubject = new Subject<string>();
  private loadCashflowSubject = new Subject<string>();
  private loadAccountingInfoSubject = new Subject<string>();
  private loadIncomeDetailsSubject = new Subject<string>();
  private incomeDetailsStatusSubject = new Subject<boolean>();
  private loadAuditRequiredSubject = new Subject<string>();
  private loadIsChangeOfFacilityRequiredSubject = new Subject<string>();
  private loadEmployeeMenuStatusSubject = new Subject<string>();
  ipAddress: string;

  constructor(
    private readApi: OriginationReadApi,
    private writeApi: OriginationWriteApi,
    private http: HttpClient,
    private ipservice: IpService,
    private signalrService: SignalrService,
    private toastrService: ToastrService
  ) {
    this.ipservice.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });

    this.loadSummarySubject
      .asObservable()
      .pipe(
        tap(_ => this.setLoanSummary({ state: DataState.Loading })),
        switchMap(loanId =>
          from(this.readApi.getLoanBalances(loanId)).pipe(
            tap(res => this.setLoanSummary({ data: res, state: DataState.Loaded })),
            catchError(err => {
              this.setLoanSummary({ data: null, state: DataState.Error });
              return EMPTY;
            })
          )
        )
      )
      .subscribe(_ => {});

    this.loadIsBankSetupSubject
      .asObservable()
      .pipe(
        tap(_ => this.setIsBankSetup({ state: DataState.Loading })),
        switchMap(loanId =>
          from(this.readApi.isBankSetup(loanId)).pipe(
            tap(res => this.setIsBankSetup({ data: res.value, state: DataState.Loaded })),
            catchError(err => {
              this.setIsBankSetup({ data: null, state: DataState.Error });
              return EMPTY;
            })
          )
        )
      )
      .subscribe(_ => {});

    this.loadIsChangeOfFacilityRequiredSubject
      .asObservable()
      .pipe(
        tap(_ => this.setIsChangeOfFacilityRequired({ state: DataState.Loading })),
        switchMap(loanId =>
          from(this.readApi.isChangeOfFacilityRequired(loanId)).pipe(
            tap(res => this.setIsChangeOfFacilityRequired({ data: res.value, state: DataState.Loaded })),
            catchError(err => {
              this.setIsChangeOfFacilityRequired({ data: null, state: DataState.Error });
              return EMPTY;
            })
          )
        )
      )
      .subscribe(_ => {});

    this.loadCashflowSubject
      .asObservable()
      .pipe(
        tap(_ => this.setCashflowDetails({ state: DataState.Loading })),
        switchMap(loanId =>
          from(this.readApi.cashflowDetailsMethod(loanId)).pipe(
            tap(res => this.setCashflowDetails({ data: res, state: DataState.Loaded })),
            catchError(err => {
              this.setCashflowDetails({ data: null, state: DataState.Error });
              return EMPTY;
            })
          )
        )
      )
      .subscribe(_ => {});

    this.loadAccountingInfoSubject
      .asObservable()
      .pipe(
        tap(_ => {
          this.setIsConnectedToAccountingSystem({ state: DataState.Loading });
          this.setReconnectAccountingSystemUrl({ state: DataState.NotLoaded });
          this.setIsSyncing(false);
        }),
        switchMap(loanId =>
          from(this.readApi.isAccountingSystemConnected(loanId)).pipe(
            tap(res => this.setIsConnectedToAccountingSystem({ data: res.body, state: DataState.Loaded })),
            map(res => {
              return { loanId, isConnected: res.body };
            }),
            catchError(err => {
              this.setIsConnectedToAccountingSystem({ data: null, state: DataState.Error });
              return EMPTY;
            })
          )
        ),
        switchMap(res => {
          this.readApi
            .getAccountingSystem(res.loanId)
            .then(accounting => {
              if (accounting) {
                this.setAccountingSystem(accounting.body);
              }
            })
            .catch(err => {
              return EMPTY;
            });
          if (res.isConnected === 'connected') {
            // this.setIsSyncing(true);
            // return (<Observable<any>>race(timer(300000), from(this.writeApi.loginProcess(res.loanId)))).pipe(
            //   tap(_ => this.setIsSyncing(false))
            // );
          } else if (res.isConnected === 'disconnected') {
            return from(this.readApi.getReconnectAccountingAuthorizeUrl(res.loanId, { host: getDomainUrl() })).pipe(
              tap(str => this.setReconnectAccountingSystemUrl({ data: str.body, state: DataState.Loaded })),
              catchError(err => {
                this.setReconnectAccountingSystemUrl({ data: null, state: DataState.Error });
                return EMPTY;
              })
            );
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe(_ => {});

    this.incomeDetailsStatusSubject.next(false);

    this.loadIncomeDetailsSubject
      .asObservable()
      .pipe(
        tap(_ => this.setIncomeDetails({ state: DataState.Loading })),
        switchMap(loanId =>
          from(this.readApi.incomeDetailsMethod(loanId)).pipe(
            tap(res => {
              this.setIncomeDetails({ data: res, state: DataState.Loaded });
              this.incomeDetailsStatusSubject.next(true);
            }),
            catchError(err => {
              this.setIncomeDetails({ data: null, state: DataState.Error });
              return EMPTY;
            })
          )
        )
      )
      .subscribe(_ => {});

    /*
    this.loadAuditRequiredSubject
      .asObservable()
      .pipe(
        tap(_ => this.setAuditRequired(false)),
        switchMap(loanId =>
          from(this.readApi.isAuditRequired(loanId)).pipe(
            tap(res => this.setAuditRequired(res.body)),
            catchError(err => {
              return EMPTY;
            })
          )
        )
      )
      .subscribe(_ => {});
*/
    /*
    this.loadEmployeeMenuStatusSubject
      .asObservable()
      .pipe(
        tap(_ => this.setEmployeeMenuStatus(false)),
        switchMap(loanId =>
          from(this.readApi.isEmployeeMenuEnabled(loanId, { providerType: 1 })).pipe(
            tap(res => this.setEmployeeMenuStatus(res.body)),
            catchError(err => {
              return EMPTY;
            })
          )
        )
      )
      .subscribe(_ => {});*/
  }

  //  ____   ___  ____ _____
  // |  _ \ / _ \/ ___|_   _|
  // | |_) | | | \___ \ | |
  // |  __/| |_| |___) || |
  // |_|    \___/|____/ |_|

  sendOtpByLoanId(loanId: string) {
    return this.writeApi
      .sendOtp1(loanId)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        return null;
      });
  }

  readLoanNotification(loanId: string, notificationId: string) {
    return this.writeApi
      .readLoanNotification(loanId, notificationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  enableShowAllDebtor(loanId: string, state: boolean) {
    return this.writeApi
      .enableShowAllDebtor(loanId, state)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  withdrawAsInvoicePayment(loanId: string, params: { body: WithdrawInvoiceRequest }) {
    return this.writeApi
      .withdrawAsInvoicePayment(loanId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  withdrawLineOfCredit(loanId: string, params: { body: WithdrawLineOfCreditRequest }) {
    params.body.ipAddress = this.ipAddress;

    return this.writeApi
      .withdrawLineOfCredit(loanId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  withdrawAsPercentage(loanId: string, params: { body: WithdrawPercentageRequest }) {
    return this.writeApi
      .withdrawAsPercentage(loanId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  withdrawFixed(loanId: string, params: { body: WithdrawFixedRequest }) {
    return this.writeApi
      .withdrawFixed(loanId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  enableDebtor(loanId: string, debtorId: string) {
    return this.writeApi
      .enableDebtor(loanId, debtorId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  registerSkipRepaymentDetails(loanId: string, params: { advanceId: string }) {
    return this.writeApi
      .registerSkipRepaymentDetails(loanId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  registerEarlyRepaymentDetails(loanId: string, params: { advanceId: string }) {
    return this.writeApi
      .registerEarlyRepaymentDetails(loanId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  disconnectAccountingSystem(loanId: string) {
    this.setIsConnectedToAccountingSystem(null);
    return this.writeApi
      .disconnectAccountingSystem(loanId)
      .then(_ => this.setIsConnectedToAccountingSystem({ data: 'disconnected' }))
      .catch(err => {
        this.setIsConnectedToAccountingSystem(null);
        return null;
      });
  }

  //   ____ _____ _____
  //  / ___| ____|_   _|
  // | |  _|  _|   | |
  // | |_| | |___  | |
  //  \____|_____| |_|

  loadBankTransactionDataStale(loanId: string) {
    this.setBankTransactionDataStale(false);
    return this.readApi
      .isBankTransactionDataStale(loanId)
      .then(res => {
        if (res && res._response) {
          this.setBankTransactionDataStale(res._response.parsedBody);
          return res._response.parsedBody;
        }
        return false;
      })
      .catch(err => {
        this.setBankTransactionDataStale(false);
        return err;
      });
  }

  loadBankTransactionDataStaleMessage(loanId: string) {
    this.setBankTransactionDataStaleMessage(null);
    return this.readApi
      .getBankTransactionDataStaleMessage(loanId)
      .then(res => {
        if (res && res._response) {
          this.setBankTransactionDataStaleMessage(res._response.parsedBody);
          return res._response.parsedBody;
        }
        return null;
      })
      .catch(err => {
        this.setBankTransactionDataStaleMessage(null);
        return err;
      });
  }

  downloadTransactionStatement(loanId: string, params: CSVExportType) {
    return this.readApi
      .downloadTransactionStatement(loanId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  downloadTransactionFile(loanId: string, params: CSVExportType) {
    return this.readApi
      .downloadTransactionFile(loanId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  downloadContractFileOnDocumentId(documentId: string) {
    return this.readApi
      .downloadContractFileOnDocumentId(documentId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  subscribeIncomeDetails() {
    return this.incomeDetailsStatusSubject;
  }

  loadLoanTransactions(loanId: string, filter?: Filter) {
    filter = !filter ? DataStore.customerData.displayedLoan.transactions.filter : filter;
    this.setLoanTransactions({ data: null, state: DataState.Loading, filter: filter, total: 0, dataSummary: null });

    return this.readApi
      .transactionSummary(
        loanId,
        Object.assign(filter, { skip: filter.page * PAGE_SIZE, take: PAGE_SIZE, showPaymentRequests: false })
      )
      .then(res => {
        this.setLoanTransactions({
          data: res.data,
          state: DataState.Loaded,
          filter: filter,
          total: res.totalCount,
          dataSummary: filter.page == 0 ? res.data : null
        });
        return res;
      })
      .catch(err => {
        this.setLoanTransactions({ data: null, state: DataState.Error, filter: filter, total: 0, dataSummary: null });
        return null;
      });
  }

  loadLoanAdvances(loanId: string, take = 100) {
    this.setLoanAdvances({ data: null, state: DataState.Loading });

    return this.readApi
      .advanceFullSummary(loanId, { take: take })
      .then(res => {
        this.setLoanAdvances({ data: res.data, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setLoanAdvances({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadLoanSettings(loanId: string) {
    this.setLoanSettings({ data: null, state: DataState.Loading });

    return this.readApi
      .getLoanSettings(loanId)
      .then(res => {
        res.showAllDebtors = true;
        this.setLoanSettings({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setLoanSettings({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadLoanNotifications(loanId: string) {
    this.setLoanNotifications({ data: null, state: DataState.Loading });

    return this.readApi
      .getLoanNotifications(loanId, { take: 30 })
      .then(res => {
        this.setLoanNotifications({ data: res.data, state: DataState.Loaded });
        return res.data;
      })
      .catch(err => {
        this.setLoanNotifications({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadLoanDebtorSummary(loanId: string) {
    this.setLoanDebtorSummary({ data: null, state: DataState.Loading });

    return this.readApi
      .debtorSummary(loanId, { take: 500 })
      .then(res => {
        const data = res.data.map(v => Object.assign(v, { collapse: false }));
        this.setLoanDebtorSummary({ data: data, state: DataState.Loaded });
        return data;
      })
      .catch(err => {
        this.setLoanDebtorSummary({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadAllContractDocuments(applicationId: string) {
    this.setAllContractDocuments({ data: null, state: DataState.Loading });

    return this.readApi
      .getAllContractDocuments(applicationId)
      .then(res => {
        this.setAllContractDocuments({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setAllContractDocuments({ data: null, state: DataState.Loaded });
        return null;
      });
  }
  LoadQuestionMessage(loanId: string) {
    this.setQuestionMessage({ data: null, state: DataState.Loading });

    return this.readApi
      .getQuestionMessageForLoan({ loanId: loanId })
      .then(res => {
        this.setQuestionMessage({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setQuestionMessage({ data: null, state: DataState.Error });
        return null;
      });
  }
  LoadDisplayMessage(loanId: string) {
    this.setDisplayMessage({ data: null, state: DataState.Loading });

    return this.readApi
      .getDisplayMessageForLoan({ loanId: loanId })
      .then(res => {
        this.setDisplayMessage({ data: res, state: DataState.Loaded });
        return res;
      })
      .catch(err => {
        this.setDisplayMessage({ data: null, state: DataState.Error });
        return null;
      });
  }
  loadLoanDetails(loanId: string) {
    this.setLoanDetails({ data: null, state: DataState.Loading });

    return this.readApi
      .getLoan(loanId)
      .then(res => {
        const authorizedResult = res as any;
        if (authorizedResult.statusCode == 401) {
          this.toastrService.error('Error while getting loan details, redirecting to home page.', 'Error');
          setTimeout(() => {
            window.location.href = '/';
          }, 1000);
        } else {
          this.setLoanDetails({ data: res, state: DataState.Loaded });
          return res;
        }
      })
      .catch(err => {
        this.setLoanDetails({ data: null, state: DataState.Error });
        return null;
      });
  }

  loadAdvanceRepaymentDetails(loanId: string, advanceId: string) {
    return Promise.resolve();
    // this.setAdvanceRepaymentDetails(null);
    // return this.readApi
    //   .calculateEarlyRepayment(loanId, { advanceId })
    //   .then(advance => {
    //     this.setAdvanceRepaymentDetails(advance);
    //     return advance;
    //   })
    //   .catch(err => {
    //     this.setAdvanceRepaymentDetails(null);
    //     return null;
    //   });
  }

  loadAdvanceSkipRepaymentDetails(loanId: string, advanceId: string) {
    return Promise.resolve();
    // this.setAdvanceSkipRepaymentDetails(null);
    // return this.readApi
    //   .calculateSkipRepayment(loanId, { advanceId })
    //   .then(res => {
    //     this.setAdvanceSkipRepaymentDetails(res);
    //     return res;
    //   })
    //   .catch(err => {
    //     this.setAdvanceSkipRepaymentDetails(null);
    //     return null;
    //   });
  }

  loadEmployeeMenuStatus(loanId: string) {
    this.loadEmployeeMenuStatusSubject.next(loanId);
  }

  loadAccountingInfo(loanId: string) {
    this.loadAccountingInfoSubject.next(loanId);
  }

  loadAuditRequired(loanId: string) {
    this.loadAuditRequiredSubject.next(loanId);
  }

  loadLoanIsBankSetup(loanId: string) {
    this.loadIsBankSetupSubject.next(loanId);
  }

  loadLoanIsChangeOfFacility(loanId: string) {
    this.loadIsChangeOfFacilityRequiredSubject.next(loanId);
  }

  loadLoanIncomeDetails(loanId: string) {
    this.loadIncomeDetailsSubject.next(loanId);
  }

  loadLoanSummary(loanId: string) {
    this.loadSummarySubject.next(loanId);
  }

  loadLoanCashflow(loanId: string) {
    this.loadCashflowSubject.next(loanId);
  }

  loadLoan(loanId: string) {
    this.resetDisplayedLoan();
    const auditRequiredPromise = this.loadAuditRequired(loanId);

    const loanDetails = this.loadLoanDetails(loanId);

    //const loanNotifications = this.loadLoanNotifications(loanId);

    //const quickSync = this.quickSync(loanId);
    const transactionsPromise = this.loadLoanTransactions(loanId);

    const advanceSummaryPromise = this.loadLoanAdvances(loanId);

    const loanSettingsPromise = this.loadLoanSettings(loanId);

    const employeeMenuStatusPromise = this.loadEmployeeMenuStatus(loanId);

    const signalrEventPromise = this.signalREvent(loanId);

    const displayMessage = this.LoadDisplayMessage(loanId);

    const questionMessage = this.LoadQuestionMessage(loanId);
    const loadLoanSummary = this.loadLoanSummary(loanId);

    return Promise.all([
      employeeMenuStatusPromise,
      auditRequiredPromise,
      loanDetails,
      //loanNotifications,
      transactionsPromise,
      advanceSummaryPromise,
      loanSettingsPromise,
      signalrEventPromise,
      displayMessage,
      questionMessage,
      loadLoanSummary
      //quickSync
    ]);
  }

  loadNewFacilityLimit(loanId: string) {
    return this.readApi
      .getNewFacilityLimit(loanId)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        return null;
      });
  }

  loadLoanApplication(loanId: string) {
    this.setLoadLoanApplication(null);
    return this.readApi
      .getLoanApplication(loanId)
      .then(res => {
        this.setLoadLoanApplication(res);
        return res;
      })
      .catch(err => {
        this.setLoadLoanApplication(null);
        return null;
      });
  }

  loadAllLoanBalances(loanId: string) {
    return this.readApi
      .getAllLoanBalances(loanId)
      .then(res => {
        return res;
      })
      .catch(err => {
        this.setLoadLoanApplication(null);
        return null;
      });
  }

  calculatePercentOfIncome(loanId: string, params: { amount?: number; percent?: number }) {
    return this.readApi
      .calculatePercentOfIncome(loanId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  calculateFixedRepayment(loanId: string, params: { amount?: number; terms?: number }) {
    return this.readApi
      .calculateFixedRepayment(loanId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  calculateInvoicePayment(loanId: string, params: { amount?: number; overrideCalculation?: boolean }) {
    return this.readApi
      .calculateInvoicePayment(loanId, params)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  calculateLineOfCreditRepayment(
    loanId: string,
    params: { amount?: number; percentOfIncome?: number; isFixedRepayment?: boolean; terms?: number }
  ) {
    return this.readApi
      .calculateLineOfCreditRepayment(
        loanId,
        params.amount,
        params.percentOfIncome,
        params.isFixedRepayment,
        params.terms
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  calculateLineOfCreditSlider(loanId: string, amount: number) {
    return this.readApi
      .calculateLineOfCreditSlider(loanId, amount)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  loadBankDetails(loanId: string) {
    return this.readApi
      .getBankDetails(loanId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  getLoanMaxTerm(loanId: string) {
    return this.readApi
      .getMaximumTermOnLoanId(loanId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  //  ____  _____ _____
  // / ___|| ____|_   _|
  // \___ \|  _|   | |
  //  ___) | |___  | |
  // |____/|_____| |_|

  setBankTransactionDataStaleMessage(message: string) {
    DataStore.bankTransactionDataStaleMessage = message;
  }

  setBankTransactionDataStale(isStale: boolean) {
    DataStore.isBankTransactionDataStale = isStale;
  }

  setAccountingSystem(data: string = null) {
    DataStore.accoutingSystem = data;
  }

  setLoadLoanApplication(data: ApplicationListProjection) {
    DataStore.application = data;
  }

  setLoanAdvances(data: Data<AdvanceProjection[]>) {
    DataStore.customerData.displayedLoan.advanceSummary = data;
  }

  setLoanSettings(data: Data<LoanSettings>) {
    DataStore.customerData.displayedLoan.settings = { ...DataStore.customerData.displayedLoan.settings, ...data };
  }

  setLoanNotifications(data: Data<LoanNotificationProjection[]>) {
    DataStore.customerData.displayedLoan.notifications = data;
  }

  setLoanDebtorSummary(data: Data<LoanDebtorProjection[]>) {
    DataStore.customerData.displayedLoan.debtors = data;
  }

  setAllContractDocuments(data: Data<object>) {
    DataStore.customerData.displayedLoan.allContractDocuments = data;
  }

  setLoanDetails(data: Data<CustomerLoanProjection>) {
    DataStore.customerData.displayedLoan.details = data;
  }

  setDisplayMessage(data: Data<DisplayMessageModal[]>) {
    DataStore.customerData.displayedLoan.displayMessage = data;
  }
  setQuestionMessage(data: Data<QuestionMessageModal[]>) {
    DataStore.customerData.displayedLoan.questionMessage = data;
  }
  DismissQuestionMessage(id) {
    DataStore.customerData.displayedLoan.questionMessage.data.splice(
      DataStore.customerData.displayedLoan.questionMessage.data.findIndex(item => item.id === id),
      1
    );
  }
  DismissDisplayMessage(id) {
    DataStore.customerData.displayedLoan.displayMessage.data.splice(
      DataStore.customerData.displayedLoan.displayMessage.data.findIndex(item => item.id === id),
      1
    );
  }
  addLoanNotifications(data: LoanNotificationProjection) {
    if (DataStore.customerData.displayedLoan.notifications.state !== DataState.Loaded) {
      return;
    }
    DataStore.customerData.displayedLoan.notifications.data.unshift(data);
  }

  // setAdvanceRepaymentDetails(data: EarlyRepaymentCalculation) {
  //   DataStore.customerData.displayedAdvance.repaymentDetails = data;
  // }

  // setAdvanceSkipRepaymentDetails(data: SkipRepaymentCalculation) {
  //   DataStore.customerData.displayedAdvance.skipRepaymentDetails = data;
  // }

  setLoanTransactions(
    data: Data<TransactionProjection[]> & { filter: Filter; total: number; dataSummary: TransactionProjection[] }
  ) {
    DataStore.customerData.displayedLoan.transactions = data;
  }

  resetDisplayedLoan() {
    DataStore.customerData.displayedLoan = {
      displayMessage: { data: null, state: DataState.NotLoaded },
      questionMessage: { data: null, state: DataState.NotLoaded },
      notifications: { data: null, state: DataState.NotLoaded },
      settings: { data: null, state: DataState.NotLoaded },
      details: { data: null, state: DataState.NotLoaded },
      summary: { data: null, state: DataState.NotLoaded },
      transactions: {
        filter: {
          page: 0,
          orderBy: 'dateUTC',
          orderbyDescending: true,
          filterColumn: null,
          filterValue: null
        },
        data: null,
        dataSummary: null,
        total: 0,
        state: DataState.NotLoaded
      },
      debtors: { data: null, state: DataState.NotLoaded },
      advanceSummary: { data: null, state: DataState.NotLoaded },
      cashFlow: { data: null, state: DataState.NotLoaded },
      bankSetup: { data: true, state: DataState.NotLoaded },
      changeOfFacilityRequired: { data: true, state: DataState.NotLoaded },
      accountingInfo: {
        connectedToAccountingSystem: {
          data: null,
          state: DataState.NotLoaded
        },
        reconnectAccountingSystemUrl: {
          data: null,
          state: DataState.NotLoaded
        },
        isSyncing: false
      },
      incomeDetails: { data: null, state: DataState.NotLoaded },
      allContractDocuments: { data: null, state: DataState.NotLoaded },
      employeeMenuEnabled: false
    };
  }

  setIsSyncing(data: boolean) {
    DataStore.customerData.displayedLoan.accountingInfo = Object.assign(
      DataStore.customerData.displayedLoan.accountingInfo,
      { isSyncing: data }
    );
  }

  setIsBankSetup(data: Partial<Data<boolean>>) {
    DataStore.customerData.displayedLoan.bankSetup = {
      ...DataStore.customerData.displayedLoan.bankSetup,
      ...data
    };
  }

  setIsChangeOfFacilityRequired(data: Partial<Data<boolean>>) {
    DataStore.customerData.displayedLoan.changeOfFacilityRequired = {
      ...DataStore.customerData.displayedLoan.changeOfFacilityRequired,
      ...data
    };
  }

  setCashflowDetails(data: Partial<Data<CashflowDetails>>) {
    DataStore.customerData.displayedLoan.cashFlow = { ...DataStore.customerData.displayedLoan.cashFlow, ...data };
  }

  setLoanSummary(data: Partial<Data<LoanBalances>>) {
    DataStore.customerData.displayedLoan.summary = { ...DataStore.customerData.displayedLoan.summary, ...data };
  }

  setIsConnectedToAccountingSystem(data: Partial<Data<string>>) {
    DataStore.customerData.displayedLoan.accountingInfo.connectedToAccountingSystem = {
      ...DataStore.customerData.displayedLoan.accountingInfo.connectedToAccountingSystem,
      ...data
    };
  }

  setReconnectAccountingSystemUrl(data: Partial<Data<string>>) {
    DataStore.customerData.displayedLoan.accountingInfo.reconnectAccountingSystemUrl = {
      ...DataStore.customerData.displayedLoan.accountingInfo.reconnectAccountingSystemUrl,
      ...data
    };
  }

  setIncomeDetails(data: Partial<Data<IncomeDetails>>) {
    DataStore.customerData.displayedLoan.incomeDetails = {
      ...DataStore.customerData.displayedLoan.incomeDetails,
      ...data
    };
  }

  setEmployeeMenuStatus(data: boolean) {
    DataStore.customerData.displayedLoan.employeeMenuEnabled = data;
  }

  removeLoanTransaction(transactionId: string) {
    if (!DataStore.customerData.displayedLoan) {
      return;
    }
    DataStore.customerData.displayedLoan.transactions.data = DataStore.customerData.displayedLoan.transactions.data.filter(
      tran => tran.id !== transactionId
    );
    DataStore.customerData.displayedLoan.transactions.total -= 1;
  }

  removeLoanAdvance(advanceId: string) {
    if (!DataStore.customerData.displayedLoan) {
      return;
    }
    DataStore.customerData.displayedLoan.advanceSummary.data = DataStore.customerData.displayedLoan.advanceSummary.data.filter(
      advance => advance.id !== advanceId
    );
  }

  signalREvent(loanId: string) {
    this.signalrService.getChannel(SignalREvents.EMPLOYEE_MENU_ENABLED).subscribe((event: SignalREvent) => {
      if (loanId == event.data.p1) {
        this.loadEmployeeMenuStatus(loanId);
      }
    });
  }
}
