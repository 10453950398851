import { Injectable } from '@angular/core';
import { OriginationReadApi } from '@app/core/proxy/Read/originationReadApi';
import { ApplicationService } from '@app/core/services/application.service';
import { Commission } from '@app/core/proxy/Read/models';
import { Offer } from '../interfaces/i-offer';
import { DataStore } from '@app/DataStore';

export enum OfferDecision {
  SECURED = 'SECURED',
  LINEOFCREDIT = 'LINEOFCREDIT',
  UNSECURED = 'UNSECURED',
  REFERRED = 'REFERRED',
  DECLINED = 'DECLINED'
}

@Injectable()
export class CreditService {
  constructor(private readApi: OriginationReadApi, private applicationService: ApplicationService) {}

  //  ____   ___  ____ _____
  // |  _ \ / _ \/ ___|_   _|
  // | |_) | | | \___ \ | |
  // |  __/| |_| |___) || |
  // |_|    \___/|____/ |_|

  //   ____ _____ _____
  //  / ___| ____|_   _|
  // | |  _|  _|   | |
  // | |_| | |___  | |
  //  \____|_____| |_|

  loadOffer(applicationId: string, offerId: string) {
    this.setOffer();
    return this.readApi
      .offer(applicationId, offerId)
      .then(res => {
        this.setOffer(res);
        const decision = getOfferDecision(DataStore.offer);
        this.setOfferDecision(decision);
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  loadOfferByApplicationId(applicationId: string) {
    return this.readApi
      .getOfferId(applicationId)
      .then(res => {
        if (res.body) {
          return this.loadOffer(applicationId, res.body);
        }
      })
      .catch(err => {
        return null;
      });
  }

  loadOfferByShortCode(shortCode: string) {
    return this.applicationService.loadApplicationByShortCode(shortCode).then(() => {
      return this.loadOfferByApplicationId(DataStore.application.id);
    });
  }

  loadCommission(applicationId: string, loanType: OfferDecision) {
    this.setCommision(null);
    let loanTypeNumber = loanType == OfferDecision.SECURED ? 0 : loanType == OfferDecision.UNSECURED ? 1 : 2;
    return this.readApi
      .commissionMethod(applicationId, { loanType: loanTypeNumber })
      .then(res => {
        this.setCommision(res);
      })
      .catch(err => {
        this.setCommision(null);
        return null;
      });
  }

  //  ____  _____ _____
  // / ___|| ____|_   _|
  // \___ \|  _|   | |
  //  ___) | |___  | |
  // |____/|_____| |_|

  setOffer(data: Offer = null) {
    DataStore.offer = data;
  }

  resetOffer() {
    this.setOffer();
  }

  setCommision(data: Commission) {
    DataStore.offer.displayedCommission = data;
  }

  setOfferDecision(data: OfferDecision) {
    DataStore.offer.decision = data;

    switch (data) {
      case OfferDecision.SECURED:
        DataStore.offer.displayedOffer = DataStore.offer.secured;
        break;
      case OfferDecision.UNSECURED:
        DataStore.offer.displayedOffer = DataStore.offer.unsecured;
        break;
      case OfferDecision.LINEOFCREDIT:
        DataStore.offer.displayedOffer = DataStore.offer.lineOfCredit;
        break;
      default:
        DataStore.offer.displayedOffer = null;
    }
  }

  toggleOfferDecision() {
    switch (DataStore.offer.decision) {
      case OfferDecision.SECURED:
        this.setOfferDecision(OfferDecision.UNSECURED);
        break;
      case OfferDecision.UNSECURED:
        this.setOfferDecision(OfferDecision.SECURED);
        break;
      case OfferDecision.LINEOFCREDIT:
        this.setOfferDecision(OfferDecision.LINEOFCREDIT);
        break;
    }
  }
}

export function getOfferDecision(offer: Offer): OfferDecision {
  if (offer.secured.decision === 0) {
    return OfferDecision.SECURED;
  } else if (offer.unsecured.decision === 0) {
    return OfferDecision.UNSECURED;
  } else if (offer.lineOfCredit.decision === 0) {
    return OfferDecision.LINEOFCREDIT;
  } else if (offer.secured.decision === 1 || offer.unsecured.decision === 1 || offer.lineOfCredit.decision === 1) {
    return OfferDecision.REFERRED;
  } else if (offer.secured.decision === 2 && offer.unsecured.decision === 2 && offer.lineOfCredit.decision === 2) {
    return OfferDecision.DECLINED;
  }
}
