import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { interval, Observable } from 'rxjs';
import { DATE_PROPERTY, PLEXE_TIMEOUT, REGION, REGION_DATE_FORMATS } from '@app/Constants';
import * as moment from 'moment';

export function markFormGroupTouched(formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach((control: any) => {
    control.markAsTouched();

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
}

export function triggerFormValidation(formGroup: FormGroup, notToUpdate: string) {
  Object.keys(formGroup.controls)
    .filter(key => key !== notToUpdate)
    .map(key => formGroup.controls[key])
    .forEach((control: FormControl) => {
      control.updateValueAndValidity();
    });
}

export function getDomainUrl(): string {
  return window.location.protocol + '//' + window.location.host;
}

export const reflect = p =>
  p.then(
    result => ({ result, status: 'fulfilled' }),
    e => ({ result: e, status: 'rejected' })
  );

export const wrapPromise = p =>
  new Promise((resolve, reject) => {
    p.then(res => resolve(res)).catch(err => reject(err));
  });

export const convertNgbDate = (
  json: { day: number; month: number; year: number },
  includeDay: boolean = true
): Date => {
  if (!json) {
    return new Date();
  }
  if (!json.day) {
    return new Date(json.year, json.month - 1);
  }
  if (includeDay) {
    return new Date(json.year, json.month - 1, json.day);
  }
  return new Date(json.year, json.month - 1, 1);
};

export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function toString(value: any): string {
  return value !== undefined && value !== null ? `${value}` : '';
}

export function getValueInRange(value: number, max: number, min: number = 0): number {
  return Math.max(Math.min(value, max), min);
}

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

export function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

export function regExpEscape(text: any) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function hasClassName(element: any, className: string): boolean {
  return (
    element && element.className && element.className.split && element.className.split(/\s+/).indexOf(className) >= 0
  );
}

if (typeof Element !== 'undefined' && !Element.prototype.closest) {
  // Polyfill for ie10+

  if (!Element.prototype.matches) {
    // IE uses the non-standard name: msMatchesSelector
    Element.prototype.matches = (Element.prototype as any).msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  Element.prototype.closest = function(s: string) {
    let el = this;
    if (!document.documentElement.contains(el)) {
      return null;
    }
    do {
      if (el.matches(s)) {
        return el;
      }
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

export function closest(element: HTMLElement, selector: any): HTMLElement {
  if (!selector) {
    return null;
  }

  return element.closest(selector);
}

export function getTimeout(): Observable<any> {
  return interval(PLEXE_TIMEOUT);
}

export function getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
  let hasError = false;
  const result = Object.keys(form.controls).reduce((acc, key) => {
    const control = form.get(key);
    const errors =
      control instanceof FormGroup || control instanceof FormArray ? getAllErrors(control) : control.errors;
    if (errors) {
      acc[key] = errors;
      hasError = true;
    }
    return acc;
  }, {} as { [key: string]: any });
  return hasError ? result : null;
}

export function getDateAsPerRegion(dateString, componentName = '', format = '') {
  let region = '';
  let regionDateFormat: RegionDateFormat = null;
  if (componentName != '') {
    regionDateFormat = REGION_DATE_FORMATS.find(x => x.name == componentName);
  }
  let formattedDate = dateString;
  region = window['appConfig']['defaultRegion'];
  switch (region) {
    case REGION.AU: {
      formattedDate =
        format && format != ''
          ? moment(dateString).format(format)
          : moment(dateString).format(regionDateFormat.formats.AU);
      break;
    }
    case REGION.US: {
      formattedDate =
        format && format != ''
          ? moment(dateString).format(format)
          : moment(dateString).format(regionDateFormat.formats.US);
      break;
    }
    default: {
      formattedDate =
        format && format != ''
          ? moment(dateString).format(format)
          : moment(dateString).format(regionDateFormat.formats.AU);
      break;
    }
  }
  return formattedDate;
}

export class RegionDateFormat {
  name: string;
  formats: RegionFormat;
}

export class RegionFormat {
  AU: string;
  US: string;
}

export function deleteCookie(name) {
  setCookie(name, '', -1);
}

export function setCookie(name: string, value: string, expireDays: number = 1, path: string = '') {
  let d: Date = new Date();
  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  let expires: string = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value}; ${expires};path=/`;
}

export function getCookie(cname) {
  let name = cname + '=';
  let ca = document.cookie ? document.cookie.split(';') : '';
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function getAddressDetails(address: string) {
  return getSelectedAddress(address).then(res => {
    let details = {
      state: '',
      city: '',
      placeId: ''
    };

    if (res.length === 1) {
      var terms = res[0].terms;
      // get State
      if (terms.length > 2) {
        details.state = terms.length == 6 ? terms[terms.length - 3].value : terms[terms.length - 2].value;
      }

      // get City
      if (terms.length > 3) {
        details.city = terms.length == 6 ? terms[terms.length - 4].value : terms[terms.length - 3].value;
      }

      // get placeid
      details.placeId = res[0].place_id;
    }

    return details;
  });
}

export function getPostalCode(placeId: string) {
  var promise = new Promise<string>((resolve, reject) => {
    return new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
      {
        placeId: placeId,
        fields: ['address_components']
      },
      (details, status) => {
        if (details && details.address_components) {
          details.address_components.forEach(entry => {
            if (entry && entry.types) {
              if (entry.types[0] === 'postal_code') {
                resolve(entry.long_name);
              }
            }
          });
        }
        resolve('');
      }
    );
  });

  return promise;
}

export function getSanitizeMobile(mobile) {
  if (mobile) {
    let newVal = mobile.replace(/\D/g, '');

    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
    }

    return newVal;
  }
  return mobile;
}
export function contains(a, b) {
  return a && b ? a.toLowerCase().includes(b.toLowerCase()) : false;
}

function getSelectedAddress(address: string) {
  const autocompleteService = new google.maps.places.AutocompleteService();
  var promise = new Promise<google.maps.places.QueryAutocompletePrediction[]>((resolve, reject) => {
    var request = {
      input: address,
      componentRestrictions: {
        country: window['appConfig']['defaultRegion']
      }
    };
    return autocompleteService.getPlacePredictions(
      request,
      (
        predictions: google.maps.places.QueryAutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status == google.maps.places.PlacesServiceStatus.OK && predictions) {
          var result = predictions.filter(x => x.description === address);
          resolve(result);
        } else {
          resolve([]);
        }
      }
    );
  });
  return promise;
}

export function getCity(address: string) {
  return getSelectedAddress(address).then(res => {
    if (res.length === 1) {
      var terms = res[0].terms;
      if (terms.length > 3) {
        return terms[terms.length - 3].value;
      }
    }
    return '';
  });
}

export function getState(address: string) {
  return getSelectedAddress(address).then(res => {
    if (res.length === 1) {
      var terms = res[0].terms;
      if (terms.length > 2) {
        return terms[terms.length - 2].value;
      }
      return '';
    }
  });
}

// Function to get the current month and year
export function getCurrentMonthAndYear(): string {
  const currentDate = new Date();
  return formatMonthYear(currentDate);
}

// Function to get the last 3 months
export function getLastMonths(months = 3): string[] {
  const currentDate = new Date();
  let lastMonths = [];

  for (let i = 1; i <= months; i++) {
    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(currentDate.getMonth() - i);
    lastMonths.push(formatMonthYear(lastMonth));
  }

  return lastMonths;
}

// Function to format the month and year as "Month Year"
function formatMonthYear(date: Date): string {
  const options = { year: 'numeric', month: 'long' } as const;
  return date.toLocaleDateString('en-US', options);
}

export function replaceWithX(input: string): string {
  return input.replace(/./g, 'x');
}

type Dictionary = { [key: string]: any };

// Function to get value by lower-case key
export function getValueByLowerKey(dictionary: Dictionary, key: string): any | undefined {
  // Convert the key to lower case
  const lowerKey = key.toLowerCase();
  // Iterate through the dictionary
  for (const k in dictionary) {
    if (k.toLowerCase() === lowerKey) {
      return dictionary[k];
    }
  }
  return undefined; // Return undefined if key is not found
}

export function isVersion2(str: string) {
  return str?.includes('v2');
}

export function calculateDateRange(startDate: Date, noOfDaysToAdd: number = 4) {
  let count = 0;
  let endDate = new Date();
  while (count < noOfDaysToAdd) {
    endDate = new Date(startDate.setDate(startDate.getDate() + 1));
    if (endDate.getDay() != 0 && endDate.getDay() != 6) {
      count++;
    }
  }

  return getDateAsPerRegion(endDate, DATE_PROPERTY.WithdrawalDate);
}

export function downloadFile(data: any, filename: string, filetype: string) {
  const blob = new Blob([data], { type: filetype });
  saveAs(blob, filename);
}
