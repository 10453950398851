import { Injectable } from '@angular/core';
import { DataStore } from 'app/DataStore';
import { Router } from '@angular/router';
import { StatusMap } from 'app/Constants';
import { OfferDecision } from 'app/core/services/credit.service';
import { ApplicationService } from 'app/core/services/application.service';
import { LoanService } from 'app/core/services/loan.service';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable()
export class AppNavigatorService {
  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private loanService: LoanService,
    private appInsightsService: ApplicationInsightsService
  ) {}

  //     _               _ _           _   _
  //    /_\  _ __  _ __ | (_) ___ __ _| |_(_) ___  _ __
  //   //_\\| '_ \| '_ \| | |/ __/ _` | __| |/ _ \| '_ \
  //  /  _  \ |_) | |_) | | | (_| (_| | |_| | (_) | | | |
  //  \_/ \_/ .__/| .__/|_|_|\___\__,_|\__|_|\___/|_| |_|
  //        |_|   |_|

  get application() {
    return DataStore.application;
  }

  get offer() {
    return DataStore.offer;
  }

  getApplicationUrlByOffer() {
    const prefix = this.getPrefix();

    const shortCode = this.application.shortCode;

    if (this.offer.decision === OfferDecision.DECLINED) {
      return `/application/` + shortCode + '/refer';
    } else if (this.offer.decision === OfferDecision.REFERRED) {
      return `/application/` + shortCode + '/refer';
    } else if (
      this.offer.decision === OfferDecision.SECURED ||
      this.offer.decision === OfferDecision.UNSECURED ||
      this.offer.decision === OfferDecision.LINEOFCREDIT
    ) {
      return `/application/` + shortCode + '/thank-you'; // '/indicative-line-of-credit';
    }
  }

  getApplicationUrlByStatus(): string {
    const status = this.application.status;
    const prefix = this.getPrefix();

    if (StatusMap[status].loadOffer) {
      return this.getApplicationUrlByOffer();
    } else if (StatusMap[status].linkedUrl) {
      return `/application/` + this.application.shortCode + StatusMap[status].linkedUrl;
      // return `${prefix}/new-application/` + this.application.shortCode + StatusMap[status].linkedUrl;
    } else {
      return '/';
    }
  }

  navigateApplicationUrlByStatus() {
    this.router.navigate([this.getApplicationUrlByStatus()], { replaceUrl: true });
  }

  //     ___          _
  //    / __\   _ ___| |_ ___  _ __ ___   ___ _ __
  //   / / | | | / __| __/ _ \| '_ ` _ \ / _ \ '__|
  //  / /__| |_| \__ \ || (_) | | | | | |  __/ |
  //  \____/\__,_|___/\__\___/|_| |_| |_|\___|_|
  //

  navigateToCustomerHome() {
    if (!!DataStore.customerData.loans && DataStore.customerData.loans.length > 0) {
      const firstLoanId = DataStore.customerData.loans[0].loanId;

      return this.loanService.loadLoan(firstLoanId).then(_ => {
        this.router.navigate(['/customer/loan/' + firstLoanId + '/summary']);
      });
    } else if (!!DataStore.customerData.readyApplications && DataStore.customerData.readyApplications.length > 0) {
      const firstApplicationId = DataStore.customerData.readyApplications[0].id;

      return Promise.all([
        this.applicationService.loadApplicationByApplicationId(firstApplicationId),
        this.applicationService.loadIncomeDetails(firstApplicationId),
        this.applicationService.loadOfferDetails(firstApplicationId)
      ]).then(_ => {
        this.router.navigate(['/customer/application/' + firstApplicationId]);
      });
    } else {
      this.router.navigate(['/customer/empty']);
    }
  }

  navigateToCustomerApplicationHome(firstApplicationId) {
    if (!!DataStore.customerData.readyApplications && DataStore.customerData.readyApplications.length > 0) {
      return this.applicationService.loadApplicationByApplicationId(firstApplicationId).then(_ => {
        this.router.navigate(['/customer/application/' + firstApplicationId]);
      });
    } else {
      this.router.navigate(['/customer/empty']);
    }
  }

  navigateToCustomerLoanHome(firstLoanId) {
    if (!!DataStore.customerData.loans && DataStore.customerData.loans.length > 0) {
      return this.loanService.loadLoan(firstLoanId).then(_ => {
        this.router.navigate(['/customer/loan/' + firstLoanId]);
      });
    } else {
      this.router.navigate(['/customer/empty']);
    }
  }

  //     ___                          _
  //    / _ \___ _ __   ___ _ __ __ _| |
  //   / /_\/ _ \ '_ \ / _ \ '__/ _` | |
  //  / /_\\  __/ | | |  __/ | | (_| | |
  //  \____/\___|_| |_|\___|_|  \__,_|_|
  //

  navigateDefault() {
    this.router.navigate(['/landing'], { replaceUrl: true });
  }

  navigateError(error: Error) {
    this.appInsightsService.logException(error);
    this.router.navigate(['/error'], { replaceUrl: true });
  }

  navigateLogin() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  navigateOverview() {
    this.router.navigate(['/v2'], { replaceUrl: true });
  }

  private getPrefix() {
    const isPartner = DataStore.partner != null;
    return isPartner ? '/partner' : '/public';
  }
}
