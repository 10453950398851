import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SecurityContext } from '@app/core/interfaces/i-security-context';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { UserService } from '@app/core/services/user.service';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/internal/operators';
import { ToastrService } from 'ngx-toastr';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';
import { fadeInOut } from '@app/shared/animation/animations';

const CORPRATE_CARDHOLDER = 'corpratecardholder';
const EMPLOYEE = 'employee';
@Component({
  selector: 'app-login-content',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInOut]
})
export class LoginComponent implements OnInit {
  isInit = true;
  loginForm: FormGroup;
  isLoading = false;
  error: string;
  userLoading = false;
  loggedInLoading = false;
  isUserTypeEmployee = false;
  hasLoggedIn = false;
  defaultRegion = window['appConfig']['defaultRegion'];
  isUSRegion = this.defaultRegion == 'US';
  isUserTypeCorprate = false;
  loginButtonText = 'Login to your account';
  isRedirecting = false;
  contactUsUrl = `${window['appConfig']['contactUsUrl']}`;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private navService: AppNavigatorService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    //window.location.href = "https://portal.plexe.co/login";
    sessionStorage.removeItem('appData');
    localStorage.removeItem('announcement');
    const loginClick = localStorage.getItem('loginClick');
    if (!loginClick || !JSON.stringify(loginClick)) {
      this.router.navigate(['/login-sms'], { replaceUrl: true });
    } else {
      if (localStorage.getItem('firstAccess')) {
        this.authenticationService.logout().subscribe();
      }

      this.createForm();
      this.isInit = false;
    }
  }

  ngAfterViewInit() {
    localStorage.setItem('firstAccess', 'true');
  }

  sendCode() {
    console.log(this.loginForm.value);
    if (this.loginForm.valid) {
      this.router.navigateByUrl('application/confirm-email');
    }
  }

  login() {
    this.isLoading = true;
    this.error = '';
    this.authenticationService
      .login(this.loginForm.value, true)
      .pipe(
        switchMap(credentials =>
          this.authenticationService.requiresTwoWayAuth(credentials)
            ? this.router.navigate(['/two-factor'])
            : this.postLoginActions(credentials)
        )
      )
      .subscribe(
        _ => {},
        err => {
          this.error = 'Login Incorrect';
          const errors = err.toString().split('Error: ');
          if (errors.length > 0) {
            const parseMessage = JSON.parse(errors[1]);
            if (typeof parseMessage === 'string') {
              this.error = parseMessage;
              if (this.error.includes('Your password has expired and must be changed.')) {
                localStorage.setItem('e', this.loginForm.value.username);
                this.loginButtonText = 'Redirecting...';
                this.isRedirecting = true;
                setTimeout(() => {
                  this.router.navigate(['/password-expires']);
                }, 3000);
              }
            }
          }
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }
      );
  }
  postLoginActions(credentials: SecurityContext): Observable<any> {
    if (credentials.userType.toLowerCase() === 'partner') {
      this.toastrService.error("you can't login as partner or employee");
    } else if (credentials.userType.toLowerCase() === 'customer' || credentials.userType.toLowerCase() === 'admin') {
      return from(
        this.userService
          .loadCustomerForHomePage(credentials.id)
          .then(_ => {
            return this.navService.navigateToCustomerHome();
            //return from(this.router.navigate(['/overview'], { replaceUrl: true }));
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else if (credentials.userType.toLowerCase() === EMPLOYEE) {
      this.toastrService.error("you can't login as partner or employee");
    } else if (credentials.userType.toLowerCase() === CORPRATE_CARDHOLDER) {
      this.toastrService.error("you can't login as partner or employee");
    } else {
      throw new Error('User not allowed');
    }
  }
  trim(event) {
    this.loginForm.get('username').setValue(event.currentTarget.value.trim());
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
        ]
      ],
      password: ['', [Validators.required]],
      remember: true
    });
  }
}
