import { REGION } from 'app/Constants';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SecurityContext } from '@app/core/interfaces/i-security-context';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { SignalrService } from '@app/core/services/signalr.service';
import { UserService } from '@app/core/services/user.service';
import { fadeInOut } from '@app/shared/animation/animations';
import { ToastrService } from 'ngx-toastr';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DataStore } from '@app/DataStore';

@Component({
  selector: 'app-login-sms',
  templateUrl: './login-sms.component.html',
  styleUrls: ['./login-sms.component.scss'],
  animations: [fadeInOut]
})
export class LoginSmsComponent implements OnInit {
  loginForm: FormGroup;
  isLoading = false;
  isSmsLogin = false;
  failed = false;
  digit1;
  digit2;
  digit3;
  digit4;
  contactUsUrl = `${window['appConfig']['contactUsUrl']}`;
  isInit = true;
  invalidMobileNumber = false;
  failedCode = false;

  defaultRegion = window['appConfig']['defaultRegion'];
  isUSRegion = this.defaultRegion == REGION.US;
  interval;
  timeLeft = 20;
  timeDisplay: string;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService,
    private signalrService: SignalrService,
    private navService: AppNavigatorService,
    private userService: UserService
  ) {}
  get digitsFilled() {
    return this.digit1 && this.digit2 && this.digit3 && this.digit4;
  }

  get isPageLoading() {
    return DataStore.isPageLoading;
  }
  ngOnInit(): void {
    // reditect to old site
    //window.location.href = "https://portal.plexe.co/login";
    sessionStorage.removeItem('appData');
    localStorage.removeItem('announcement');
    if (localStorage.getItem('firstAccess')) {
      this.authenticationService.logout().subscribe();
    }
    this.initForm();

    localStorage.setItem('firstAccess', 'true');
    this.isInit = false;
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.timeDisplay = new Date(this.timeLeft * 1000).toISOString().substring(14, 19);
      }
    }, 1000);
  }

  sendCode() {
    console.log(this.loginForm.value);
    if (this.loginForm.valid) {
      this.router.navigateByUrl('/');
    }
  }

  validateEmail() {
    this.isLoading = true;
    this.userService
      .getMobileNumber(this.loginForm.value.email)
      .then(res => {
        if (res.body) {
          this.loginForm.get('mobileNumber').setValue(res.body);
          this.sendLoginOtp();
        } else {
          this.invalidMobileNumber = true;
          this.isLoading = false;
        }
      })
      .catch(_ => (this.isLoading = false));
  }

  sendLoginOtp() {
    this.userService
      .sendLoginOtp({ email: this.loginForm.value.email })
      .then(res => {
        this.startTimer();
        this.isSmsLogin = true;
        setTimeout(() => {
          const e = document.getElementById('digit-1');
          if (e) {
            e.focus();
          }
        }, 500);
      })
      .catch(_ => (this.failedCode = true))
      .finally(() => (this.isLoading = false));
  }

  login() {
    if (this.loginForm.invalid) {
      return;
    }
    this.validateEmail();
  }

  clearErrors() {
    this.invalidMobileNumber = false;
    this.failedCode = false;
  }

  onKeyDown(event: any) {
    if ((event.which > 47 && event.which < 58) || (event.which >= 96 && event.which <= 105)) {
      event.currentTarget.value = '';
    }
  }

  onKeyUp(event: any, nextElemId?: string, prevElemId?: string) {
    if (event.which === 8 && prevElemId) {
      const e = document.getElementById(prevElemId);
      if (e) {
        e.focus();
      }
    } else if (((event.which > 47 && event.which < 58) || (event.which >= 96 && event.which <= 105)) && nextElemId) {
      const e = document.getElementById(nextElemId);
      if (e) {
        e.focus();
      }
    }
  }

  goToComplete(code: any) {
    this.failed = false;
    this.isLoading = true;
    DataStore.isPageLoading = true;
    this.authenticationService
      .validateTwoWayAuth({
        username: this.loginForm.value.email,
        code,
        ipAddress: ''
      })
      .pipe(switchMap(credentials => this.postLoginActions(credentials)))
      .subscribe(
        _ => {},
        _ => {
          this.failed = true;
          this.isLoading = false;
          DataStore.isPageLoading = false;
        }
      );
  }

  private postLoginActions(credentials: SecurityContext): Observable<any> {
    const connectionId = this.signalrService.getHubConnectionId();
    console.log(connectionId);
    if (connectionId != '') {
      console.log(connectionId);
      this.userService.setCustomerConnection(credentials.id, connectionId);
    }
    if (credentials.userType.toLowerCase() === 'partner') {
      return from(
        this.userService
          .loadPartnerHomepageData(credentials.id)
          .then(_ => {
            return this.router.navigate(['/partner/home'], {
              replaceUrl: true
            });
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else if (credentials.userType.toLowerCase() === 'customer' || credentials.userType.toLowerCase() === 'admin') {
      return from(
        this.userService
          .loadCustomerForHomePage(credentials.id)
          .then(_ => {
            return this.navService.navigateToCustomerHome();
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else if (credentials.userType.toLowerCase() === 'agent') {
      return from(
        this.userService
          .loadAgentHomepageData(credentials.id)
          .then(_ => {
            return this.router.navigate(['/agent/home'], {
              replaceUrl: true
            });
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else {
      throw new Error('User not allowed');
    }
  }

  resend() {
    this.login();
    this.digit1 = this.digit2 = this.digit3 = this.digit4 = '';
    this.failed = false;

    this.timeLeft = 20;
    this.startTimer();
  }
  initForm() {
    this.loginForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')
        ]
      ],
      mobileNumber: ['']
    });
  }

  loginWithPassword() {
    localStorage.setItem('loginClick', JSON.stringify(true));
  }

  ngOnDestroy() {
    DataStore.isPageLoading = false;
  }
}
