/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from '@azure/ms-rest-js';
import * as Models from './models';
import * as Mappers from './models/mappers';
import * as Parameters from './models/parameters';
import { OriginationWriteApiContext } from './originationWriteApiContext';

class OriginationWriteApi extends OriginationWriteApiContext {
  /**
   * Initializes a new instance of the OriginationWriteApi class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, options?: Models.OriginationWriteApiOptions) {
    super(credentials, options);
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addAgent(partnerId: string, options?: Models.OriginationWriteApiAddAgentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  addAgent(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  addAgent(
    partnerId: string,
    options: Models.OriginationWriteApiAddAgentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addAgent(
    partnerId: string,
    options?: Models.OriginationWriteApiAddAgentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      addAgentOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  editAgent(
    partnerId: string,
    options?: Models.OriginationWriteApiEditAgentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  editAgent(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  editAgent(
    partnerId: string,
    options: Models.OriginationWriteApiEditAgentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  editAgent(
    partnerId: string,
    options?: Models.OriginationWriteApiEditAgentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      editAgentOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableAgent(
    partnerId: string,
    options?: Models.OriginationWriteApiEnableAgentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  enableAgent(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  enableAgent(
    partnerId: string,
    options: Models.OriginationWriteApiEnableAgentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  enableAgent(
    partnerId: string,
    options?: Models.OriginationWriteApiEnableAgentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      enableAgentOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disableAgent(
    partnerId: string,
    options?: Models.OriginationWriteApiDisableAgentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  disableAgent(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  disableAgent(
    partnerId: string,
    options: Models.OriginationWriteApiDisableAgentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  disableAgent(
    partnerId: string,
    options?: Models.OriginationWriteApiDisableAgentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      disableAgentOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeAgent(
    partnerId: string,
    options?: Models.OriginationWriteApiRemoveAgentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  removeAgent(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeAgent(
    partnerId: string,
    options: Models.OriginationWriteApiRemoveAgentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeAgent(
    partnerId: string,
    options?: Models.OriginationWriteApiRemoveAgentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      removeAgentOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeAgentNote(
    partnerId: string,
    options?: Models.OriginationWriteApiRemoveAgentNoteOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  removeAgentNote(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeAgentNote(
    partnerId: string,
    options: Models.OriginationWriteApiRemoveAgentNoteOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeAgentNote(
    partnerId: string,
    options?: Models.OriginationWriteApiRemoveAgentNoteOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      removeAgentNoteOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  editAgentNote(
    partnerId: string,
    options?: Models.OriginationWriteApiEditAgentNoteOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  editAgentNote(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  editAgentNote(
    partnerId: string,
    options: Models.OriginationWriteApiEditAgentNoteOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  editAgentNote(
    partnerId: string,
    options?: Models.OriginationWriteApiEditAgentNoteOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      editAgentNoteOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addAgentNote(
    partnerId: string,
    options?: Models.OriginationWriteApiAddAgentNoteOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  addAgentNote(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  addAgentNote(
    partnerId: string,
    options: Models.OriginationWriteApiAddAgentNoteOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addAgentNote(
    partnerId: string,
    options?: Models.OriginationWriteApiAddAgentNoteOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      addAgentNoteOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setShowAgentTodo(
    partnerId: string,
    options?: Models.OriginationWriteApiSetShowAgentTodoOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  setShowAgentTodo(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  setShowAgentTodo(
    partnerId: string,
    options: Models.OriginationWriteApiSetShowAgentTodoOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setShowAgentTodo(
    partnerId: string,
    options?: Models.OriginationWriteApiSetShowAgentTodoOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      setShowAgentTodoOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setIsMaster(
    partnerId: string,
    options?: Models.OriginationWriteApiSetIsMasterOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  setIsMaster(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  setIsMaster(
    partnerId: string,
    options: Models.OriginationWriteApiSetIsMasterOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setIsMaster(
    partnerId: string,
    options?: Models.OriginationWriteApiSetIsMasterOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      setIsMasterOperationSpec,
      callback
    );
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<Models.SendLinkResponse>
   */
  sendLink(
    agentId: string,
    options?: Models.OriginationWriteApiSendLinkOptionalParams
  ): Promise<Models.SendLinkResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  sendLink(agentId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendLink(
    agentId: string,
    options: Models.OriginationWriteApiSendLinkOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  sendLink(
    agentId: string,
    options?: Models.OriginationWriteApiSendLinkOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SendLinkResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      sendLinkOperationSpec,
      callback
    ) as Promise<Models.SendLinkResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resendSendLink(
    agentId: string,
    options?: Models.OriginationWriteApiResendSendLinkOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  resendSendLink(agentId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  resendSendLink(
    agentId: string,
    options: Models.OriginationWriteApiResendSendLinkOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  resendSendLink(
    agentId: string,
    options?: Models.OriginationWriteApiResendSendLinkOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      resendSendLinkOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  moveAllAgents(options?: Models.OriginationWriteApiMoveAllAgentsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  moveAllAgents(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  moveAllAgents(
    options: Models.OriginationWriteApiMoveAllAgentsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  moveAllAgents(
    options?: Models.OriginationWriteApiMoveAllAgentsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      moveAllAgentsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addAgentAnnouncement(
    options?: Models.OriginationWriteApiAddAgentAnnouncementOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addAgentAnnouncement(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addAgentAnnouncement(
    options: Models.OriginationWriteApiAddAgentAnnouncementOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addAgentAnnouncement(
    options?: Models.OriginationWriteApiAddAgentAnnouncementOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addAgentAnnouncementOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeAgentAnnouncement(
    options?: Models.OriginationWriteApiRemoveAgentAnnouncementOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removeAgentAnnouncement(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removeAgentAnnouncement(
    options: Models.OriginationWriteApiRemoveAgentAnnouncementOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeAgentAnnouncement(
    options?: Models.OriginationWriteApiRemoveAgentAnnouncementOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removeAgentAnnouncementOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addAlertNotification(
    options?: Models.OriginationWriteApiAddAlertNotificationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addAlertNotification(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addAlertNotification(
    options: Models.OriginationWriteApiAddAlertNotificationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addAlertNotification(
    options?: Models.OriginationWriteApiAddAlertNotificationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addAlertNotificationOperationSpec,
      callback
    );
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  changeReadStatus(
    id: number,
    options?: Models.OriginationWriteApiChangeReadStatusOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  changeReadStatus(id: number, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  changeReadStatus(
    id: number,
    options: Models.OriginationWriteApiChangeReadStatusOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  changeReadStatus(
    id: number,
    options?: Models.OriginationWriteApiChangeReadStatusOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      changeReadStatusOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  markAllRead(options?: Models.OriginationWriteApiMarkAllReadOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  markAllRead(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  markAllRead(
    options: Models.OriginationWriteApiMarkAllReadOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  markAllRead(
    options?: Models.OriginationWriteApiMarkAllReadOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      markAllReadOperationSpec,
      callback
    );
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteAlertNotification(id: number, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteAlertNotification(id: number, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteAlertNotification(id: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteAlertNotification(
    id: number,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      deleteAlertNotificationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteAlertNotifications(
    options?: Models.OriginationWriteApiDeleteAlertNotificationsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteAlertNotifications(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteAlertNotifications(
    options: Models.OriginationWriteApiDeleteAlertNotificationsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  deleteAlertNotifications(
    options?: Models.OriginationWriteApiDeleteAlertNotificationsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteAlertNotificationsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  assignEmailClassiications(
    options?: Models.OriginationWriteApiAssignEmailClassiicationsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  assignEmailClassiications(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  assignEmailClassiications(
    options: Models.OriginationWriteApiAssignEmailClassiicationsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  assignEmailClassiications(
    options?: Models.OriginationWriteApiAssignEmailClassiicationsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      assignEmailClassiicationsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unAssignEmailClassifications(
    options?: Models.OriginationWriteApiUnAssignEmailClassificationsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  unAssignEmailClassifications(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  unAssignEmailClassifications(
    options: Models.OriginationWriteApiUnAssignEmailClassificationsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  unAssignEmailClassifications(
    options?: Models.OriginationWriteApiUnAssignEmailClassificationsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      unAssignEmailClassificationsOperationSpec,
      callback
    );
  }

  /**
   * @param applicantId
   * @param applicant
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unregisterApplicant(
    applicantId: string,
    applicant: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicantId
   * @param applicant
   * @param callback The callback
   */
  unregisterApplicant(applicantId: string, applicant: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicantId
   * @param applicant
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterApplicant(
    applicantId: string,
    applicant: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  unregisterApplicant(
    applicantId: string,
    applicant: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicantId,
        applicant,
        options
      },
      unregisterApplicantOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.AddPrimaryApplicantDetailsResponse>
   */
  addPrimaryApplicantDetails(
    applicationId: string,
    body: Models.PrimaryApplicantAndSecondaryApplicantRequested,
    options?: Models.OriginationWriteApiAddPrimaryApplicantDetailsOptionalParams
  ): Promise<Models.AddPrimaryApplicantDetailsResponse>;
  /**
   * @param applicationId
   * @param body
   * @param callback The callback
   */
  addPrimaryApplicantDetails(
    applicationId: string,
    body: Models.PrimaryApplicantAndSecondaryApplicantRequested,
    callback: msRest.ServiceCallback<string>
  ): void;
  /**
   * @param applicationId
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  addPrimaryApplicantDetails(
    applicationId: string,
    body: Models.PrimaryApplicantAndSecondaryApplicantRequested,
    options: Models.OriginationWriteApiAddPrimaryApplicantDetailsOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  addPrimaryApplicantDetails(
    applicationId: string,
    body: Models.PrimaryApplicantAndSecondaryApplicantRequested,
    options?: Models.OriginationWriteApiAddPrimaryApplicantDetailsOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.AddPrimaryApplicantDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        body,
        options
      },
      addPrimaryApplicantDetailsOperationSpec,
      callback
    ) as Promise<Models.AddPrimaryApplicantDetailsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.RequestSecondaryResponse>
   */
  requestSecondary(
    applicationId: string,
    options?: Models.OriginationWriteApiRequestSecondaryOptionalParams
  ): Promise<Models.RequestSecondaryResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  requestSecondary(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  requestSecondary(
    applicationId: string,
    options: Models.OriginationWriteApiRequestSecondaryOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  requestSecondary(
    applicationId: string,
    options?: Models.OriginationWriteApiRequestSecondaryOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.RequestSecondaryResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      requestSecondaryOperationSpec,
      callback
    ) as Promise<Models.RequestSecondaryResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.RemoveSecondaryRequestResponse>
   */
  removeSecondaryRequest(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.RemoveSecondaryRequestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  removeSecondaryRequest(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeSecondaryRequest(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  removeSecondaryRequest(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.RemoveSecondaryRequestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      removeSecondaryRequestOperationSpec,
      callback
    ) as Promise<Models.RemoveSecondaryRequestResponse>;
  }

  /**
   * @param applicationId
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<Models.AddSecondaryApplicantDetailsResponse>
   */
  addSecondaryApplicantDetails(
    applicationId: string,
    body: Models.SecondaryApplicant,
    options?: Models.OriginationWriteApiAddSecondaryApplicantDetailsOptionalParams
  ): Promise<Models.AddSecondaryApplicantDetailsResponse>;
  /**
   * @param applicationId
   * @param body
   * @param callback The callback
   */
  addSecondaryApplicantDetails(
    applicationId: string,
    body: Models.SecondaryApplicant,
    callback: msRest.ServiceCallback<string>
  ): void;
  /**
   * @param applicationId
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  addSecondaryApplicantDetails(
    applicationId: string,
    body: Models.SecondaryApplicant,
    options: Models.OriginationWriteApiAddSecondaryApplicantDetailsOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  addSecondaryApplicantDetails(
    applicationId: string,
    body: Models.SecondaryApplicant,
    options?: Models.OriginationWriteApiAddSecondaryApplicantDetailsOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.AddSecondaryApplicantDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        body,
        options
      },
      addSecondaryApplicantDetailsOperationSpec,
      callback
    ) as Promise<Models.AddSecondaryApplicantDetailsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendSecondaryApplicantNotice(
    applicationId: string,
    options?: Models.OriginationWriteApiSendSecondaryApplicantNoticeOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendSecondaryApplicantNotice(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendSecondaryApplicantNotice(
    applicationId: string,
    options: Models.OriginationWriteApiSendSecondaryApplicantNoticeOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendSecondaryApplicantNotice(
    applicationId: string,
    options?: Models.OriginationWriteApiSendSecondaryApplicantNoticeOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendSecondaryApplicantNoticeOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicantDetails(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantDetailsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateApplicantDetails(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicantDetails(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateApplicantDetailsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateApplicantDetails(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantDetailsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateApplicantDetailsOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicantDetail(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantDetailOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateApplicantDetail(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicantDetail(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateApplicantDetailOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateApplicantDetail(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantDetailOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateApplicantDetailOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicantSSN(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantSSNOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateApplicantSSN(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicantSSN(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateApplicantSSNOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateApplicantSSN(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantSSNOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateApplicantSSNOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicantDriversLicence(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantDriversLicenceOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateApplicantDriversLicence(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicantDriversLicence(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateApplicantDriversLicenceOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateApplicantDriversLicence(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantDriversLicenceOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateApplicantDriversLicenceOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicantEmail(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantEmailOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateApplicantEmail(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicantEmail(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateApplicantEmailOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateApplicantEmail(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantEmailOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateApplicantEmailOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicantDateOfBirth(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantDateOfBirthOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateApplicantDateOfBirth(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicantDateOfBirth(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateApplicantDateOfBirthOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateApplicantDateOfBirth(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicantDateOfBirthOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateApplicantDateOfBirthOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  changeApplicantToPrimary(
    options?: Models.OriginationWriteApiChangeApplicantToPrimaryOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  changeApplicantToPrimary(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  changeApplicantToPrimary(
    options: Models.OriginationWriteApiChangeApplicantToPrimaryOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  changeApplicantToPrimary(
    options?: Models.OriginationWriteApiChangeApplicantToPrimaryOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      changeApplicantToPrimaryOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  changeApplicantToSecondary(
    options?: Models.OriginationWriteApiChangeApplicantToSecondaryOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  changeApplicantToSecondary(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  changeApplicantToSecondary(
    options: Models.OriginationWriteApiChangeApplicantToSecondaryOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  changeApplicantToSecondary(
    options?: Models.OriginationWriteApiChangeApplicantToSecondaryOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      changeApplicantToSecondaryOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  cleanAlerts(options?: Models.OriginationWriteApiCleanAlertsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  cleanAlerts(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  cleanAlerts(
    options: Models.OriginationWriteApiCleanAlertsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  cleanAlerts(
    options?: Models.OriginationWriteApiCleanAlertsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      cleanAlertsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setPartnerApplicationStatus(
    options?: Models.OriginationWriteApiSetPartnerApplicationStatusOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setPartnerApplicationStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setPartnerApplicationStatus(
    options: Models.OriginationWriteApiSetPartnerApplicationStatusOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setPartnerApplicationStatus(
    options?: Models.OriginationWriteApiSetPartnerApplicationStatusOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setPartnerApplicationStatusOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  requestAction(
    applicationId: string,
    options?: Models.OriginationWriteApiRequestActionOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  requestAction(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  requestAction(
    applicationId: string,
    options: Models.OriginationWriteApiRequestActionOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  requestAction(
    applicationId: string,
    options?: Models.OriginationWriteApiRequestActionOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      requestActionOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TestApplicationResponse>
   */
  testApplication(
    options?: Models.OriginationWriteApiTestApplicationOptionalParams
  ): Promise<Models.TestApplicationResponse>;
  /**
   * @param callback The callback
   */
  testApplication(callback: msRest.ServiceCallback<Models.DecisionDetails>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  testApplication(
    options: Models.OriginationWriteApiTestApplicationOptionalParams,
    callback: msRest.ServiceCallback<Models.DecisionDetails>
  ): void;
  testApplication(
    options?: Models.OriginationWriteApiTestApplicationOptionalParams | msRest.ServiceCallback<Models.DecisionDetails>,
    callback?: msRest.ServiceCallback<Models.DecisionDetails>
  ): Promise<Models.TestApplicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      testApplicationOperationSpec,
      callback
    ) as Promise<Models.TestApplicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendBankDataRefresh(
    options?: Models.OriginationWriteApiSendBankDataRefreshOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendBankDataRefresh(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendBankDataRefresh(
    options: Models.OriginationWriteApiSendBankDataRefreshOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendBankDataRefresh(
    options?: Models.OriginationWriteApiSendBankDataRefreshOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendBankDataRefreshOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendBankDataRefreshOnStatus(
    options?: Models.OriginationWriteApiSendBankDataRefreshOnStatusOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendBankDataRefreshOnStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendBankDataRefreshOnStatus(
    options: Models.OriginationWriteApiSendBankDataRefreshOnStatusOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendBankDataRefreshOnStatus(
    options?: Models.OriginationWriteApiSendBankDataRefreshOnStatusOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendBankDataRefreshOnStatusOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendMessage(options?: Models.OriginationWriteApiSendMessageOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendMessage(
    options: Models.OriginationWriteApiSendMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendMessage(
    options?: Models.OriginationWriteApiSendMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendMessageOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  processContract(options?: Models.OriginationWriteApiProcessContractOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  processContract(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  processContract(
    options: Models.OriginationWriteApiProcessContractOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  processContract(
    options?: Models.OriginationWriteApiProcessContractOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      processContractOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  generatePartnerReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  generatePartnerReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  generatePartnerReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  generatePartnerReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      generatePartnerReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendSimpleMessage(options?: Models.OriginationWriteApiSendSimpleMessageOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendSimpleMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendSimpleMessage(
    options: Models.OriginationWriteApiSendSimpleMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendSimpleMessage(
    options?: Models.OriginationWriteApiSendSimpleMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendSimpleMessageOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendApplicationReady(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendApplicationReady(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendApplicationReady(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendApplicationReady(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendApplicationReadyOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildContract(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  buildContract(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  buildContract(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildContract(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      buildContractOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.CompleteApplicationResponse>
   */
  completeApplication(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.CompleteApplicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  completeApplication(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  completeApplication(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  completeApplication(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.CompleteApplicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      completeApplicationOperationSpec,
      callback
    ) as Promise<Models.CompleteApplicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resetLogin(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  resetLogin(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  resetLogin(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  resetLogin(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      resetLoginOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateApplicationResponse>
   */
  createApplication(
    options?: Models.OriginationWriteApiCreateApplicationOptionalParams
  ): Promise<Models.CreateApplicationResponse>;
  /**
   * @param callback The callback
   */
  createApplication(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createApplication(
    options: Models.OriginationWriteApiCreateApplicationOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  createApplication(
    options?: Models.OriginationWriteApiCreateApplicationOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.CreateApplicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createApplicationOperationSpec,
      callback
    ) as Promise<Models.CreateApplicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTempApplicationResponse>
   */
  createTempApplication(
    options?: Models.OriginationWriteApiCreateTempApplicationOptionalParams
  ): Promise<Models.CreateTempApplicationResponse>;
  /**
   * @param callback The callback
   */
  createTempApplication(callback: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createTempApplication(
    options: Models.OriginationWriteApiCreateTempApplicationOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>
  ): void;
  createTempApplication(
    options?:
      | Models.OriginationWriteApiCreateTempApplicationOptionalParams
      | msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>,
    callback?: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>
  ): Promise<Models.CreateTempApplicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createTempApplicationOperationSpec,
      callback
    ) as Promise<Models.CreateTempApplicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateApplicationAndCustomerResponse>
   */
  createApplicationAndCustomer(
    options?: Models.OriginationWriteApiCreateApplicationAndCustomerOptionalParams
  ): Promise<Models.CreateApplicationAndCustomerResponse>;
  /**
   * @param callback The callback
   */
  createApplicationAndCustomer(callback: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createApplicationAndCustomer(
    options: Models.OriginationWriteApiCreateApplicationAndCustomerOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>
  ): void;
  createApplicationAndCustomer(
    options?:
      | Models.OriginationWriteApiCreateApplicationAndCustomerOptionalParams
      | msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>,
    callback?: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>
  ): Promise<Models.CreateApplicationAndCustomerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createApplicationAndCustomerOperationSpec,
      callback
    ) as Promise<Models.CreateApplicationAndCustomerResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateApplicationAndCustomerAndSupportDocumentsResponse>
   */
  createApplicationAndCustomerAndSupportDocuments(
    options?: Models.OriginationWriteApiCreateApplicationAndCustomerAndSupportDocumentsOptionalParams
  ): Promise<Models.CreateApplicationAndCustomerAndSupportDocumentsResponse>;
  /**
   * @param callback The callback
   */
  createApplicationAndCustomerAndSupportDocuments(
    callback: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>
  ): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createApplicationAndCustomerAndSupportDocuments(
    options: Models.OriginationWriteApiCreateApplicationAndCustomerAndSupportDocumentsOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>
  ): void;
  createApplicationAndCustomerAndSupportDocuments(
    options?:
      | Models.OriginationWriteApiCreateApplicationAndCustomerAndSupportDocumentsOptionalParams
      | msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>,
    callback?: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>
  ): Promise<Models.CreateApplicationAndCustomerAndSupportDocumentsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createApplicationAndCustomerAndSupportDocumentsOperationSpec,
      callback
    ) as Promise<Models.CreateApplicationAndCustomerAndSupportDocumentsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadSupportDocuments(
    options?: Models.OriginationWriteApiUploadSupportDocumentsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  uploadSupportDocuments(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadSupportDocuments(
    options: Models.OriginationWriteApiUploadSupportDocumentsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadSupportDocuments(
    options?: Models.OriginationWriteApiUploadSupportDocumentsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      uploadSupportDocumentsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateApplicationAndCustomerAndApplicantAndSupportDocumentsResponse>
   */
  createApplicationAndCustomerAndApplicantAndSupportDocuments(
    options?: Models.OriginationWriteApiCreateApplicationAndCustomerAndApplicantAndSupportDocumentsOptionalParams
  ): Promise<Models.CreateApplicationAndCustomerAndApplicantAndSupportDocumentsResponse>;
  /**
   * @param callback The callback
   */
  createApplicationAndCustomerAndApplicantAndSupportDocuments(
    callback: msRest.ServiceCallback<Models.ApplicationAndCustomerAndApplicantResponse>
  ): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createApplicationAndCustomerAndApplicantAndSupportDocuments(
    options: Models.OriginationWriteApiCreateApplicationAndCustomerAndApplicantAndSupportDocumentsOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationAndCustomerAndApplicantResponse>
  ): void;
  createApplicationAndCustomerAndApplicantAndSupportDocuments(
    options?:
      | Models.OriginationWriteApiCreateApplicationAndCustomerAndApplicantAndSupportDocumentsOptionalParams
      | msRest.ServiceCallback<Models.ApplicationAndCustomerAndApplicantResponse>,
    callback?: msRest.ServiceCallback<Models.ApplicationAndCustomerAndApplicantResponse>
  ): Promise<Models.CreateApplicationAndCustomerAndApplicantAndSupportDocumentsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createApplicationAndCustomerAndApplicantAndSupportDocumentsOperationSpec,
      callback
    ) as Promise<Models.CreateApplicationAndCustomerAndApplicantAndSupportDocumentsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateApplicationAndCustomerWithApplicantResponse>
   */
  createApplicationAndCustomerWithApplicant(
    options?: Models.OriginationWriteApiCreateApplicationAndCustomerWithApplicantOptionalParams
  ): Promise<Models.CreateApplicationAndCustomerWithApplicantResponse>;
  /**
   * @param callback The callback
   */
  createApplicationAndCustomerWithApplicant(
    callback: msRest.ServiceCallback<Models.ApplicationAndCustomerAndApplicantResponse>
  ): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createApplicationAndCustomerWithApplicant(
    options: Models.OriginationWriteApiCreateApplicationAndCustomerWithApplicantOptionalParams,
    callback: msRest.ServiceCallback<Models.ApplicationAndCustomerAndApplicantResponse>
  ): void;
  createApplicationAndCustomerWithApplicant(
    options?:
      | Models.OriginationWriteApiCreateApplicationAndCustomerWithApplicantOptionalParams
      | msRest.ServiceCallback<Models.ApplicationAndCustomerAndApplicantResponse>,
    callback?: msRest.ServiceCallback<Models.ApplicationAndCustomerAndApplicantResponse>
  ): Promise<Models.CreateApplicationAndCustomerWithApplicantResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createApplicationAndCustomerWithApplicantOperationSpec,
      callback
    ) as Promise<Models.CreateApplicationAndCustomerWithApplicantResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateCustomerResponse>
   */
  updateCustomer(
    options?: Models.OriginationWriteApiUpdateCustomerOptionalParams
  ): Promise<Models.UpdateCustomerResponse>;
  /**
   * @param callback The callback
   */
  updateCustomer(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCustomer(
    options: Models.OriginationWriteApiUpdateCustomerOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updateCustomer(
    options?: Models.OriginationWriteApiUpdateCustomerOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdateCustomerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateCustomerOperationSpec,
      callback
    ) as Promise<Models.UpdateCustomerResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdatePartnerResponse>
   */
  updatePartner(options?: Models.OriginationWriteApiUpdatePartnerOptionalParams): Promise<Models.UpdatePartnerResponse>;
  /**
   * @param callback The callback
   */
  updatePartner(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartner(
    options: Models.OriginationWriteApiUpdatePartnerOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  updatePartner(
    options?: Models.OriginationWriteApiUpdatePartnerOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.UpdatePartnerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updatePartnerOperationSpec,
      callback
    ) as Promise<Models.UpdatePartnerResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateBusinessNameAndDetailsResponse>
   */
  updateBusinessNameAndDetails(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateBusinessNameAndDetailsOptionalParams
  ): Promise<Models.UpdateBusinessNameAndDetailsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateBusinessNameAndDetails(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBusinessNameAndDetails(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateBusinessNameAndDetailsOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updateBusinessNameAndDetails(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateBusinessNameAndDetailsOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdateBusinessNameAndDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateBusinessNameAndDetailsOperationSpec,
      callback
    ) as Promise<Models.UpdateBusinessNameAndDetailsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateBusinessNameResponse>
   */
  updateBusinessName(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateBusinessNameOptionalParams
  ): Promise<Models.UpdateBusinessNameResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateBusinessName(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBusinessName(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateBusinessNameOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updateBusinessName(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateBusinessNameOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdateBusinessNameResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateBusinessNameOperationSpec,
      callback
    ) as Promise<Models.UpdateBusinessNameResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateOfferReadyDateResponse>
   */
  updateOfferReadyDate(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateOfferReadyDateOptionalParams
  ): Promise<Models.UpdateOfferReadyDateResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateOfferReadyDate(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateOfferReadyDate(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateOfferReadyDateOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  updateOfferReadyDate(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateOfferReadyDateOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.UpdateOfferReadyDateResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateOfferReadyDateOperationSpec,
      callback
    ) as Promise<Models.UpdateOfferReadyDateResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateBusinessDetailsResponse>
   */
  updateBusinessDetails(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateBusinessDetailsOptionalParams
  ): Promise<Models.UpdateBusinessDetailsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateBusinessDetails(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBusinessDetails(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateBusinessDetailsOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updateBusinessDetails(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateBusinessDetailsOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdateBusinessDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateBusinessDetailsOperationSpec,
      callback
    ) as Promise<Models.UpdateBusinessDetailsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateApplicationResponse>
   */
  updateApplication(
    options?: Models.OriginationWriteApiUpdateApplicationOptionalParams
  ): Promise<Models.UpdateApplicationResponse>;
  /**
   * @param callback The callback
   */
  updateApplication(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplication(
    options: Models.OriginationWriteApiUpdateApplicationOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updateApplication(
    options?: Models.OriginationWriteApiUpdateApplicationOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdateApplicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateApplicationOperationSpec,
      callback
    ) as Promise<Models.UpdateApplicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ChangeStatusResponse>
   */
  changeStatus(options?: Models.OriginationWriteApiChangeStatusOptionalParams): Promise<Models.ChangeStatusResponse>;
  /**
   * @param callback The callback
   */
  changeStatus(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  changeStatus(
    options: Models.OriginationWriteApiChangeStatusOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  changeStatus(
    options?: Models.OriginationWriteApiChangeStatusOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.ChangeStatusResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      changeStatusOperationSpec,
      callback
    ) as Promise<Models.ChangeStatusResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateOfferResponse>
   */
  updateOffer(options?: Models.OriginationWriteApiUpdateOfferOptionalParams): Promise<Models.UpdateOfferResponse>;
  /**
   * @param callback The callback
   */
  updateOffer(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateOffer(
    options: Models.OriginationWriteApiUpdateOfferOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updateOffer(
    options?: Models.OriginationWriteApiUpdateOfferOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdateOfferResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateOfferOperationSpec,
      callback
    ) as Promise<Models.UpdateOfferResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateLoginStatusResponse>
   */
  updateLoginStatus(
    options?: Models.OriginationWriteApiUpdateLoginStatusOptionalParams
  ): Promise<Models.UpdateLoginStatusResponse>;
  /**
   * @param callback The callback
   */
  updateLoginStatus(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoginStatus(
    options: Models.OriginationWriteApiUpdateLoginStatusOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updateLoginStatus(
    options?: Models.OriginationWriteApiUpdateLoginStatusOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdateLoginStatusResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateLoginStatusOperationSpec,
      callback
    ) as Promise<Models.UpdateLoginStatusResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendCustomerApplication(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendCustomerApplication(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendCustomerApplication(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendCustomerApplication(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendCustomerApplicationOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterCommunicationResponse>
   */
  registerCommunication(
    applicationId: string,
    options?: Models.OriginationWriteApiRegisterCommunicationOptionalParams
  ): Promise<Models.RegisterCommunicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  registerCommunication(applicationId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerCommunication(
    applicationId: string,
    options: Models.OriginationWriteApiRegisterCommunicationOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  registerCommunication(
    applicationId: string,
    options?:
      | Models.OriginationWriteApiRegisterCommunicationOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.RegisterCommunicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      registerCommunicationOperationSpec,
      callback
    ) as Promise<Models.RegisterCommunicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  readCommunication(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  readCommunication(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  readCommunication(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  readCommunication(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      readCommunicationOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param communicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unregisterCommunication(
    applicationId: string,
    communicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param communicationId
   * @param callback The callback
   */
  unregisterCommunication(applicationId: string, communicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param communicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterCommunication(
    applicationId: string,
    communicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  unregisterCommunication(
    applicationId: string,
    communicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        communicationId,
        options
      },
      unregisterCommunicationOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendConnectionLink(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendConnectionLink(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendConnectionLink(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendConnectionLink(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendConnectionLinkOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  cancelApplication(
    applicationId: string,
    options?: Models.OriginationWriteApiCancelApplicationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  cancelApplication(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  cancelApplication(
    applicationId: string,
    options: Models.OriginationWriteApiCancelApplicationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  cancelApplication(
    applicationId: string,
    options?: Models.OriginationWriteApiCancelApplicationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      cancelApplicationOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  submittedApplication(
    applicationId: string,
    options?: Models.OriginationWriteApiSubmittedApplicationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  submittedApplication(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  submittedApplication(
    applicationId: string,
    options: Models.OriginationWriteApiSubmittedApplicationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  submittedApplication(
    applicationId: string,
    options?: Models.OriginationWriteApiSubmittedApplicationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      submittedApplicationOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  linkBankingData(
    applicationId: string,
    options?: Models.OriginationWriteApiLinkBankingDataOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  linkBankingData(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  linkBankingData(
    applicationId: string,
    options: Models.OriginationWriteApiLinkBankingDataOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  linkBankingData(
    applicationId: string,
    options?: Models.OriginationWriteApiLinkBankingDataOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      linkBankingDataOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @deprecated This operation is deprecated. Please do not use it any longer.
   * @returns Promise<msRest.RestResponse>
   */
  uploadBankStatementsDocumentId(
    applicationId: string,
    options?: Models.OriginationWriteApiUploadBankStatementsDocumentIdOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  uploadBankStatementsDocumentId(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  uploadBankStatementsDocumentId(
    applicationId: string,
    options: Models.OriginationWriteApiUploadBankStatementsDocumentIdOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadBankStatementsDocumentId(
    applicationId: string,
    options?: Models.OriginationWriteApiUploadBankStatementsDocumentIdOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      uploadBankStatementsDocumentIdOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  linkAccoutingData(
    applicationId: string,
    options?: Models.OriginationWriteApiLinkAccoutingDataOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  linkAccoutingData(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  linkAccoutingData(
    applicationId: string,
    options: Models.OriginationWriteApiLinkAccoutingDataOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  linkAccoutingData(
    applicationId: string,
    options?: Models.OriginationWriteApiLinkAccoutingDataOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      linkAccoutingDataOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  completed(
    applicationId: string,
    options?: Models.OriginationWriteApiCompletedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  completed(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  completed(
    applicationId: string,
    options: Models.OriginationWriteApiCompletedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  completed(
    applicationId: string,
    options?: Models.OriginationWriteApiCompletedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      completedOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicationCurrentProcess(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicationCurrentProcessOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateApplicationCurrentProcess(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicationCurrentProcess(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateApplicationCurrentProcessOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateApplicationCurrentProcess(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateApplicationCurrentProcessOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateApplicationCurrentProcessOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  refer(applicationId: string, options?: Models.OriginationWriteApiReferOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  refer(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  refer(
    applicationId: string,
    options: Models.OriginationWriteApiReferOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  refer(
    applicationId: string,
    options?: Models.OriginationWriteApiReferOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      referOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankAccounts(
    applicationId: string,
    options?: Models.OriginationWriteApiConfirmBankAccountsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankAccounts(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankAccounts(
    applicationId: string,
    options: Models.OriginationWriteApiConfirmBankAccountsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  confirmBankAccounts(
    applicationId: string,
    options?: Models.OriginationWriteApiConfirmBankAccountsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankAccountsOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  connectBankfeedAccount(
    applicationId: string,
    options?: Models.OriginationWriteApiConnectBankfeedAccountOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  connectBankfeedAccount(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  connectBankfeedAccount(
    applicationId: string,
    options: Models.OriginationWriteApiConnectBankfeedAccountOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  connectBankfeedAccount(
    applicationId: string,
    options?: Models.OriginationWriteApiConnectBankfeedAccountOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      connectBankfeedAccountOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  connectYodleeBankfeedAccount(
    applicationId: string,
    body: Models.BankAccountConnectionDetails,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param body
   * @param callback The callback
   */
  connectYodleeBankfeedAccount(
    applicationId: string,
    body: Models.BankAccountConnectionDetails,
    callback: msRest.ServiceCallback<void>
  ): void;
  /**
   * @param applicationId
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  connectYodleeBankfeedAccount(
    applicationId: string,
    body: Models.BankAccountConnectionDetails,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  connectYodleeBankfeedAccount(
    applicationId: string,
    body: Models.BankAccountConnectionDetails,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        body,
        options
      },
      connectYodleeBankfeedAccountOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param body
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  connectPlaidBankfeedAccount(
    applicationId: string,
    body: Models.BankAccountConnectionDetails,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param body
   * @param callback The callback
   */
  connectPlaidBankfeedAccount(
    applicationId: string,
    body: Models.BankAccountConnectionDetails,
    callback: msRest.ServiceCallback<void>
  ): void;
  /**
   * @param applicationId
   * @param body
   * @param options The optional parameters
   * @param callback The callback
   */
  connectPlaidBankfeedAccount(
    applicationId: string,
    body: Models.BankAccountConnectionDetails,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  connectPlaidBankfeedAccount(
    applicationId: string,
    body: Models.BankAccountConnectionDetails,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        body,
        options
      },
      connectPlaidBankfeedAccountOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankfeedAccount(
    applicationId: string,
    options?: Models.OriginationWriteApiConfirmBankfeedAccountOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankfeedAccount(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankfeedAccount(
    applicationId: string,
    options: Models.OriginationWriteApiConfirmBankfeedAccountOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  confirmBankfeedAccount(
    applicationId: string,
    options?: Models.OriginationWriteApiConfirmBankfeedAccountOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankfeedAccountOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankfeedAccounts(
    applicationId: string,
    options?: Models.OriginationWriteApiConfirmBankfeedAccountsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankfeedAccounts(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankfeedAccounts(
    applicationId: string,
    options: Models.OriginationWriteApiConfirmBankfeedAccountsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  confirmBankfeedAccounts(
    applicationId: string,
    options?: Models.OriginationWriteApiConfirmBankfeedAccountsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankfeedAccountsOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankfeedYodlee(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankfeedYodlee(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankfeedYodlee(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  confirmBankfeedYodlee(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankfeedYodleeOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankfeedPlaid(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankfeedPlaid(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankfeedPlaid(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  confirmBankfeedPlaid(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankfeedPlaidOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SendApplicationOtpResponse>
   */
  sendApplicationOtp(
    options?: Models.OriginationWriteApiSendApplicationOtpOptionalParams
  ): Promise<Models.SendApplicationOtpResponse>;
  /**
   * @param callback The callback
   */
  sendApplicationOtp(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendApplicationOtp(
    options: Models.OriginationWriteApiSendApplicationOtpOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  sendApplicationOtp(
    options?: Models.OriginationWriteApiSendApplicationOtpOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SendApplicationOtpResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendApplicationOtpOperationSpec,
      callback
    ) as Promise<Models.SendApplicationOtpResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendLoginOtp(options?: Models.OriginationWriteApiSendLoginOtpOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendLoginOtp(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendLoginOtp(
    options: Models.OriginationWriteApiSendLoginOtpOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendLoginOtp(
    options?: Models.OriginationWriteApiSendLoginOtpOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendLoginOtpOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendResetPasswordOtp(
    options?: Models.OriginationWriteApiSendResetPasswordOtpOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendResetPasswordOtp(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendResetPasswordOtp(
    options: Models.OriginationWriteApiSendResetPasswordOtpOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendResetPasswordOtp(
    options?: Models.OriginationWriteApiSendResetPasswordOtpOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendResetPasswordOtpOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ChangePasswordResponse>
   */
  changePassword(
    options?: Models.OriginationWriteApiChangePasswordOptionalParams
  ): Promise<Models.ChangePasswordResponse>;
  /**
   * @param callback The callback
   */
  changePassword(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  changePassword(
    options: Models.OriginationWriteApiChangePasswordOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  changePassword(
    options?: Models.OriginationWriteApiChangePasswordOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.ChangePasswordResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      changePasswordOperationSpec,
      callback
    ) as Promise<Models.ChangePasswordResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankfeedMFADetails(
    applicationId: string,
    options?: Models.OriginationWriteApiConfirmBankfeedMFADetailsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankfeedMFADetails(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankfeedMFADetails(
    applicationId: string,
    options: Models.OriginationWriteApiConfirmBankfeedMFADetailsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  confirmBankfeedMFADetails(
    applicationId: string,
    options?: Models.OriginationWriteApiConfirmBankfeedMFADetailsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankfeedMFADetailsOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  contractComplete(
    applicationId: string,
    options?: Models.OriginationWriteApiContractCompleteOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  contractComplete(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  contractComplete(
    applicationId: string,
    options: Models.OriginationWriteApiContractCompleteOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  contractComplete(
    applicationId: string,
    options?: Models.OriginationWriteApiContractCompleteOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      contractCompleteOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatedContractCompleted(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdatedContractCompletedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updatedContractCompleted(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updatedContractCompleted(
    applicationId: string,
    options: Models.OriginationWriteApiUpdatedContractCompletedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updatedContractCompleted(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdatedContractCompletedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updatedContractCompletedOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GenerateOfferResponse>
   */
  generateOffer(
    applicationId: string,
    options?: Models.OriginationWriteApiGenerateOfferOptionalParams
  ): Promise<Models.GenerateOfferResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  generateOffer(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  generateOffer(
    applicationId: string,
    options: Models.OriginationWriteApiGenerateOfferOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  generateOffer(
    applicationId: string,
    options?: Models.OriginationWriteApiGenerateOfferOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GenerateOfferResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      generateOfferOperationSpec,
      callback
    ) as Promise<Models.GenerateOfferResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  commissionMethod(
    applicationId: string,
    options?: Models.OriginationWriteApiCommissionMethodOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  commissionMethod(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  commissionMethod(
    applicationId: string,
    options: Models.OriginationWriteApiCommissionMethodOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  commissionMethod(
    applicationId: string,
    options?: Models.OriginationWriteApiCommissionMethodOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      commissionMethodOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  connectAccountingCompany(
    applicationId: string,
    options?: Models.OriginationWriteApiConnectAccountingCompanyOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  connectAccountingCompany(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  connectAccountingCompany(
    applicationId: string,
    options: Models.OriginationWriteApiConnectAccountingCompanyOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  connectAccountingCompany(
    applicationId: string,
    options?: Models.OriginationWriteApiConnectAccountingCompanyOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      connectAccountingCompanyOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  connectAccountingCompanyWithDetails(
    applicationId: string,
    options?: Models.OriginationWriteApiConnectAccountingCompanyWithDetailsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  connectAccountingCompanyWithDetails(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  connectAccountingCompanyWithDetails(
    applicationId: string,
    options: Models.OriginationWriteApiConnectAccountingCompanyWithDetailsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  connectAccountingCompanyWithDetails(
    applicationId: string,
    options?:
      | Models.OriginationWriteApiConnectAccountingCompanyWithDetailsOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      connectAccountingCompanyWithDetailsOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.AddLoanCustomerResponse>
   */
  addLoanCustomer(
    applicationId: string,
    options?: Models.OriginationWriteApiAddLoanCustomerOptionalParams
  ): Promise<Models.AddLoanCustomerResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addLoanCustomer(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addLoanCustomer(
    applicationId: string,
    options: Models.OriginationWriteApiAddLoanCustomerOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  addLoanCustomer(
    applicationId: string,
    options?: Models.OriginationWriteApiAddLoanCustomerOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.AddLoanCustomerResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addLoanCustomerOperationSpec,
      callback
    ) as Promise<Models.AddLoanCustomerResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateLoanCustomerResponse>
   */
  updateLoanCustomer(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateLoanCustomerOptionalParams
  ): Promise<Models.UpdateLoanCustomerResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateLoanCustomer(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanCustomer(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateLoanCustomerOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updateLoanCustomer(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateLoanCustomerOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdateLoanCustomerResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateLoanCustomerOperationSpec,
      callback
    ) as Promise<Models.UpdateLoanCustomerResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.RemoveLoanCustomerResponse>
   */
  removeLoanCustomer(
    applicationId: string,
    options?: Models.OriginationWriteApiRemoveLoanCustomerOptionalParams
  ): Promise<Models.RemoveLoanCustomerResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  removeLoanCustomer(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeLoanCustomer(
    applicationId: string,
    options: Models.OriginationWriteApiRemoveLoanCustomerOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  removeLoanCustomer(
    applicationId: string,
    options?: Models.OriginationWriteApiRemoveLoanCustomerOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.RemoveLoanCustomerResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      removeLoanCustomerOperationSpec,
      callback
    ) as Promise<Models.RemoveLoanCustomerResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removePartnerExternalLink(
    applicationId: string,
    options?: Models.OriginationWriteApiRemovePartnerExternalLinkOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  removePartnerExternalLink(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  removePartnerExternalLink(
    applicationId: string,
    options: Models.OriginationWriteApiRemovePartnerExternalLinkOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removePartnerExternalLink(
    applicationId: string,
    options?: Models.OriginationWriteApiRemovePartnerExternalLinkOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      removePartnerExternalLinkOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeAgentExternalLink(
    applicationId: string,
    options?: Models.OriginationWriteApiRemoveAgentExternalLinkOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  removeAgentExternalLink(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeAgentExternalLink(
    applicationId: string,
    options: Models.OriginationWriteApiRemoveAgentExternalLinkOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeAgentExternalLink(
    applicationId: string,
    options?: Models.OriginationWriteApiRemoveAgentExternalLinkOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      removeAgentExternalLinkOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setPrimaryAccount(
    applicationId: string,
    options?: Models.OriginationWriteApiSetPrimaryAccountOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  setPrimaryAccount(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  setPrimaryAccount(
    applicationId: string,
    options: Models.OriginationWriteApiSetPrimaryAccountOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setPrimaryAccount(
    applicationId: string,
    options?: Models.OriginationWriteApiSetPrimaryAccountOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      setPrimaryAccountOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateCommunication(
    options?: Models.OriginationWriteApiUpdateCommunicationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateCommunication(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCommunication(
    options: Models.OriginationWriteApiUpdateCommunicationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateCommunication(
    options?: Models.OriginationWriteApiUpdateCommunicationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateCommunicationOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addAuxiliaryBankAccount(
    organisationId: string,
    options?: Models.OriginationWriteApiAddAuxiliaryBankAccountOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  addAuxiliaryBankAccount(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addAuxiliaryBankAccount(
    organisationId: string,
    options: Models.OriginationWriteApiAddAuxiliaryBankAccountOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addAuxiliaryBankAccount(
    organisationId: string,
    options?: Models.OriginationWriteApiAddAuxiliaryBankAccountOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      addAuxiliaryBankAccountOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconnectPlaidBankfeedAccount(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  reconnectPlaidBankfeedAccount(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconnectPlaidBankfeedAccount(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  reconnectPlaidBankfeedAccount(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      reconnectPlaidBankfeedAccountOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconnectAuxiliaryBankAccounts(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  reconnectAuxiliaryBankAccounts(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconnectAuxiliaryBankAccounts(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  reconnectAuxiliaryBankAccounts(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      reconnectAuxiliaryBankAccountsOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconnectYodleeBankfeedAccount(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  reconnectYodleeBankfeedAccount(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconnectYodleeBankfeedAccount(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  reconnectYodleeBankfeedAccount(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      reconnectYodleeBankfeedAccountOperationSpec,
      callback
    );
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  logoutCustomer(customerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  logoutCustomer(customerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  logoutCustomer(customerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  logoutCustomer(
    customerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      logoutCustomerOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankAccountConnectionSettings(
    options?: Models.OriginationWriteApiBankAccountConnectionSettingsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankAccountConnectionSettings(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankAccountConnectionSettings(
    options: Models.OriginationWriteApiBankAccountConnectionSettingsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  bankAccountConnectionSettings(
    options?: Models.OriginationWriteApiBankAccountConnectionSettingsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankAccountConnectionSettingsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteApplicationResponse>
   */
  deleteApplication(
    options?: Models.OriginationWriteApiDeleteApplicationOptionalParams
  ): Promise<Models.DeleteApplicationResponse>;
  /**
   * @param callback The callback
   */
  deleteApplication(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteApplication(
    options: Models.OriginationWriteApiDeleteApplicationOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  deleteApplication(
    options?: Models.OriginationWriteApiDeleteApplicationOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.DeleteApplicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteApplicationOperationSpec,
      callback
    ) as Promise<Models.DeleteApplicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendAddAccountOtp(options?: Models.OriginationWriteApiSendAddAccountOtpOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendAddAccountOtp(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendAddAccountOtp(
    options: Models.OriginationWriteApiSendAddAccountOtpOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendAddAccountOtp(
    options?: Models.OriginationWriteApiSendAddAccountOtpOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendAddAccountOtpOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendCustomerAudit(
    applicationId: string,
    options?: Models.OriginationWriteApiSendCustomerAuditOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendCustomerAudit(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendCustomerAudit(
    applicationId: string,
    options: Models.OriginationWriteApiSendCustomerAuditOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendCustomerAudit(
    applicationId: string,
    options?: Models.OriginationWriteApiSendCustomerAuditOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendCustomerAuditOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendSalesTeamCalculation(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendSalesTeamCalculation(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendSalesTeamCalculation(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendSalesTeamCalculation(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendSalesTeamCalculationOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendCustomerOffer(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendCustomerOffer(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendCustomerOffer(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendCustomerOffer(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendCustomerOfferOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.AddUpdateAgentOnApplicationResponse>
   */
  addUpdateAgentOnApplication(
    applicationId: string,
    options?: Models.OriginationWriteApiAddUpdateAgentOnApplicationOptionalParams
  ): Promise<Models.AddUpdateAgentOnApplicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addUpdateAgentOnApplication(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addUpdateAgentOnApplication(
    applicationId: string,
    options: Models.OriginationWriteApiAddUpdateAgentOnApplicationOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  addUpdateAgentOnApplication(
    applicationId: string,
    options?: Models.OriginationWriteApiAddUpdateAgentOnApplicationOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.AddUpdateAgentOnApplicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addUpdateAgentOnApplicationOperationSpec,
      callback
    ) as Promise<Models.AddUpdateAgentOnApplicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resendUnderReviewApplication(
    options?: Models.OriginationWriteApiResendUnderReviewApplicationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  resendUnderReviewApplication(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  resendUnderReviewApplication(
    options: Models.OriginationWriteApiResendUnderReviewApplicationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  resendUnderReviewApplication(
    options?: Models.OriginationWriteApiResendUnderReviewApplicationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      resendUnderReviewApplicationOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  createEmptyOffer(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  createEmptyOffer(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  createEmptyOffer(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  createEmptyOffer(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      createEmptyOfferOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  draftApplicationAccess(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  draftApplicationAccess(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  draftApplicationAccess(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  draftApplicationAccess(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      draftApplicationAccessOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  markAsFraud(
    applicationId: string,
    options?: Models.OriginationWriteApiMarkAsFraudOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  markAsFraud(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  markAsFraud(
    applicationId: string,
    options: Models.OriginationWriteApiMarkAsFraudOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  markAsFraud(
    applicationId: string,
    options?: Models.OriginationWriteApiMarkAsFraudOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      markAsFraudOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendUnderReviewKycNote(
    applicationId: string,
    options?: Models.OriginationWriteApiSendUnderReviewKycNoteOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendUnderReviewKycNote(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendUnderReviewKycNote(
    applicationId: string,
    options: Models.OriginationWriteApiSendUnderReviewKycNoteOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendUnderReviewKycNote(
    applicationId: string,
    options?: Models.OriginationWriteApiSendUnderReviewKycNoteOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendUnderReviewKycNoteOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationBankBalanceSnapShotResponse>
   */
  getApplicationBankBalanceSnapShot(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationBankBalanceSnapShotResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getApplicationBankBalanceSnapShot(
    applicationId: string,
    callback: msRest.ServiceCallback<Models.ApplicationBankBalanceSnapShot>
  ): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationBankBalanceSnapShot(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicationBankBalanceSnapShot>
  ): void;
  getApplicationBankBalanceSnapShot(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationBankBalanceSnapShot>,
    callback?: msRest.ServiceCallback<Models.ApplicationBankBalanceSnapShot>
  ): Promise<Models.GetApplicationBankBalanceSnapShotResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getApplicationBankBalanceSnapShotOperationSpec,
      callback
    ) as Promise<Models.GetApplicationBankBalanceSnapShotResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  captureApplicationBankBalanceSnapShot(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  captureApplicationBankBalanceSnapShot(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  captureApplicationBankBalanceSnapShot(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  captureApplicationBankBalanceSnapShot(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      captureApplicationBankBalanceSnapShotOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setManualReview(
    applicationId: string,
    options?: Models.OriginationWriteApiSetManualReviewOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  setManualReview(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  setManualReview(
    applicationId: string,
    options: Models.OriginationWriteApiSetManualReviewOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setManualReview(
    applicationId: string,
    options?: Models.OriginationWriteApiSetManualReviewOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      setManualReviewOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteNote(options?: Models.OriginationWriteApiDeleteNoteOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteNote(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteNote(options: Models.OriginationWriteApiDeleteNoteOptionalParams, callback: msRest.ServiceCallback<void>): void;
  deleteNote(
    options?: Models.OriginationWriteApiDeleteNoteOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteNoteOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  testApplicationOrganisation(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  testApplicationOrganisation(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  testApplicationOrganisation(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  testApplicationOrganisation(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      testApplicationOrganisationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reprocessApplications(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  reprocessApplications(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  reprocessApplications(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  reprocessApplications(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      reprocessApplicationsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  expireOfferReadyApplications(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  expireOfferReadyApplications(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  expireOfferReadyApplications(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  expireOfferReadyApplications(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      expireOfferReadyApplicationsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reprocessOnStatus(options?: Models.OriginationWriteApiReprocessOnStatusOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  reprocessOnStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  reprocessOnStatus(
    options: Models.OriginationWriteApiReprocessOnStatusOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  reprocessOnStatus(
    options?: Models.OriginationWriteApiReprocessOnStatusOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      reprocessOnStatusOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  ensureLoansAreCorrectStatus(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  ensureLoansAreCorrectStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  ensureLoansAreCorrectStatus(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  ensureLoansAreCorrectStatus(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      ensureLoansAreCorrectStatusOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  ensureLoansAreCorrectFunder(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  ensureLoansAreCorrectFunder(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  ensureLoansAreCorrectFunder(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  ensureLoansAreCorrectFunder(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      ensureLoansAreCorrectFunderOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reprocessWaitingRoom(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  reprocessWaitingRoom(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  reprocessWaitingRoom(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  reprocessWaitingRoom(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      reprocessWaitingRoomOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resendEmailBankStatementMissingTransaction(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  resendEmailBankStatementMissingTransaction(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  resendEmailBankStatementMissingTransaction(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  resendEmailBankStatementMissingTransaction(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      resendEmailBankStatementMissingTransactionOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendToCustomerEmailBankStatementMissingTransaction(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  sendToCustomerEmailBankStatementMissingTransaction(
    organisationId: string,
    callback: msRest.ServiceCallback<void>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendToCustomerEmailBankStatementMissingTransaction(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendToCustomerEmailBankStatementMissingTransaction(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      sendToCustomerEmailBankStatementMissingTransactionOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendCommunicationBankStatementMissingTransaction(
    organisationId: string,
    options?: Models.OriginationWriteApiSendCommunicationBankStatementMissingTransactionOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  sendCommunicationBankStatementMissingTransaction(
    organisationId: string,
    callback: msRest.ServiceCallback<void>
  ): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendCommunicationBankStatementMissingTransaction(
    organisationId: string,
    options: Models.OriginationWriteApiSendCommunicationBankStatementMissingTransactionOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendCommunicationBankStatementMissingTransaction(
    organisationId: string,
    options?:
      | Models.OriginationWriteApiSendCommunicationBankStatementMissingTransactionOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      sendCommunicationBankStatementMissingTransactionOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addBankStatementMissingTransaction(
    organisationId: string,
    options?: Models.OriginationWriteApiAddBankStatementMissingTransactionOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  addBankStatementMissingTransaction(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addBankStatementMissingTransaction(
    organisationId: string,
    options: Models.OriginationWriteApiAddBankStatementMissingTransactionOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addBankStatementMissingTransaction(
    organisationId: string,
    options?: Models.OriginationWriteApiAddBankStatementMissingTransactionOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      addBankStatementMissingTransactionOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteBankStatementMissingTransaction(
    organisationId: string,
    options?: Models.OriginationWriteApiDeleteBankStatementMissingTransactionOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  deleteBankStatementMissingTransaction(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteBankStatementMissingTransaction(
    organisationId: string,
    options: Models.OriginationWriteApiDeleteBankStatementMissingTransactionOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  deleteBankStatementMissingTransaction(
    organisationId: string,
    options?:
      | Models.OriginationWriteApiDeleteBankStatementMissingTransactionOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      deleteBankStatementMissingTransactionOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadBankStatementDocument(
    organisationId: string,
    options?: Models.OriginationWriteApiUploadBankStatementDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  uploadBankStatementDocument(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadBankStatementDocument(
    organisationId: string,
    options: Models.OriginationWriteApiUploadBankStatementDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadBankStatementDocument(
    organisationId: string,
    options?: Models.OriginationWriteApiUploadBankStatementDocumentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      uploadBankStatementDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadNewBankStatementDocument(
    organisationId: string,
    options?: Models.OriginationWriteApiUploadNewBankStatementDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  uploadNewBankStatementDocument(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadNewBankStatementDocument(
    organisationId: string,
    options: Models.OriginationWriteApiUploadNewBankStatementDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadNewBankStatementDocument(
    organisationId: string,
    options?: Models.OriginationWriteApiUploadNewBankStatementDocumentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      uploadNewBankStatementDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadBankStatementDocuments(
    organisationId: string,
    options?: Models.OriginationWriteApiUploadBankStatementDocumentsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  uploadBankStatementDocuments(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadBankStatementDocuments(
    organisationId: string,
    options: Models.OriginationWriteApiUploadBankStatementDocumentsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadBankStatementDocuments(
    organisationId: string,
    options?: Models.OriginationWriteApiUploadBankStatementDocumentsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      uploadBankStatementDocumentsOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteBankStatementDocument(
    organisationId: string,
    options?: Models.OriginationWriteApiDeleteBankStatementDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  deleteBankStatementDocument(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteBankStatementDocument(
    organisationId: string,
    options: Models.OriginationWriteApiDeleteBankStatementDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  deleteBankStatementDocument(
    organisationId: string,
    options?: Models.OriginationWriteApiDeleteBankStatementDocumentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      deleteBankStatementDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadBankStatementMissingTransactionDocument(
    organisationId: string,
    options?: Models.OriginationWriteApiUploadBankStatementMissingTransactionDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  uploadBankStatementMissingTransactionDocument(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadBankStatementMissingTransactionDocument(
    organisationId: string,
    options: Models.OriginationWriteApiUploadBankStatementMissingTransactionDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadBankStatementMissingTransactionDocument(
    organisationId: string,
    options?:
      | Models.OriginationWriteApiUploadBankStatementMissingTransactionDocumentOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      uploadBankStatementMissingTransactionDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteBankStatementMissingTransactionDocument(
    organisationId: string,
    options?: Models.OriginationWriteApiDeleteBankStatementMissingTransactionDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  deleteBankStatementMissingTransactionDocument(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteBankStatementMissingTransactionDocument(
    organisationId: string,
    options: Models.OriginationWriteApiDeleteBankStatementMissingTransactionDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  deleteBankStatementMissingTransactionDocument(
    organisationId: string,
    options?:
      | Models.OriginationWriteApiDeleteBankStatementMissingTransactionDocumentOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      deleteBankStatementMissingTransactionDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankStatementUploadComplete(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  bankStatementUploadComplete(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  bankStatementUploadComplete(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  bankStatementUploadComplete(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      bankStatementUploadCompleteOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  processThirdPartyBankStatements(
    organisationId: string,
    options?: Models.OriginationWriteApiProcessThirdPartyBankStatementsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  processThirdPartyBankStatements(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  processThirdPartyBankStatements(
    organisationId: string,
    options: Models.OriginationWriteApiProcessThirdPartyBankStatementsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  processThirdPartyBankStatements(
    organisationId: string,
    options?: Models.OriginationWriteApiProcessThirdPartyBankStatementsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      processThirdPartyBankStatementsOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadThirdPartyTransactions(
    organisationId: string,
    options?: Models.OriginationWriteApiUploadThirdPartyTransactionsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  uploadThirdPartyTransactions(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadThirdPartyTransactions(
    organisationId: string,
    options: Models.OriginationWriteApiUploadThirdPartyTransactionsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadThirdPartyTransactions(
    organisationId: string,
    options?: Models.OriginationWriteApiUploadThirdPartyTransactionsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      uploadThirdPartyTransactionsOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadAllThirdPartyTransactions(
    organisationId: string,
    options?: Models.OriginationWriteApiUploadAllThirdPartyTransactionsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  uploadAllThirdPartyTransactions(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadAllThirdPartyTransactions(
    organisationId: string,
    options: Models.OriginationWriteApiUploadAllThirdPartyTransactionsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadAllThirdPartyTransactions(
    organisationId: string,
    options?: Models.OriginationWriteApiUploadAllThirdPartyTransactionsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      uploadAllThirdPartyTransactionsOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param chargeOffDate
   * @param [options] The optional parameters
   * @returns Promise<Models.SetChargeOffResponse>
   */
  setChargeOff(
    loanId: string,
    chargeOffDate: Date | string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.SetChargeOffResponse>;
  /**
   * @param loanId
   * @param chargeOffDate
   * @param callback The callback
   */
  setChargeOff(loanId: string, chargeOffDate: Date | string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param loanId
   * @param chargeOffDate
   * @param options The optional parameters
   * @param callback The callback
   */
  setChargeOff(
    loanId: string,
    chargeOffDate: Date | string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  setChargeOff(
    loanId: string,
    chargeOffDate: Date | string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.SetChargeOffResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        chargeOffDate,
        options
      },
      setChargeOffOperationSpec,
      callback
    ) as Promise<Models.SetChargeOffResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.TestChargeOffResponse>
   */
  testChargeOff(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.TestChargeOffResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  testChargeOff(loanId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  testChargeOff(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  testChargeOff(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.TestChargeOffResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      testChargeOffOperationSpec,
      callback
    ) as Promise<Models.TestChargeOffResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  testAllChargeOff(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  testAllChargeOff(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  testAllChargeOff(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  testAllChargeOff(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      testAllChargeOffOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildManualCheckList(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  buildManualCheckList(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  buildManualCheckList(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildManualCheckList(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      buildManualCheckListOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateManualCheckList(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateManualCheckListOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateManualCheckList(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateManualCheckList(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateManualCheckListOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateManualCheckList(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateManualCheckListOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateManualCheckListOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildSettlementCheckList(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  buildSettlementCheckList(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  buildSettlementCheckList(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildSettlementCheckList(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      buildSettlementCheckListOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateSettlementCheckList(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateSettlementCheckListOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateSettlementCheckList(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateSettlementCheckList(
    applicationId: string,
    options: Models.OriginationWriteApiUpdateSettlementCheckListOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateSettlementCheckList(
    applicationId: string,
    options?: Models.OriginationWriteApiUpdateSettlementCheckListOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateSettlementCheckListOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AddUpdateAgentCommunicationResponse>
   */
  addUpdateAgentCommunication(
    options?: Models.OriginationWriteApiAddUpdateAgentCommunicationOptionalParams
  ): Promise<Models.AddUpdateAgentCommunicationResponse>;
  /**
   * @param callback The callback
   */
  addUpdateAgentCommunication(callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addUpdateAgentCommunication(
    options: Models.OriginationWriteApiAddUpdateAgentCommunicationOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  addUpdateAgentCommunication(
    options?:
      | Models.OriginationWriteApiAddUpdateAgentCommunicationOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.AddUpdateAgentCommunicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addUpdateAgentCommunicationOperationSpec,
      callback
    ) as Promise<Models.AddUpdateAgentCommunicationResponse>;
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  readAgentCommunication(
    agentId: string,
    options?: Models.OriginationWriteApiReadAgentCommunicationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  readAgentCommunication(agentId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  readAgentCommunication(
    agentId: string,
    options: Models.OriginationWriteApiReadAgentCommunicationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  readAgentCommunication(
    agentId: string,
    options?: Models.OriginationWriteApiReadAgentCommunicationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      readAgentCommunicationOperationSpec,
      callback
    );
  }

  /**
   * @param agentId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteAgentCommunication(
    agentId: string,
    options?: Models.OriginationWriteApiDeleteAgentCommunicationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param agentId
   * @param callback The callback
   */
  deleteAgentCommunication(agentId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param agentId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteAgentCommunication(
    agentId: string,
    options: Models.OriginationWriteApiDeleteAgentCommunicationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  deleteAgentCommunication(
    agentId: string,
    options?: Models.OriginationWriteApiDeleteAgentCommunicationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        agentId,
        options
      },
      deleteAgentCommunicationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AddUpdatePartnerCommunicationResponse>
   */
  addUpdatePartnerCommunication(
    options?: Models.OriginationWriteApiAddUpdatePartnerCommunicationOptionalParams
  ): Promise<Models.AddUpdatePartnerCommunicationResponse>;
  /**
   * @param callback The callback
   */
  addUpdatePartnerCommunication(callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addUpdatePartnerCommunication(
    options: Models.OriginationWriteApiAddUpdatePartnerCommunicationOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  addUpdatePartnerCommunication(
    options?:
      | Models.OriginationWriteApiAddUpdatePartnerCommunicationOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.AddUpdatePartnerCommunicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addUpdatePartnerCommunicationOperationSpec,
      callback
    ) as Promise<Models.AddUpdatePartnerCommunicationResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  readPartnerCommunication(partnerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  readPartnerCommunication(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  readPartnerCommunication(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  readPartnerCommunication(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      readPartnerCommunicationOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  readPartnerCommunication1(
    partnerId: string,
    options?: Models.OriginationWriteApiReadPartnerCommunication1OptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  readPartnerCommunication1(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  readPartnerCommunication1(
    partnerId: string,
    options: Models.OriginationWriteApiReadPartnerCommunication1OptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  readPartnerCommunication1(
    partnerId: string,
    options?: Models.OriginationWriteApiReadPartnerCommunication1OptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      readPartnerCommunication1OperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deletePartnerCommunication(
    partnerId: string,
    options?: Models.OriginationWriteApiDeletePartnerCommunicationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  deletePartnerCommunication(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  deletePartnerCommunication(
    partnerId: string,
    options: Models.OriginationWriteApiDeletePartnerCommunicationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  deletePartnerCommunication(
    partnerId: string,
    options?: Models.OriginationWriteApiDeletePartnerCommunicationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      deletePartnerCommunicationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  forwardNotification(
    options?: Models.OriginationWriteApiForwardNotificationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  forwardNotification(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  forwardNotification(
    options: Models.OriginationWriteApiForwardNotificationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  forwardNotification(
    options?: Models.OriginationWriteApiForwardNotificationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      forwardNotificationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setCommunicationRule(
    options?: Models.OriginationWriteApiSetCommunicationRuleOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setCommunicationRule(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setCommunicationRule(
    options: Models.OriginationWriteApiSetCommunicationRuleOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setCommunicationRule(
    options?: Models.OriginationWriteApiSetCommunicationRuleOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setCommunicationRuleOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setCommunicationRules(
    options?: Models.OriginationWriteApiSetCommunicationRulesOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setCommunicationRules(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setCommunicationRules(
    options: Models.OriginationWriteApiSetCommunicationRulesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setCommunicationRules(
    options?: Models.OriginationWriteApiSetCommunicationRulesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setCommunicationRulesOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteCommunicationRule(
    options?: Models.OriginationWriteApiDeleteCommunicationRuleOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteCommunicationRule(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteCommunicationRule(
    options: Models.OriginationWriteApiDeleteCommunicationRuleOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  deleteCommunicationRule(
    options?: Models.OriginationWriteApiDeleteCommunicationRuleOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteCommunicationRuleOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateCommunicationRuleResponse>
   */
  updateCommunicationRule(
    options?: Models.OriginationWriteApiUpdateCommunicationRuleOptionalParams
  ): Promise<Models.UpdateCommunicationRuleResponse>;
  /**
   * @param callback The callback
   */
  updateCommunicationRule(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCommunicationRule(
    options: Models.OriginationWriteApiUpdateCommunicationRuleOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  updateCommunicationRule(
    options?: Models.OriginationWriteApiUpdateCommunicationRuleOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.UpdateCommunicationRuleResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateCommunicationRuleOperationSpec,
      callback
    ) as Promise<Models.UpdateCommunicationRuleResponse>;
  }

  /**
   * @param customerId
   * @param connectionId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setCustomerConnection(
    customerId: string,
    connectionId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param customerId
   * @param connectionId
   * @param callback The callback
   */
  setCustomerConnection(customerId: string, connectionId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param customerId
   * @param connectionId
   * @param options The optional parameters
   * @param callback The callback
   */
  setCustomerConnection(
    customerId: string,
    connectionId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  setCustomerConnection(
    customerId: string,
    connectionId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        connectionId,
        options
      },
      setCustomerConnectionOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TestCustomerConnectionResponse>
   */
  testCustomerConnection(
    options?: Models.OriginationWriteApiTestCustomerConnectionOptionalParams
  ): Promise<Models.TestCustomerConnectionResponse>;
  /**
   * @param callback The callback
   */
  testCustomerConnection(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  testCustomerConnection(
    options: Models.OriginationWriteApiTestCustomerConnectionOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  testCustomerConnection(
    options?: Models.OriginationWriteApiTestCustomerConnectionOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.TestCustomerConnectionResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      testCustomerConnectionOperationSpec,
      callback
    ) as Promise<Models.TestCustomerConnectionResponse>;
  }

  /**
   * @param connectionId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resetCustomerConnection(connectionId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param connectionId
   * @param callback The callback
   */
  resetCustomerConnection(connectionId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param connectionId
   * @param options The optional parameters
   * @param callback The callback
   */
  resetCustomerConnection(
    connectionId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  resetCustomerConnection(
    connectionId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        connectionId,
        options
      },
      resetCustomerConnectionOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  startupConfig(options?: Models.OriginationWriteApiStartupConfigOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  startupConfig(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  startupConfig(
    options: Models.OriginationWriteApiStartupConfigOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  startupConfig(
    options?: Models.OriginationWriteApiStartupConfigOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      startupConfigOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableAccountingSystem(
    options?: Models.OriginationWriteApiEnableAccountingSystemOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  enableAccountingSystem(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  enableAccountingSystem(
    options: Models.OriginationWriteApiEnableAccountingSystemOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  enableAccountingSystem(
    options?: Models.OriginationWriteApiEnableAccountingSystemOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      enableAccountingSystemOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableApplicantImageCapture(
    options?: Models.OriginationWriteApiEnableApplicantImageCaptureOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  enableApplicantImageCapture(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  enableApplicantImageCapture(
    options: Models.OriginationWriteApiEnableApplicantImageCaptureOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  enableApplicantImageCapture(
    options?: Models.OriginationWriteApiEnableApplicantImageCaptureOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      enableApplicantImageCaptureOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addContact(
    applicationId: string,
    options?: Models.OriginationWriteApiAddContactOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addContact(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addContact(
    applicationId: string,
    options: Models.OriginationWriteApiAddContactOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addContact(
    applicationId: string,
    options?: Models.OriginationWriteApiAddContactOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addContactOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param contactId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeContact(
    applicationId: string,
    contactId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param contactId
   * @param callback The callback
   */
  removeContact(applicationId: string, contactId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param contactId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeContact(
    applicationId: string,
    contactId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeContact(
    applicationId: string,
    contactId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        contactId,
        options
      },
      removeContactOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param contactId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  editContactNotes(
    applicationId: string,
    contactId: string,
    options?: Models.OriginationWriteApiEditContactNotesOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param contactId
   * @param callback The callback
   */
  editContactNotes(applicationId: string, contactId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param contactId
   * @param options The optional parameters
   * @param callback The callback
   */
  editContactNotes(
    applicationId: string,
    contactId: string,
    options: Models.OriginationWriteApiEditContactNotesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  editContactNotes(
    applicationId: string,
    contactId: string,
    options?: Models.OriginationWriteApiEditContactNotesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        contactId,
        options
      },
      editContactNotesOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param contactId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  editContactStatus(
    applicationId: string,
    contactId: string,
    options?: Models.OriginationWriteApiEditContactStatusOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param contactId
   * @param callback The callback
   */
  editContactStatus(applicationId: string, contactId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param contactId
   * @param options The optional parameters
   * @param callback The callback
   */
  editContactStatus(
    applicationId: string,
    contactId: string,
    options: Models.OriginationWriteApiEditContactStatusOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  editContactStatus(
    applicationId: string,
    contactId: string,
    options?: Models.OriginationWriteApiEditContactStatusOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        contactId,
        options
      },
      editContactStatusOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addCustomerAnnouncement(
    options?: Models.OriginationWriteApiAddCustomerAnnouncementOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addCustomerAnnouncement(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addCustomerAnnouncement(
    options: Models.OriginationWriteApiAddCustomerAnnouncementOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addCustomerAnnouncement(
    options?: Models.OriginationWriteApiAddCustomerAnnouncementOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addCustomerAnnouncementOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeCustomerAnnouncement(
    options?: Models.OriginationWriteApiRemoveCustomerAnnouncementOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removeCustomerAnnouncement(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removeCustomerAnnouncement(
    options: Models.OriginationWriteApiRemoveCustomerAnnouncementOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeCustomerAnnouncement(
    options?: Models.OriginationWriteApiRemoveCustomerAnnouncementOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removeCustomerAnnouncementOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AddCustomersResponse>
   */
  addCustomers(options?: Models.OriginationWriteApiAddCustomersOptionalParams): Promise<Models.AddCustomersResponse>;
  /**
   * @param callback The callback
   */
  addCustomers(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addCustomers(
    options: Models.OriginationWriteApiAddCustomersOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  addCustomers(
    options?: Models.OriginationWriteApiAddCustomersOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.AddCustomersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addCustomersOperationSpec,
      callback
    ) as Promise<Models.AddCustomersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AddCustomerResponse>
   */
  addCustomer(options?: Models.OriginationWriteApiAddCustomerOptionalParams): Promise<Models.AddCustomerResponse>;
  /**
   * @param callback The callback
   */
  addCustomer(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addCustomer(
    options: Models.OriginationWriteApiAddCustomerOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  addCustomer(
    options?: Models.OriginationWriteApiAddCustomerOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.AddCustomerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addCustomerOperationSpec,
      callback
    ) as Promise<Models.AddCustomerResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.SendOtpResponse>
   */
  sendOtp(customerId: string, options?: msRest.RequestOptionsBase): Promise<Models.SendOtpResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  sendOtp(customerId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendOtp(customerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  sendOtp(
    customerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SendOtpResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      sendOtpOperationSpec,
      callback
    ) as Promise<Models.SendOtpResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ResetPassswordResponse>
   */
  resetPasssword(
    options?: Models.OriginationWriteApiResetPassswordOptionalParams
  ): Promise<Models.ResetPassswordResponse>;
  /**
   * @param callback The callback
   */
  resetPasssword(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  resetPasssword(
    options: Models.OriginationWriteApiResetPassswordOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  resetPasssword(
    options?: Models.OriginationWriteApiResetPassswordOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.ResetPassswordResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      resetPassswordOperationSpec,
      callback
    ) as Promise<Models.ResetPassswordResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateCustomer1Response>
   */
  updateCustomer1(
    id: string,
    options?: Models.OriginationWriteApiUpdateCustomer1OptionalParams
  ): Promise<Models.UpdateCustomer1Response>;
  /**
   * @param id
   * @param callback The callback
   */
  updateCustomer1(id: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCustomer1(
    id: string,
    options: Models.OriginationWriteApiUpdateCustomer1OptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updateCustomer1(
    id: string,
    options?: Models.OriginationWriteApiUpdateCustomer1OptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdateCustomer1Response> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      updateCustomer1OperationSpec,
      callback
    ) as Promise<Models.UpdateCustomer1Response>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disableCustomer(options?: Models.OriginationWriteApiDisableCustomerOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  disableCustomer(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  disableCustomer(
    options: Models.OriginationWriteApiDisableCustomerOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  disableCustomer(
    options?: Models.OriginationWriteApiDisableCustomerOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      disableCustomerOperationSpec,
      callback
    );
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setUserRoles(
    customerId: string,
    options?: Models.OriginationWriteApiSetUserRolesOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  setUserRoles(customerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  setUserRoles(
    customerId: string,
    options: Models.OriginationWriteApiSetUserRolesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setUserRoles(
    customerId: string,
    options?: Models.OriginationWriteApiSetUserRolesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      setUserRolesOperationSpec,
      callback
    );
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeUserRoles(
    customerId: string,
    options?: Models.OriginationWriteApiRemoveUserRolesOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  removeUserRoles(customerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeUserRoles(
    customerId: string,
    options: Models.OriginationWriteApiRemoveUserRolesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeUserRoles(
    customerId: string,
    options?: Models.OriginationWriteApiRemoveUserRolesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      removeUserRolesOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DisableUnusedCustomersResponse>
   */
  disableUnusedCustomers(
    options?: Models.OriginationWriteApiDisableUnusedCustomersOptionalParams
  ): Promise<Models.DisableUnusedCustomersResponse>;
  /**
   * @param callback The callback
   */
  disableUnusedCustomers(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  disableUnusedCustomers(
    options: Models.OriginationWriteApiDisableUnusedCustomersOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  disableUnusedCustomers(
    options?: Models.OriginationWriteApiDisableUnusedCustomersOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.DisableUnusedCustomersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      disableUnusedCustomersOperationSpec,
      callback
    ) as Promise<Models.DisableUnusedCustomersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DisableClosedLoanCustomersResponse>
   */
  disableClosedLoanCustomers(
    options?: Models.OriginationWriteApiDisableClosedLoanCustomersOptionalParams
  ): Promise<Models.DisableClosedLoanCustomersResponse>;
  /**
   * @param callback The callback
   */
  disableClosedLoanCustomers(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  disableClosedLoanCustomers(
    options: Models.OriginationWriteApiDisableClosedLoanCustomersOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  disableClosedLoanCustomers(
    options?: Models.OriginationWriteApiDisableClosedLoanCustomersOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.DisableClosedLoanCustomersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      disableClosedLoanCustomersOperationSpec,
      callback
    ) as Promise<Models.DisableClosedLoanCustomersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateDashboardInsights(
    options?: Models.OriginationWriteApiUpdateDashboardInsightsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateDashboardInsights(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateDashboardInsights(
    options: Models.OriginationWriteApiUpdateDashboardInsightsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateDashboardInsights(
    options?: Models.OriginationWriteApiUpdateDashboardInsightsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateDashboardInsightsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateDashboardOnLoan(
    options?: Models.OriginationWriteApiUpdateDashboardOnLoanOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateDashboardOnLoan(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateDashboardOnLoan(
    options: Models.OriginationWriteApiUpdateDashboardOnLoanOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateDashboardOnLoan(
    options?: Models.OriginationWriteApiUpdateDashboardOnLoanOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateDashboardOnLoanOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateDashboardOnAllLoans(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateDashboardOnAllLoans(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateDashboardOnAllLoans(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateDashboardOnAllLoans(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateDashboardOnAllLoansOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateAccountDashboardOnAllLoans(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateAccountDashboardOnAllLoans(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAccountDashboardOnAllLoans(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateAccountDashboardOnAllLoans(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateAccountDashboardOnAllLoansOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addDisplayMessage(options?: Models.OriginationWriteApiAddDisplayMessageOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addDisplayMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addDisplayMessage(
    options: Models.OriginationWriteApiAddDisplayMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addDisplayMessage(
    options?: Models.OriginationWriteApiAddDisplayMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addDisplayMessageOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeDisplayMessage(
    options?: Models.OriginationWriteApiRemoveDisplayMessageOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removeDisplayMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removeDisplayMessage(
    options: Models.OriginationWriteApiRemoveDisplayMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeDisplayMessage(
    options?: Models.OriginationWriteApiRemoveDisplayMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removeDisplayMessageOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  dismissDisplayMessage(
    options?: Models.OriginationWriteApiDismissDisplayMessageOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  dismissDisplayMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  dismissDisplayMessage(
    options: Models.OriginationWriteApiDismissDisplayMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  dismissDisplayMessage(
    options?: Models.OriginationWriteApiDismissDisplayMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      dismissDisplayMessageOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addQuestionMessage(
    options?: Models.OriginationWriteApiAddQuestionMessageOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addQuestionMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addQuestionMessage(
    options: Models.OriginationWriteApiAddQuestionMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addQuestionMessage(
    options?: Models.OriginationWriteApiAddQuestionMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addQuestionMessageOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeQuestionMessage(
    options?: Models.OriginationWriteApiRemoveQuestionMessageOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removeQuestionMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removeQuestionMessage(
    options: Models.OriginationWriteApiRemoveQuestionMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeQuestionMessage(
    options?: Models.OriginationWriteApiRemoveQuestionMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removeQuestionMessageOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  repliedQuestionMessage(
    options?: Models.OriginationWriteApiRepliedQuestionMessageOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  repliedQuestionMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  repliedQuestionMessage(
    options: Models.OriginationWriteApiRepliedQuestionMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  repliedQuestionMessage(
    options?: Models.OriginationWriteApiRepliedQuestionMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      repliedQuestionMessageOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addUpdateSystemMessage(
    options?: Models.OriginationWriteApiAddUpdateSystemMessageOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addUpdateSystemMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addUpdateSystemMessage(
    options: Models.OriginationWriteApiAddUpdateSystemMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addUpdateSystemMessage(
    options?: Models.OriginationWriteApiAddUpdateSystemMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addUpdateSystemMessageOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeSystemMessage(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removeSystemMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removeSystemMessage(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  removeSystemMessage(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removeSystemMessageOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadDocument(
    applicationId: string,
    options?: Models.OriginationWriteApiUploadDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  uploadDocument(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadDocument(
    applicationId: string,
    options: Models.OriginationWriteApiUploadDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadDocument(
    applicationId: string,
    options?: Models.OriginationWriteApiUploadDocumentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      uploadDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadDocumentByArray(
    applicationId: string,
    options?: Models.OriginationWriteApiUploadDocumentByArrayOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  uploadDocumentByArray(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadDocumentByArray(
    applicationId: string,
    options: Models.OriginationWriteApiUploadDocumentByArrayOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadDocumentByArray(
    applicationId: string,
    options?: Models.OriginationWriteApiUploadDocumentByArrayOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      uploadDocumentByArrayOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadDocuments(
    applicationId: string,
    options?: Models.OriginationWriteApiUploadDocumentsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  uploadDocuments(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadDocuments(
    applicationId: string,
    options: Models.OriginationWriteApiUploadDocumentsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadDocuments(
    applicationId: string,
    options?: Models.OriginationWriteApiUploadDocumentsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      uploadDocumentsOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteDocument(
    applicationId: string,
    options?: Models.OriginationWriteApiDeleteDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  deleteDocument(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteDocument(
    applicationId: string,
    options: Models.OriginationWriteApiDeleteDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  deleteDocument(
    applicationId: string,
    options?: Models.OriginationWriteApiDeleteDocumentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      deleteDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RequestEarlyRepaymentDetailsResponse>
   */
  requestEarlyRepaymentDetails(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.RequestEarlyRepaymentDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  requestEarlyRepaymentDetails(loanId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  requestEarlyRepaymentDetails(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  requestEarlyRepaymentDetails(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.RequestEarlyRepaymentDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      requestEarlyRepaymentDetailsOperationSpec,
      callback
    ) as Promise<Models.RequestEarlyRepaymentDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RequestExtraRepaymentDetailsResponse>
   */
  requestExtraRepaymentDetails(
    loanId: string,
    options?: Models.OriginationWriteApiRequestExtraRepaymentDetailsOptionalParams
  ): Promise<Models.RequestExtraRepaymentDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  requestExtraRepaymentDetails(loanId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  requestExtraRepaymentDetails(
    loanId: string,
    options: Models.OriginationWriteApiRequestExtraRepaymentDetailsOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  requestExtraRepaymentDetails(
    loanId: string,
    options?: Models.OriginationWriteApiRequestExtraRepaymentDetailsOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.RequestExtraRepaymentDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      requestExtraRepaymentDetailsOperationSpec,
      callback
    ) as Promise<Models.RequestExtraRepaymentDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendIssueDefaultEmail(
    loanId: string,
    options?: Models.OriginationWriteApiSendIssueDefaultEmailOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  sendIssueDefaultEmail(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendIssueDefaultEmail(
    loanId: string,
    options: Models.OriginationWriteApiSendIssueDefaultEmailOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendIssueDefaultEmail(
    loanId: string,
    options?: Models.OriginationWriteApiSendIssueDefaultEmailOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      sendIssueDefaultEmailOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadBaseSupportDocument(
    options?: Models.OriginationWriteApiUploadBaseSupportDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  uploadBaseSupportDocument(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadBaseSupportDocument(
    options: Models.OriginationWriteApiUploadBaseSupportDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadBaseSupportDocument(
    options?: Models.OriginationWriteApiUploadBaseSupportDocumentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      uploadBaseSupportDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateBaseSupportDocumentForApplication(
    options?: Models.OriginationWriteApiUpdateBaseSupportDocumentForApplicationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateBaseSupportDocumentForApplication(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBaseSupportDocumentForApplication(
    options: Models.OriginationWriteApiUpdateBaseSupportDocumentForApplicationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateBaseSupportDocumentForApplication(
    options?:
      | Models.OriginationWriteApiUpdateBaseSupportDocumentForApplicationOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateBaseSupportDocumentForApplicationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  overrideContract(options?: Models.OriginationWriteApiOverrideContractOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  overrideContract(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  overrideContract(
    options: Models.OriginationWriteApiOverrideContractOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  overrideContract(
    options?: Models.OriginationWriteApiOverrideContractOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      overrideContractOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadW9SampleDocument(
    options?: Models.OriginationWriteApiUploadW9SampleDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  uploadW9SampleDocument(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadW9SampleDocument(
    options: Models.OriginationWriteApiUploadW9SampleDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadW9SampleDocument(
    options?: Models.OriginationWriteApiUploadW9SampleDocumentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      uploadW9SampleDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addTestFile(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addTestFile(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addTestFile(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  addTestFile(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addTestFileOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteTestFile(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteTestFile(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteTestFile(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  deleteTestFile(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteTestFileOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  intelligenceSearchAndAdd(
    options?: Models.OriginationWriteApiIntelligenceSearchAndAddOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  intelligenceSearchAndAdd(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  intelligenceSearchAndAdd(
    options: Models.OriginationWriteApiIntelligenceSearchAndAddOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  intelligenceSearchAndAdd(
    options?: Models.OriginationWriteApiIntelligenceSearchAndAddOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      intelligenceSearchAndAddOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  intelligenceRebuild(
    options?: Models.OriginationWriteApiIntelligenceRebuildOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  intelligenceRebuild(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  intelligenceRebuild(
    options: Models.OriginationWriteApiIntelligenceRebuildOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  intelligenceRebuild(
    options?: Models.OriginationWriteApiIntelligenceRebuildOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      intelligenceRebuildOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  intelligenceSearchFlag(
    options?: Models.OriginationWriteApiIntelligenceSearchFlagOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  intelligenceSearchFlag(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  intelligenceSearchFlag(
    options: Models.OriginationWriteApiIntelligenceSearchFlagOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  intelligenceSearchFlag(
    options?: Models.OriginationWriteApiIntelligenceSearchFlagOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      intelligenceSearchFlagOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  intelligenceSearchRemove(
    options?: Models.OriginationWriteApiIntelligenceSearchRemoveOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  intelligenceSearchRemove(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  intelligenceSearchRemove(
    options: Models.OriginationWriteApiIntelligenceSearchRemoveOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  intelligenceSearchRemove(
    options?: Models.OriginationWriteApiIntelligenceSearchRemoveOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      intelligenceSearchRemoveOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  intelligenceSearchRemoveMany(
    options?: Models.OriginationWriteApiIntelligenceSearchRemoveManyOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  intelligenceSearchRemoveMany(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  intelligenceSearchRemoveMany(
    options: Models.OriginationWriteApiIntelligenceSearchRemoveManyOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  intelligenceSearchRemoveMany(
    options?: Models.OriginationWriteApiIntelligenceSearchRemoveManyOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      intelligenceSearchRemoveManyOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addInvestor(options?: Models.OriginationWriteApiAddInvestorOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addInvestor(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addInvestor(
    options: Models.OriginationWriteApiAddInvestorOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addInvestor(
    options?: Models.OriginationWriteApiAddInvestorOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addInvestorOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  turnOnChangeOfFacility(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  turnOnChangeOfFacility(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  turnOnChangeOfFacility(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  turnOnChangeOfFacility(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      turnOnChangeOfFacilityOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addStaleOverride(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  addStaleOverride(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  addStaleOverride(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  addStaleOverride(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      addStaleOverrideOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateFees(options?: Models.OriginationWriteApiUpdateFeesOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateFees(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateFees(options: Models.OriginationWriteApiUpdateFeesOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateFees(
    options?: Models.OriginationWriteApiUpdateFeesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateFeesOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTransactionDateResponse>
   */
  updateTransactionDate(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateTransactionDateOptionalParams
  ): Promise<Models.UpdateTransactionDateResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateTransactionDate(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTransactionDate(
    loanId: string,
    options: Models.OriginationWriteApiUpdateTransactionDateOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateTransactionDate(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateTransactionDateOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateTransactionDateResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateTransactionDateOperationSpec,
      callback
    ) as Promise<Models.UpdateTransactionDateResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTransactionDateNumberResponse>
   */
  updateTransactionDateNumber(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateTransactionDateNumberOptionalParams
  ): Promise<Models.UpdateTransactionDateNumberResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateTransactionDateNumber(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTransactionDateNumber(
    loanId: string,
    options: Models.OriginationWriteApiUpdateTransactionDateNumberOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateTransactionDateNumber(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateTransactionDateNumberOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateTransactionDateNumberResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateTransactionDateNumberOperationSpec,
      callback
    ) as Promise<Models.UpdateTransactionDateNumberResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTransactionDebtorResponse>
   */
  updateTransactionDebtor(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateTransactionDebtorOptionalParams
  ): Promise<Models.UpdateTransactionDebtorResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateTransactionDebtor(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTransactionDebtor(
    loanId: string,
    options: Models.OriginationWriteApiUpdateTransactionDebtorOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateTransactionDebtor(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateTransactionDebtorOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateTransactionDebtorResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateTransactionDebtorOperationSpec,
      callback
    ) as Promise<Models.UpdateTransactionDebtorResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTransactionDetailsResponse>
   */
  updateTransactionDetails(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateTransactionDetailsOptionalParams
  ): Promise<Models.UpdateTransactionDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateTransactionDetails(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTransactionDetails(
    loanId: string,
    options: Models.OriginationWriteApiUpdateTransactionDetailsOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateTransactionDetails(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateTransactionDetailsOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateTransactionDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateTransactionDetailsOperationSpec,
      callback
    ) as Promise<Models.UpdateTransactionDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTransactionDetailsAmountResponse>
   */
  updateTransactionDetailsAmount(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateTransactionDetailsAmountOptionalParams
  ): Promise<Models.UpdateTransactionDetailsAmountResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateTransactionDetailsAmount(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTransactionDetailsAmount(
    loanId: string,
    options: Models.OriginationWriteApiUpdateTransactionDetailsAmountOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateTransactionDetailsAmount(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateTransactionDetailsAmountOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateTransactionDetailsAmountResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateTransactionDetailsAmountOperationSpec,
      callback
    ) as Promise<Models.UpdateTransactionDetailsAmountResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterTransactionsSummaryResponse>
   */
  registerTransactionsSummary(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterTransactionsSummaryOptionalParams
  ): Promise<Models.RegisterTransactionsSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerTransactionsSummary(
    loanId: string,
    callback: msRest.ServiceCallback<Models.GuidIEnumerableApiResponse>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerTransactionsSummary(
    loanId: string,
    options: Models.OriginationWriteApiRegisterTransactionsSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidIEnumerableApiResponse>
  ): void;
  registerTransactionsSummary(
    loanId: string,
    options?:
      | Models.OriginationWriteApiRegisterTransactionsSummaryOptionalParams
      | msRest.ServiceCallback<Models.GuidIEnumerableApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidIEnumerableApiResponse>
  ): Promise<Models.RegisterTransactionsSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerTransactionsSummaryOperationSpec,
      callback
    ) as Promise<Models.RegisterTransactionsSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.SetTransactionValueResponse>
   */
  setTransactionValue(
    loanId: string,
    options?: Models.OriginationWriteApiSetTransactionValueOptionalParams
  ): Promise<Models.SetTransactionValueResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  setTransactionValue(loanId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  setTransactionValue(
    loanId: string,
    options: Models.OriginationWriteApiSetTransactionValueOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  setTransactionValue(
    loanId: string,
    options?: Models.OriginationWriteApiSetTransactionValueOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.SetTransactionValueResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      setTransactionValueOperationSpec,
      callback
    ) as Promise<Models.SetTransactionValueResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UnregisterTransactionSummaryResponse>
   */
  unregisterTransactionSummary(
    loanId: string,
    options?: Models.OriginationWriteApiUnregisterTransactionSummaryOptionalParams
  ): Promise<Models.UnregisterTransactionSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unregisterTransactionSummary(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterTransactionSummary(
    loanId: string,
    options: Models.OriginationWriteApiUnregisterTransactionSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  unregisterTransactionSummary(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUnregisterTransactionSummaryOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UnregisterTransactionSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unregisterTransactionSummaryOperationSpec,
      callback
    ) as Promise<Models.UnregisterTransactionSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterSyntheticPaymentSummaryResponse>
   */
  registerSyntheticPaymentSummary(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterSyntheticPaymentSummaryOptionalParams
  ): Promise<Models.RegisterSyntheticPaymentSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerSyntheticPaymentSummary(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerSyntheticPaymentSummary(
    loanId: string,
    options: Models.OriginationWriteApiRegisterSyntheticPaymentSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  registerSyntheticPaymentSummary(
    loanId: string,
    options?:
      | Models.OriginationWriteApiRegisterSyntheticPaymentSummaryOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.RegisterSyntheticPaymentSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerSyntheticPaymentSummaryOperationSpec,
      callback
    ) as Promise<Models.RegisterSyntheticPaymentSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterSyntheticPaymentFullResponse>
   */
  registerSyntheticPaymentFull(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterSyntheticPaymentFullOptionalParams
  ): Promise<Models.RegisterSyntheticPaymentFullResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerSyntheticPaymentFull(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerSyntheticPaymentFull(
    loanId: string,
    options: Models.OriginationWriteApiRegisterSyntheticPaymentFullOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  registerSyntheticPaymentFull(
    loanId: string,
    options?:
      | Models.OriginationWriteApiRegisterSyntheticPaymentFullOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.RegisterSyntheticPaymentFullResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerSyntheticPaymentFullOperationSpec,
      callback
    ) as Promise<Models.RegisterSyntheticPaymentFullResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.ReconcileTransactionResponse>
   */
  reconcileTransaction(
    loanId: string,
    options?: Models.OriginationWriteApiReconcileTransactionOptionalParams
  ): Promise<Models.ReconcileTransactionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconcileTransaction(loanId: string, callback: msRest.ServiceCallback<Models.StringApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconcileTransaction(
    loanId: string,
    options: Models.OriginationWriteApiReconcileTransactionOptionalParams,
    callback: msRest.ServiceCallback<Models.StringApiResponse>
  ): void;
  reconcileTransaction(
    loanId: string,
    options?:
      | Models.OriginationWriteApiReconcileTransactionOptionalParams
      | msRest.ServiceCallback<Models.StringApiResponse>,
    callback?: msRest.ServiceCallback<Models.StringApiResponse>
  ): Promise<Models.ReconcileTransactionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconcileTransactionOperationSpec,
      callback
    ) as Promise<Models.ReconcileTransactionResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UnregisterSyntheticPaymentResponse>
   */
  unregisterSyntheticPayment(
    loanId: string,
    options?: Models.OriginationWriteApiUnregisterSyntheticPaymentOptionalParams
  ): Promise<Models.UnregisterSyntheticPaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unregisterSyntheticPayment(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterSyntheticPayment(
    loanId: string,
    options: Models.OriginationWriteApiUnregisterSyntheticPaymentOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  unregisterSyntheticPayment(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUnregisterSyntheticPaymentOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UnregisterSyntheticPaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unregisterSyntheticPaymentOperationSpec,
      callback
    ) as Promise<Models.UnregisterSyntheticPaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteSyntheticPaymentResponse>
   */
  deleteSyntheticPayment(
    loanId: string,
    options?: Models.OriginationWriteApiDeleteSyntheticPaymentOptionalParams
  ): Promise<Models.DeleteSyntheticPaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  deleteSyntheticPayment(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteSyntheticPayment(
    loanId: string,
    options: Models.OriginationWriteApiDeleteSyntheticPaymentOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  deleteSyntheticPayment(
    loanId: string,
    options?:
      | Models.OriginationWriteApiDeleteSyntheticPaymentOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.DeleteSyntheticPaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      deleteSyntheticPaymentOperationSpec,
      callback
    ) as Promise<Models.DeleteSyntheticPaymentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  cleanAllAdvanceAdjustments(
    options?: Models.OriginationWriteApiCleanAllAdvanceAdjustmentsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  cleanAllAdvanceAdjustments(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  cleanAllAdvanceAdjustments(
    options: Models.OriginationWriteApiCleanAllAdvanceAdjustmentsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  cleanAllAdvanceAdjustments(
    options?: Models.OriginationWriteApiCleanAllAdvanceAdjustmentsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      cleanAllAdvanceAdjustmentsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAdvanceAdjustments(
    options?: Models.OriginationWriteApiBuildAdvanceAdjustmentsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAdvanceAdjustments(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAdvanceAdjustments(
    options: Models.OriginationWriteApiBuildAdvanceAdjustmentsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildAdvanceAdjustments(
    options?: Models.OriginationWriteApiBuildAdvanceAdjustmentsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAdvanceAdjustmentsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllAdvanceAdjustments(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllAdvanceAdjustments(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllAdvanceAdjustments(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllAdvanceAdjustments(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllAdvanceAdjustmentsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  processBankTransactions(
    options?: Models.OriginationWriteApiProcessBankTransactionsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  processBankTransactions(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  processBankTransactions(
    options: Models.OriginationWriteApiProcessBankTransactionsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  processBankTransactions(
    options?: Models.OriginationWriteApiProcessBankTransactionsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      processBankTransactionsOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterAdvancesSummaryResponse>
   */
  registerAdvancesSummary(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterAdvancesSummaryOptionalParams
  ): Promise<Models.RegisterAdvancesSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerAdvancesSummary(
    loanId: string,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionIEnumerableApiResponse>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerAdvancesSummary(
    loanId: string,
    options: Models.OriginationWriteApiRegisterAdvancesSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionIEnumerableApiResponse>
  ): void;
  registerAdvancesSummary(
    loanId: string,
    options?:
      | Models.OriginationWriteApiRegisterAdvancesSummaryOptionalParams
      | msRest.ServiceCallback<Models.AdvanceProjectionIEnumerableApiResponse>,
    callback?: msRest.ServiceCallback<Models.AdvanceProjectionIEnumerableApiResponse>
  ): Promise<Models.RegisterAdvancesSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerAdvancesSummaryOperationSpec,
      callback
    ) as Promise<Models.RegisterAdvancesSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterAdvanceResponse>
   */
  registerAdvance(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterAdvanceOptionalParams
  ): Promise<Models.RegisterAdvanceResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerAdvance(loanId: string, callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerAdvance(
    loanId: string,
    options: Models.OriginationWriteApiRegisterAdvanceOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): void;
  registerAdvance(
    loanId: string,
    options?:
      | Models.OriginationWriteApiRegisterAdvanceOptionalParams
      | msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>,
    callback?: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): Promise<Models.RegisterAdvanceResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerAdvanceOperationSpec,
      callback
    ) as Promise<Models.RegisterAdvanceResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterAdjustmentAdvanceResponse>
   */
  registerAdjustmentAdvance(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterAdjustmentAdvanceOptionalParams
  ): Promise<Models.RegisterAdjustmentAdvanceResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerAdjustmentAdvance(
    loanId: string,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerAdjustmentAdvance(
    loanId: string,
    options: Models.OriginationWriteApiRegisterAdjustmentAdvanceOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): void;
  registerAdjustmentAdvance(
    loanId: string,
    options?:
      | Models.OriginationWriteApiRegisterAdjustmentAdvanceOptionalParams
      | msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>,
    callback?: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): Promise<Models.RegisterAdjustmentAdvanceResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerAdjustmentAdvanceOperationSpec,
      callback
    ) as Promise<Models.RegisterAdjustmentAdvanceResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UnregisterAdvancesSummaryResponse>
   */
  unregisterAdvancesSummary(
    loanId: string,
    options?: Models.OriginationWriteApiUnregisterAdvancesSummaryOptionalParams
  ): Promise<Models.UnregisterAdvancesSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unregisterAdvancesSummary(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterAdvancesSummary(
    loanId: string,
    options: Models.OriginationWriteApiUnregisterAdvancesSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  unregisterAdvancesSummary(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUnregisterAdvancesSummaryOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UnregisterAdvancesSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unregisterAdvancesSummaryOperationSpec,
      callback
    ) as Promise<Models.UnregisterAdvancesSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawStartDateResponse>
   */
  updateWithdrawStartDate(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateWithdrawStartDateOptionalParams
  ): Promise<Models.UpdateWithdrawStartDateResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawStartDate(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawStartDate(
    loanId: string,
    options: Models.OriginationWriteApiUpdateWithdrawStartDateOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateWithdrawStartDate(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateWithdrawStartDateOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateWithdrawStartDateResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawStartDateOperationSpec,
      callback
    ) as Promise<Models.UpdateWithdrawStartDateResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateAdvanceDateResponse>
   */
  updateAdvanceDate(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateAdvanceDateOptionalParams
  ): Promise<Models.UpdateAdvanceDateResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateAdvanceDate(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAdvanceDate(
    loanId: string,
    options: Models.OriginationWriteApiUpdateAdvanceDateOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateAdvanceDate(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateAdvanceDateOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateAdvanceDateResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateAdvanceDateOperationSpec,
      callback
    ) as Promise<Models.UpdateAdvanceDateResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateAdvanceRateResponse>
   */
  updateAdvanceRate(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateAdvanceRateOptionalParams
  ): Promise<Models.UpdateAdvanceRateResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateAdvanceRate(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAdvanceRate(
    loanId: string,
    options: Models.OriginationWriteApiUpdateAdvanceRateOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateAdvanceRate(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateAdvanceRateOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateAdvanceRateResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateAdvanceRateOperationSpec,
      callback
    ) as Promise<Models.UpdateAdvanceRateResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateAdvanceDateNumberResponse>
   */
  updateAdvanceDateNumber(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateAdvanceDateNumberOptionalParams
  ): Promise<Models.UpdateAdvanceDateNumberResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateAdvanceDateNumber(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAdvanceDateNumber(
    loanId: string,
    options: Models.OriginationWriteApiUpdateAdvanceDateNumberOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateAdvanceDateNumber(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateAdvanceDateNumberOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateAdvanceDateNumberResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateAdvanceDateNumberOperationSpec,
      callback
    ) as Promise<Models.UpdateAdvanceDateNumberResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawWeeklyRepaymentResponse>
   */
  updateWithdrawWeeklyRepayment(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateWithdrawWeeklyRepaymentOptionalParams
  ): Promise<Models.UpdateWithdrawWeeklyRepaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawWeeklyRepayment(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawWeeklyRepayment(
    loanId: string,
    options: Models.OriginationWriteApiUpdateWithdrawWeeklyRepaymentOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateWithdrawWeeklyRepayment(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateWithdrawWeeklyRepaymentOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateWithdrawWeeklyRepaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawWeeklyRepaymentOperationSpec,
      callback
    ) as Promise<Models.UpdateWithdrawWeeklyRepaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawTrailingCommissionResponse>
   */
  updateWithdrawTrailingCommission(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateWithdrawTrailingCommissionOptionalParams
  ): Promise<Models.UpdateWithdrawTrailingCommissionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawTrailingCommission(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawTrailingCommission(
    loanId: string,
    options: Models.OriginationWriteApiUpdateWithdrawTrailingCommissionOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateWithdrawTrailingCommission(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateWithdrawTrailingCommissionOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateWithdrawTrailingCommissionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawTrailingCommissionOperationSpec,
      callback
    ) as Promise<Models.UpdateWithdrawTrailingCommissionResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawLegacyResponse>
   */
  updateWithdrawLegacy(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateWithdrawLegacyOptionalParams
  ): Promise<Models.UpdateWithdrawLegacyResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawLegacy(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawLegacy(
    loanId: string,
    options: Models.OriginationWriteApiUpdateWithdrawLegacyOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateWithdrawLegacy(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateWithdrawLegacyOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateWithdrawLegacyResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawLegacyOperationSpec,
      callback
    ) as Promise<Models.UpdateWithdrawLegacyResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawUpfrontFeeCommissionResponse>
   */
  updateWithdrawUpfrontFeeCommission(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateWithdrawUpfrontFeeCommissionOptionalParams
  ): Promise<Models.UpdateWithdrawUpfrontFeeCommissionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawUpfrontFeeCommission(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawUpfrontFeeCommission(
    loanId: string,
    options: Models.OriginationWriteApiUpdateWithdrawUpfrontFeeCommissionOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateWithdrawUpfrontFeeCommission(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateWithdrawUpfrontFeeCommissionOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateWithdrawUpfrontFeeCommissionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawUpfrontFeeCommissionOperationSpec,
      callback
    ) as Promise<Models.UpdateWithdrawUpfrontFeeCommissionResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawAgentFeeCommissionResponse>
   */
  updateWithdrawAgentFeeCommission(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateWithdrawAgentFeeCommissionOptionalParams
  ): Promise<Models.UpdateWithdrawAgentFeeCommissionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawAgentFeeCommission(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawAgentFeeCommission(
    loanId: string,
    options: Models.OriginationWriteApiUpdateWithdrawAgentFeeCommissionOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateWithdrawAgentFeeCommission(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateWithdrawAgentFeeCommissionOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateWithdrawAgentFeeCommissionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawAgentFeeCommissionOperationSpec,
      callback
    ) as Promise<Models.UpdateWithdrawAgentFeeCommissionResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawDrawFeeCommissionResponse>
   */
  updateWithdrawDrawFeeCommission(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateWithdrawDrawFeeCommissionOptionalParams
  ): Promise<Models.UpdateWithdrawDrawFeeCommissionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawDrawFeeCommission(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawDrawFeeCommission(
    loanId: string,
    options: Models.OriginationWriteApiUpdateWithdrawDrawFeeCommissionOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateWithdrawDrawFeeCommission(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateWithdrawDrawFeeCommissionOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateWithdrawDrawFeeCommissionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawDrawFeeCommissionOperationSpec,
      callback
    ) as Promise<Models.UpdateWithdrawDrawFeeCommissionResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateAdvanceLastRepaymentDateResponse>
   */
  updateAdvanceLastRepaymentDate(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateAdvanceLastRepaymentDateOptionalParams
  ): Promise<Models.UpdateAdvanceLastRepaymentDateResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateAdvanceLastRepaymentDate(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAdvanceLastRepaymentDate(
    loanId: string,
    options: Models.OriginationWriteApiUpdateAdvanceLastRepaymentDateOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateAdvanceLastRepaymentDate(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateAdvanceLastRepaymentDateOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateAdvanceLastRepaymentDateResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateAdvanceLastRepaymentDateOperationSpec,
      callback
    ) as Promise<Models.UpdateAdvanceLastRepaymentDateResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawAsPercentageResponse>
   */
  withdrawAsPercentage(
    loanId: string,
    options?: Models.OriginationWriteApiWithdrawAsPercentageOptionalParams
  ): Promise<Models.WithdrawAsPercentageResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawAsPercentage(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawAsPercentage(
    loanId: string,
    options: Models.OriginationWriteApiWithdrawAsPercentageOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  withdrawAsPercentage(
    loanId: string,
    options?:
      | Models.OriginationWriteApiWithdrawAsPercentageOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.WithdrawAsPercentageResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawAsPercentageOperationSpec,
      callback
    ) as Promise<Models.WithdrawAsPercentageResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawAsPercentageOverrideResponse>
   */
  withdrawAsPercentageOverride(
    loanId: string,
    options?: Models.OriginationWriteApiWithdrawAsPercentageOverrideOptionalParams
  ): Promise<Models.WithdrawAsPercentageOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawAsPercentageOverride(
    loanId: string,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawAsPercentageOverride(
    loanId: string,
    options: Models.OriginationWriteApiWithdrawAsPercentageOverrideOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): void;
  withdrawAsPercentageOverride(
    loanId: string,
    options?:
      | Models.OriginationWriteApiWithdrawAsPercentageOverrideOptionalParams
      | msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>,
    callback?: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): Promise<Models.WithdrawAsPercentageOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawAsPercentageOverrideOperationSpec,
      callback
    ) as Promise<Models.WithdrawAsPercentageOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawAsPercentageOverrideResponse>
   */
  updateWithdrawAsPercentageOverride(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateWithdrawAsPercentageOverrideOptionalParams
  ): Promise<Models.UpdateWithdrawAsPercentageOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawAsPercentageOverride(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawAsPercentageOverride(
    loanId: string,
    options: Models.OriginationWriteApiUpdateWithdrawAsPercentageOverrideOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateWithdrawAsPercentageOverride(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateWithdrawAsPercentageOverrideOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateWithdrawAsPercentageOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawAsPercentageOverrideOperationSpec,
      callback
    ) as Promise<Models.UpdateWithdrawAsPercentageOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawAsInvoicePaymentResponse>
   */
  withdrawAsInvoicePayment(
    loanId: string,
    options?: Models.OriginationWriteApiWithdrawAsInvoicePaymentOptionalParams
  ): Promise<Models.WithdrawAsInvoicePaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawAsInvoicePayment(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawAsInvoicePayment(
    loanId: string,
    options: Models.OriginationWriteApiWithdrawAsInvoicePaymentOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  withdrawAsInvoicePayment(
    loanId: string,
    options?:
      | Models.OriginationWriteApiWithdrawAsInvoicePaymentOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.WithdrawAsInvoicePaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawAsInvoicePaymentOperationSpec,
      callback
    ) as Promise<Models.WithdrawAsInvoicePaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawAsInvoicePaymentOverrideResponse>
   */
  withdrawAsInvoicePaymentOverride(
    loanId: string,
    options?: Models.OriginationWriteApiWithdrawAsInvoicePaymentOverrideOptionalParams
  ): Promise<Models.WithdrawAsInvoicePaymentOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawAsInvoicePaymentOverride(
    loanId: string,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawAsInvoicePaymentOverride(
    loanId: string,
    options: Models.OriginationWriteApiWithdrawAsInvoicePaymentOverrideOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): void;
  withdrawAsInvoicePaymentOverride(
    loanId: string,
    options?:
      | Models.OriginationWriteApiWithdrawAsInvoicePaymentOverrideOptionalParams
      | msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>,
    callback?: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): Promise<Models.WithdrawAsInvoicePaymentOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawAsInvoicePaymentOverrideOperationSpec,
      callback
    ) as Promise<Models.WithdrawAsInvoicePaymentOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawAsInvoicePaymentOverrideResponse>
   */
  updateWithdrawAsInvoicePaymentOverride(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateWithdrawAsInvoicePaymentOverrideOptionalParams
  ): Promise<Models.UpdateWithdrawAsInvoicePaymentOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawAsInvoicePaymentOverride(
    loanId: string,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawAsInvoicePaymentOverride(
    loanId: string,
    options: Models.OriginationWriteApiUpdateWithdrawAsInvoicePaymentOverrideOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateWithdrawAsInvoicePaymentOverride(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateWithdrawAsInvoicePaymentOverrideOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateWithdrawAsInvoicePaymentOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawAsInvoicePaymentOverrideOperationSpec,
      callback
    ) as Promise<Models.UpdateWithdrawAsInvoicePaymentOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawFixedResponse>
   */
  withdrawFixed(
    loanId: string,
    options?: Models.OriginationWriteApiWithdrawFixedOptionalParams
  ): Promise<Models.WithdrawFixedResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawFixed(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawFixed(
    loanId: string,
    options: Models.OriginationWriteApiWithdrawFixedOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  withdrawFixed(
    loanId: string,
    options?: Models.OriginationWriteApiWithdrawFixedOptionalParams | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.WithdrawFixedResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawFixedOperationSpec,
      callback
    ) as Promise<Models.WithdrawFixedResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawFixedOverrideResponse>
   */
  withdrawFixedOverride(
    loanId: string,
    options?: Models.OriginationWriteApiWithdrawFixedOverrideOptionalParams
  ): Promise<Models.WithdrawFixedOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawFixedOverride(loanId: string, callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawFixedOverride(
    loanId: string,
    options: Models.OriginationWriteApiWithdrawFixedOverrideOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): void;
  withdrawFixedOverride(
    loanId: string,
    options?:
      | Models.OriginationWriteApiWithdrawFixedOverrideOptionalParams
      | msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>,
    callback?: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): Promise<Models.WithdrawFixedOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawFixedOverrideOperationSpec,
      callback
    ) as Promise<Models.WithdrawFixedOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawFixedOverrideResponse>
   */
  updateWithdrawFixedOverride(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateWithdrawFixedOverrideOptionalParams
  ): Promise<Models.UpdateWithdrawFixedOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawFixedOverride(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawFixedOverride(
    loanId: string,
    options: Models.OriginationWriteApiUpdateWithdrawFixedOverrideOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateWithdrawFixedOverride(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateWithdrawFixedOverrideOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateWithdrawFixedOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawFixedOverrideOperationSpec,
      callback
    ) as Promise<Models.UpdateWithdrawFixedOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawLineOfCreditResponse>
   */
  withdrawLineOfCredit(
    loanId: string,
    options?: Models.OriginationWriteApiWithdrawLineOfCreditOptionalParams
  ): Promise<Models.WithdrawLineOfCreditResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawLineOfCredit(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawLineOfCredit(
    loanId: string,
    options: Models.OriginationWriteApiWithdrawLineOfCreditOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  withdrawLineOfCredit(
    loanId: string,
    options?:
      | Models.OriginationWriteApiWithdrawLineOfCreditOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.WithdrawLineOfCreditResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawLineOfCreditOperationSpec,
      callback
    ) as Promise<Models.WithdrawLineOfCreditResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawLineOfCreditOverrideResponse>
   */
  withdrawLineOfCreditOverride(
    loanId: string,
    options?: Models.OriginationWriteApiWithdrawLineOfCreditOverrideOptionalParams
  ): Promise<Models.WithdrawLineOfCreditOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawLineOfCreditOverride(
    loanId: string,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawLineOfCreditOverride(
    loanId: string,
    options: Models.OriginationWriteApiWithdrawLineOfCreditOverrideOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): void;
  withdrawLineOfCreditOverride(
    loanId: string,
    options?:
      | Models.OriginationWriteApiWithdrawLineOfCreditOverrideOptionalParams
      | msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>,
    callback?: msRest.ServiceCallback<Models.AdvanceProjectionApiResponse>
  ): Promise<Models.WithdrawLineOfCreditOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawLineOfCreditOverrideOperationSpec,
      callback
    ) as Promise<Models.WithdrawLineOfCreditOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawLineOfCreditOverrideResponse>
   */
  updateWithdrawLineOfCreditOverride(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateWithdrawLineOfCreditOverrideOptionalParams
  ): Promise<Models.UpdateWithdrawLineOfCreditOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawLineOfCreditOverride(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawLineOfCreditOverride(
    loanId: string,
    options: Models.OriginationWriteApiUpdateWithdrawLineOfCreditOverrideOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  updateWithdrawLineOfCreditOverride(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUpdateWithdrawLineOfCreditOverrideOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UpdateWithdrawLineOfCreditOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawLineOfCreditOverrideOperationSpec,
      callback
    ) as Promise<Models.UpdateWithdrawLineOfCreditOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterPaymentArrangementResponse>
   */
  registerPaymentArrangement(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterPaymentArrangementOptionalParams
  ): Promise<Models.RegisterPaymentArrangementResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerPaymentArrangement(loanId: string, callback: msRest.ServiceCallback<Models.StringApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerPaymentArrangement(
    loanId: string,
    options: Models.OriginationWriteApiRegisterPaymentArrangementOptionalParams,
    callback: msRest.ServiceCallback<Models.StringApiResponse>
  ): void;
  registerPaymentArrangement(
    loanId: string,
    options?:
      | Models.OriginationWriteApiRegisterPaymentArrangementOptionalParams
      | msRest.ServiceCallback<Models.StringApiResponse>,
    callback?: msRest.ServiceCallback<Models.StringApiResponse>
  ): Promise<Models.RegisterPaymentArrangementResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerPaymentArrangementOperationSpec,
      callback
    ) as Promise<Models.RegisterPaymentArrangementResponse>;
  }

  /**
   * @param loanId
   * @param paymentArrangementId
   * @param [options] The optional parameters
   * @returns Promise<Models.UnregisterPaymentArrangementResponse>
   */
  unregisterPaymentArrangement(
    loanId: string,
    paymentArrangementId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.UnregisterPaymentArrangementResponse>;
  /**
   * @param loanId
   * @param paymentArrangementId
   * @param callback The callback
   */
  unregisterPaymentArrangement(
    loanId: string,
    paymentArrangementId: string,
    callback: msRest.ServiceCallback<Models.StringApiResponse>
  ): void;
  /**
   * @param loanId
   * @param paymentArrangementId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterPaymentArrangement(
    loanId: string,
    paymentArrangementId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.StringApiResponse>
  ): void;
  unregisterPaymentArrangement(
    loanId: string,
    paymentArrangementId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.StringApiResponse>,
    callback?: msRest.ServiceCallback<Models.StringApiResponse>
  ): Promise<Models.UnregisterPaymentArrangementResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        paymentArrangementId,
        options
      },
      unregisterPaymentArrangementOperationSpec,
      callback
    ) as Promise<Models.UnregisterPaymentArrangementResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.EarlyRepayAdvanceResponse>
   */
  earlyRepayAdvance(
    loanId: string,
    options?: Models.OriginationWriteApiEarlyRepayAdvanceOptionalParams
  ): Promise<Models.EarlyRepayAdvanceResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  earlyRepayAdvance(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  earlyRepayAdvance(
    loanId: string,
    options: Models.OriginationWriteApiEarlyRepayAdvanceOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  earlyRepayAdvance(
    loanId: string,
    options?:
      | Models.OriginationWriteApiEarlyRepayAdvanceOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.EarlyRepayAdvanceResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      earlyRepayAdvanceOperationSpec,
      callback
    ) as Promise<Models.EarlyRepayAdvanceResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterRepaymentDetailsResponse>
   */
  registerRepaymentDetails(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterRepaymentDetailsOptionalParams
  ): Promise<Models.RegisterRepaymentDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerRepaymentDetails(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerRepaymentDetails(
    loanId: string,
    options: Models.OriginationWriteApiRegisterRepaymentDetailsOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  registerRepaymentDetails(
    loanId: string,
    options?:
      | Models.OriginationWriteApiRegisterRepaymentDetailsOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.RegisterRepaymentDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerRepaymentDetailsOperationSpec,
      callback
    ) as Promise<Models.RegisterRepaymentDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterEarlyRepaymentDetailsResponse>
   */
  registerEarlyRepaymentDetails(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterEarlyRepaymentDetailsOptionalParams
  ): Promise<Models.RegisterEarlyRepaymentDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerEarlyRepaymentDetails(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerEarlyRepaymentDetails(
    loanId: string,
    options: Models.OriginationWriteApiRegisterEarlyRepaymentDetailsOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  registerEarlyRepaymentDetails(
    loanId: string,
    options?:
      | Models.OriginationWriteApiRegisterEarlyRepaymentDetailsOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.RegisterEarlyRepaymentDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerEarlyRepaymentDetailsOperationSpec,
      callback
    ) as Promise<Models.RegisterEarlyRepaymentDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterSkipRepaymentDetailsResponse>
   */
  registerSkipRepaymentDetails(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterSkipRepaymentDetailsOptionalParams
  ): Promise<Models.RegisterSkipRepaymentDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerSkipRepaymentDetails(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerSkipRepaymentDetails(
    loanId: string,
    options: Models.OriginationWriteApiRegisterSkipRepaymentDetailsOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  registerSkipRepaymentDetails(
    loanId: string,
    options?:
      | Models.OriginationWriteApiRegisterSkipRepaymentDetailsOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.RegisterSkipRepaymentDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerSkipRepaymentDetailsOperationSpec,
      callback
    ) as Promise<Models.RegisterSkipRepaymentDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UnregisterRepaymentDetailsResponse>
   */
  unregisterRepaymentDetails(
    loanId: string,
    options?: Models.OriginationWriteApiUnregisterRepaymentDetailsOptionalParams
  ): Promise<Models.UnregisterRepaymentDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unregisterRepaymentDetails(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterRepaymentDetails(
    loanId: string,
    options: Models.OriginationWriteApiUnregisterRepaymentDetailsOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  unregisterRepaymentDetails(
    loanId: string,
    options?:
      | Models.OriginationWriteApiUnregisterRepaymentDetailsOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.UnregisterRepaymentDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unregisterRepaymentDetailsOperationSpec,
      callback
    ) as Promise<Models.UnregisterRepaymentDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addCommissionToAdvance(
    loanId: string,
    options?: Models.OriginationWriteApiAddCommissionToAdvanceOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  addCommissionToAdvance(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  addCommissionToAdvance(
    loanId: string,
    options: Models.OriginationWriteApiAddCommissionToAdvanceOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addCommissionToAdvance(
    loanId: string,
    options?: Models.OriginationWriteApiAddCommissionToAdvanceOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      addCommissionToAdvanceOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param advanceId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  payCommission(loanId: string, advanceId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param advanceId
   * @param callback The callback
   */
  payCommission(loanId: string, advanceId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param advanceId
   * @param options The optional parameters
   * @param callback The callback
   */
  payCommission(
    loanId: string,
    advanceId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  payCommission(
    loanId: string,
    advanceId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        advanceId,
        options
      },
      payCommissionOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  commissionMethod1(
    loanId: string,
    options?: Models.OriginationWriteApiCommissionMethod1OptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  commissionMethod1(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  commissionMethod1(
    loanId: string,
    options: Models.OriginationWriteApiCommissionMethod1OptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  commissionMethod1(
    loanId: string,
    options?: Models.OriginationWriteApiCommissionMethod1OptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      commissionMethod1OperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param debtorId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableDebtor(loanId: string, debtorId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param debtorId
   * @param callback The callback
   */
  enableDebtor(loanId: string, debtorId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param debtorId
   * @param options The optional parameters
   * @param callback The callback
   */
  enableDebtor(
    loanId: string,
    debtorId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  enableDebtor(
    loanId: string,
    debtorId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        debtorId,
        options
      },
      enableDebtorOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param enabled
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableDebtorService(
    loanId: string,
    enabled: boolean,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param enabled
   * @param callback The callback
   */
  enableDebtorService(loanId: string, enabled: boolean, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param enabled
   * @param options The optional parameters
   * @param callback The callback
   */
  enableDebtorService(
    loanId: string,
    enabled: boolean,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  enableDebtorService(
    loanId: string,
    enabled: boolean,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        enabled,
        options
      },
      enableDebtorServiceOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param enabled
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableShowAllDebtor(
    loanId: string,
    enabled: boolean,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param enabled
   * @param callback The callback
   */
  enableShowAllDebtor(loanId: string, enabled: boolean, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param enabled
   * @param options The optional parameters
   * @param callback The callback
   */
  enableShowAllDebtor(
    loanId: string,
    enabled: boolean,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  enableShowAllDebtor(
    loanId: string,
    enabled: boolean,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        enabled,
        options
      },
      enableShowAllDebtorOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param bankId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateBankId(loanId: string, bankId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param bankId
   * @param callback The callback
   */
  updateBankId(loanId: string, bankId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param bankId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBankId(
    loanId: string,
    bankId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateBankId(
    loanId: string,
    bankId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        bankId,
        options
      },
      updateBankIdOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param funder
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateFunder(loanId: string, funder: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param funder
   * @param callback The callback
   */
  updateFunder(loanId: string, funder: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param funder
   * @param options The optional parameters
   * @param callback The callback
   */
  updateFunder(
    loanId: string,
    funder: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateFunder(
    loanId: string,
    funder: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        funder,
        options
      },
      updateFunderOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enable(loanId: string, options?: Models.OriginationWriteApiEnableOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  enable(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  enable(
    loanId: string,
    options: Models.OriginationWriteApiEnableOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  enable(
    loanId: string,
    options?: Models.OriginationWriteApiEnableOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      enableOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanCustomerDetails(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateLoanCustomerDetailsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanCustomerDetails(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanCustomerDetails(
    loanId: string,
    options: Models.OriginationWriteApiUpdateLoanCustomerDetailsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateLoanCustomerDetails(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateLoanCustomerDetailsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanCustomerDetailsOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateBusinessNameDetailsResponse>
   */
  updateBusinessNameDetails(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateBusinessNameDetailsOptionalParams
  ): Promise<Models.UpdateBusinessNameDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateBusinessNameDetails(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBusinessNameDetails(
    loanId: string,
    options: Models.OriginationWriteApiUpdateBusinessNameDetailsOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updateBusinessNameDetails(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateBusinessNameDetailsOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdateBusinessNameDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateBusinessNameDetailsOperationSpec,
      callback
    ) as Promise<Models.UpdateBusinessNameDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateBalanceOverrideResponse>
   */
  updateBalanceOverride(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateBalanceOverrideOptionalParams
  ): Promise<Models.UpdateBalanceOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateBalanceOverride(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBalanceOverride(
    loanId: string,
    options: Models.OriginationWriteApiUpdateBalanceOverrideOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updateBalanceOverride(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateBalanceOverrideOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdateBalanceOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateBalanceOverrideOperationSpec,
      callback
    ) as Promise<Models.UpdateBalanceOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanStatus(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateLoanStatusOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanStatus(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanStatus(
    loanId: string,
    options: Models.OriginationWriteApiUpdateLoanStatusOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateLoanStatus(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateLoanStatusOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanStatusOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconnectAccountingData(
    options?: Models.OriginationWriteApiReconnectAccountingDataOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  reconnectAccountingData(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  reconnectAccountingData(
    options: Models.OriginationWriteApiReconnectAccountingDataOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  reconnectAccountingData(
    options?: Models.OriginationWriteApiReconnectAccountingDataOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      reconnectAccountingDataOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reprocessAccountingDataCommand(
    options?: Models.OriginationWriteApiReprocessAccountingDataCommandOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  reprocessAccountingDataCommand(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  reprocessAccountingDataCommand(
    options: Models.OriginationWriteApiReprocessAccountingDataCommandOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  reprocessAccountingDataCommand(
    options?: Models.OriginationWriteApiReprocessAccountingDataCommandOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      reprocessAccountingDataCommandOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disconnectAccountingSystem(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  disconnectAccountingSystem(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  disconnectAccountingSystem(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  disconnectAccountingSystem(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      disconnectAccountingSystemOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  processPayments(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  processPayments(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  processPayments(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  processPayments(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      processPaymentsOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  close(loanId: string, options?: Models.OriginationWriteApiCloseOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  close(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  close(
    loanId: string,
    options: Models.OriginationWriteApiCloseOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  close(
    loanId: string,
    options?: Models.OriginationWriteApiCloseOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      closeOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  switchLoanType(
    loanId: string,
    options?: Models.OriginationWriteApiSwitchLoanTypeOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  switchLoanType(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  switchLoanType(
    loanId: string,
    options: Models.OriginationWriteApiSwitchLoanTypeOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  switchLoanType(
    loanId: string,
    options?: Models.OriginationWriteApiSwitchLoanTypeOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      switchLoanTypeOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  open(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  open(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  open(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  open(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      openOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  archived(loanId: string, options?: Models.OriginationWriteApiArchivedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  archived(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  archived(
    loanId: string,
    options: Models.OriginationWriteApiArchivedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  archived(
    loanId: string,
    options?: Models.OriginationWriteApiArchivedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      archivedOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanPartnerDetails(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateLoanPartnerDetailsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanPartnerDetails(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanPartnerDetails(
    loanId: string,
    options: Models.OriginationWriteApiUpdateLoanPartnerDetailsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateLoanPartnerDetails(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateLoanPartnerDetailsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanPartnerDetailsOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @deprecated This operation is deprecated. Please do not use it any longer.
   * @returns Promise<msRest.RestResponse>
   */
  addChangeToFacility(
    loanId: string,
    options?: Models.OriginationWriteApiAddChangeToFacilityOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  addChangeToFacility(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  addChangeToFacility(
    loanId: string,
    options: Models.OriginationWriteApiAddChangeToFacilityOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addChangeToFacility(
    loanId: string,
    options?: Models.OriginationWriteApiAddChangeToFacilityOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      addChangeToFacilityOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @deprecated This operation is deprecated. Please do not use it any longer.
   * @returns Promise<msRest.RestResponse>
   */
  removeChangeToFacility(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  removeChangeToFacility(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  removeChangeToFacility(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeChangeToFacility(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      removeChangeToFacilityOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateLoanOnApplicationIdResponse>
   */
  createLoanOnApplicationId(
    options?: Models.OriginationWriteApiCreateLoanOnApplicationIdOptionalParams
  ): Promise<Models.CreateLoanOnApplicationIdResponse>;
  /**
   * @param callback The callback
   */
  createLoanOnApplicationId(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createLoanOnApplicationId(
    options: Models.OriginationWriteApiCreateLoanOnApplicationIdOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  createLoanOnApplicationId(
    options?: Models.OriginationWriteApiCreateLoanOnApplicationIdOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.CreateLoanOnApplicationIdResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createLoanOnApplicationIdOperationSpec,
      callback
    ) as Promise<Models.CreateLoanOnApplicationIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  registerBankAccount(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterBankAccountOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerBankAccount(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerBankAccount(
    loanId: string,
    options: Models.OriginationWriteApiRegisterBankAccountOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  registerBankAccount(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterBankAccountOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerBankAccountOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateRevolvingFacility(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateRevolvingFacilityOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateRevolvingFacility(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateRevolvingFacility(
    loanId: string,
    options: Models.OriginationWriteApiUpdateRevolvingFacilityOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateRevolvingFacility(
    loanId: string,
    options?: Models.OriginationWriteApiUpdateRevolvingFacilityOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateRevolvingFacilityOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.SendOtp1Response>
   */
  sendOtp1(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.SendOtp1Response>;
  /**
   * @param loanId
   * @param callback The callback
   */
  sendOtp1(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendOtp1(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  sendOtp1(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SendOtp1Response> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      sendOtp1OperationSpec,
      callback
    ) as Promise<Models.SendOtp1Response>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterLoanNotificationResponse>
   */
  registerLoanNotification(
    loanId: string,
    options?: Models.OriginationWriteApiRegisterLoanNotificationOptionalParams
  ): Promise<Models.RegisterLoanNotificationResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerLoanNotification(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerLoanNotification(
    loanId: string,
    options: Models.OriginationWriteApiRegisterLoanNotificationOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  registerLoanNotification(
    loanId: string,
    options?:
      | Models.OriginationWriteApiRegisterLoanNotificationOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.RegisterLoanNotificationResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerLoanNotificationOperationSpec,
      callback
    ) as Promise<Models.RegisterLoanNotificationResponse>;
  }

  /**
   * @param loanId
   * @param notificationId
   * @param [options] The optional parameters
   * @returns Promise<Models.ReadLoanNotificationResponse>
   */
  readLoanNotification(
    loanId: string,
    notificationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.ReadLoanNotificationResponse>;
  /**
   * @param loanId
   * @param notificationId
   * @param callback The callback
   */
  readLoanNotification(
    loanId: string,
    notificationId: string,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  /**
   * @param loanId
   * @param notificationId
   * @param options The optional parameters
   * @param callback The callback
   */
  readLoanNotification(
    loanId: string,
    notificationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  readLoanNotification(
    loanId: string,
    notificationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.ReadLoanNotificationResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        notificationId,
        options
      },
      readLoanNotificationOperationSpec,
      callback
    ) as Promise<Models.ReadLoanNotificationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  dailyUpdateOnLoan(options?: Models.OriginationWriteApiDailyUpdateOnLoanOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  dailyUpdateOnLoan(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  dailyUpdateOnLoan(
    options: Models.OriginationWriteApiDailyUpdateOnLoanOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  dailyUpdateOnLoan(
    options?: Models.OriginationWriteApiDailyUpdateOnLoanOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      dailyUpdateOnLoanOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disconnectBankAccount(
    options?: Models.OriginationWriteApiDisconnectBankAccountOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  disconnectBankAccount(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  disconnectBankAccount(
    options: Models.OriginationWriteApiDisconnectBankAccountOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  disconnectBankAccount(
    options?: Models.OriginationWriteApiDisconnectBankAccountOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      disconnectBankAccountOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateFlaggedStatus(
    options?: Models.OriginationWriteApiUpdateFlaggedStatusOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateFlaggedStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateFlaggedStatus(
    options: Models.OriginationWriteApiUpdateFlaggedStatusOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateFlaggedStatus(
    options?: Models.OriginationWriteApiUpdateFlaggedStatusOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateFlaggedStatusOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateExclusionStatus(
    options?: Models.OriginationWriteApiUpdateExclusionStatusOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateExclusionStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateExclusionStatus(
    options: Models.OriginationWriteApiUpdateExclusionStatusOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateExclusionStatus(
    options?: Models.OriginationWriteApiUpdateExclusionStatusOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateExclusionStatusOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unregisterLoanFeeTransactions(
    options?: Models.OriginationWriteApiUnregisterLoanFeeTransactionsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  unregisterLoanFeeTransactions(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterLoanFeeTransactions(
    options: Models.OriginationWriteApiUnregisterLoanFeeTransactionsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  unregisterLoanFeeTransactions(
    options?: Models.OriginationWriteApiUnregisterLoanFeeTransactionsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      unregisterLoanFeeTransactionsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  dailyUpdate(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  dailyUpdate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  dailyUpdate(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  dailyUpdate(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      dailyUpdateOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadFlaggedLoans(
    options?: Models.OriginationWriteApiUploadFlaggedLoansOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  uploadFlaggedLoans(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadFlaggedLoans(
    options: Models.OriginationWriteApiUploadFlaggedLoansOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadFlaggedLoans(
    options?: Models.OriginationWriteApiUploadFlaggedLoansOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      uploadFlaggedLoansOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateAllLoanBalances(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateAllLoanBalances(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAllLoanBalances(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateAllLoanBalances(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateAllLoanBalancesOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeAllEstFeeRequests(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removeAllEstFeeRequests(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removeAllEstFeeRequests(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  removeAllEstFeeRequests(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removeAllEstFeeRequestsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  checkLoanApplicationStatus(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  checkLoanApplicationStatus(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  checkLoanApplicationStatus(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  checkLoanApplicationStatus(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      checkLoanApplicationStatusOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateNotAssignedTransaction(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateNotAssignedTransaction(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateNotAssignedTransaction(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateNotAssignedTransaction(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateNotAssignedTransactionOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeTransactions(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removeTransactions(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removeTransactions(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  removeTransactions(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removeTransactionsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeExtraEstFeeTransactions(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removeExtraEstFeeTransactions(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removeExtraEstFeeTransactions(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  removeExtraEstFeeTransactions(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removeExtraEstFeeTransactionsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.PreformHealthCheckOnAllResponse>
   */
  preformHealthCheckOnAll(
    options?: Models.OriginationWriteApiPreformHealthCheckOnAllOptionalParams
  ): Promise<Models.PreformHealthCheckOnAllResponse>;
  /**
   * @param callback The callback
   */
  preformHealthCheckOnAll(callback: msRest.ServiceCallback<Models.HealthRuleDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  preformHealthCheckOnAll(
    options: Models.OriginationWriteApiPreformHealthCheckOnAllOptionalParams,
    callback: msRest.ServiceCallback<Models.HealthRuleDetails[]>
  ): void;
  preformHealthCheckOnAll(
    options?:
      | Models.OriginationWriteApiPreformHealthCheckOnAllOptionalParams
      | msRest.ServiceCallback<Models.HealthRuleDetails[]>,
    callback?: msRest.ServiceCallback<Models.HealthRuleDetails[]>
  ): Promise<Models.PreformHealthCheckOnAllResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      preformHealthCheckOnAllOperationSpec,
      callback
    ) as Promise<Models.PreformHealthCheckOnAllResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.PreformHealthCheckResponse>
   */
  preformHealthCheck(
    loanId: string,
    options?: Models.OriginationWriteApiPreformHealthCheckOptionalParams
  ): Promise<Models.PreformHealthCheckResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  preformHealthCheck(loanId: string, callback: msRest.ServiceCallback<Models.HealthCheckRule[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  preformHealthCheck(
    loanId: string,
    options: Models.OriginationWriteApiPreformHealthCheckOptionalParams,
    callback: msRest.ServiceCallback<Models.HealthCheckRule[]>
  ): void;
  preformHealthCheck(
    loanId: string,
    options?:
      | Models.OriginationWriteApiPreformHealthCheckOptionalParams
      | msRest.ServiceCallback<Models.HealthCheckRule[]>,
    callback?: msRest.ServiceCallback<Models.HealthCheckRule[]>
  ): Promise<Models.PreformHealthCheckResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      preformHealthCheckOperationSpec,
      callback
    ) as Promise<Models.PreformHealthCheckResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.PreformHealthCheckDetailResponse>
   */
  preformHealthCheckDetail(
    loanId: string,
    options?: Models.OriginationWriteApiPreformHealthCheckDetailOptionalParams
  ): Promise<Models.PreformHealthCheckDetailResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  preformHealthCheckDetail(loanId: string, callback: msRest.ServiceCallback<Models.HealthRuleDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  preformHealthCheckDetail(
    loanId: string,
    options: Models.OriginationWriteApiPreformHealthCheckDetailOptionalParams,
    callback: msRest.ServiceCallback<Models.HealthRuleDetails>
  ): void;
  preformHealthCheckDetail(
    loanId: string,
    options?:
      | Models.OriginationWriteApiPreformHealthCheckDetailOptionalParams
      | msRest.ServiceCallback<Models.HealthRuleDetails>,
    callback?: msRest.ServiceCallback<Models.HealthRuleDetails>
  ): Promise<Models.PreformHealthCheckDetailResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      preformHealthCheckDetailOperationSpec,
      callback
    ) as Promise<Models.PreformHealthCheckDetailResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  dailyHealthCheckOnAll(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  dailyHealthCheckOnAll(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  dailyHealthCheckOnAll(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  dailyHealthCheckOnAll(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      dailyHealthCheckOnAllOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  cleanAllHealthCheckMessages(
    options?: Models.OriginationWriteApiCleanAllHealthCheckMessagesOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  cleanAllHealthCheckMessages(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  cleanAllHealthCheckMessages(
    options: Models.OriginationWriteApiCleanAllHealthCheckMessagesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  cleanAllHealthCheckMessages(
    options?: Models.OriginationWriteApiCleanAllHealthCheckMessagesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      cleanAllHealthCheckMessagesOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  cleanHealthCheckMessage(
    options?: Models.OriginationWriteApiCleanHealthCheckMessageOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  cleanHealthCheckMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  cleanHealthCheckMessage(
    options: Models.OriginationWriteApiCleanHealthCheckMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  cleanHealthCheckMessage(
    options?: Models.OriginationWriteApiCleanHealthCheckMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      cleanHealthCheckMessageOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  performHealthCheckOnAllCSV(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  performHealthCheckOnAllCSV(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  performHealthCheckOnAllCSV(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  performHealthCheckOnAllCSV(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      performHealthCheckOnAllCSVOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  generateAndSendHealthCheckCSV(
    options?: Models.OriginationWriteApiGenerateAndSendHealthCheckCSVOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  generateAndSendHealthCheckCSV(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  generateAndSendHealthCheckCSV(
    options: Models.OriginationWriteApiGenerateAndSendHealthCheckCSVOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  generateAndSendHealthCheckCSV(
    options?: Models.OriginationWriteApiGenerateAndSendHealthCheckCSVOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      generateAndSendHealthCheckCSVOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  autoMatchingProcess(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  autoMatchingProcess(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  autoMatchingProcess(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  autoMatchingProcess(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      autoMatchingProcessOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationAllPayments(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationAllPaymentsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationAllPayments(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationAllPayments(
    loanId: string,
    options: Models.OriginationWriteApiReconciliationAllPaymentsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  reconciliationAllPayments(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationAllPaymentsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationAllPaymentsOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationPayment(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationPaymentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationPayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationPayment(
    loanId: string,
    options: Models.OriginationWriteApiReconciliationPaymentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  reconciliationPayment(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationPaymentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationPaymentOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationRepayment(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationRepaymentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationRepayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationRepayment(
    loanId: string,
    options: Models.OriginationWriteApiReconciliationRepaymentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  reconciliationRepayment(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationRepaymentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationRepaymentOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationTransfers(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationTransfersOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationTransfers(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationTransfers(
    loanId: string,
    options: Models.OriginationWriteApiReconciliationTransfersOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  reconciliationTransfers(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationTransfersOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationTransfersOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationExternalCredit(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationExternalCreditOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationExternalCredit(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationExternalCredit(
    loanId: string,
    options: Models.OriginationWriteApiReconciliationExternalCreditOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  reconciliationExternalCredit(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationExternalCreditOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationExternalCreditOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationInvoicePayment(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationInvoicePaymentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationInvoicePayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationInvoicePayment(
    loanId: string,
    options: Models.OriginationWriteApiReconciliationInvoicePaymentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  reconciliationInvoicePayment(
    loanId: string,
    options?: Models.OriginationWriteApiReconciliationInvoicePaymentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationInvoicePaymentOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationAllPayment(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationAllPaymentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationAllPayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationAllPayment(
    loanId: string,
    options: Models.OriginationWriteApiUnreconciliationAllPaymentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  unreconciliationAllPayment(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationAllPaymentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationAllPaymentOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationPayment(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationPaymentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationPayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationPayment(
    loanId: string,
    options: Models.OriginationWriteApiUnreconciliationPaymentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  unreconciliationPayment(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationPaymentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationPaymentOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationRepayment(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationRepaymentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationRepayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationRepayment(
    loanId: string,
    options: Models.OriginationWriteApiUnreconciliationRepaymentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  unreconciliationRepayment(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationRepaymentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationRepaymentOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationInvoicePayment(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationInvoicePaymentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationInvoicePayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationInvoicePayment(
    loanId: string,
    options: Models.OriginationWriteApiUnreconciliationInvoicePaymentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  unreconciliationInvoicePayment(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationInvoicePaymentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationInvoicePaymentOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationTransfers(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationTransfersOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationTransfers(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationTransfers(
    loanId: string,
    options: Models.OriginationWriteApiUnreconciliationTransfersOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  unreconciliationTransfers(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationTransfersOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationTransfersOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationExternalCredit(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationExternalCreditOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationExternalCredit(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationExternalCredit(
    loanId: string,
    options: Models.OriginationWriteApiUnreconciliationExternalCreditOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  unreconciliationExternalCredit(
    loanId: string,
    options?: Models.OriginationWriteApiUnreconciliationExternalCreditOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationExternalCreditOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateReconcilationRequestResponse>
   */
  createReconcilationRequest(
    loanId: string,
    options?: Models.OriginationWriteApiCreateReconcilationRequestOptionalParams
  ): Promise<Models.CreateReconcilationRequestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  createReconcilationRequest(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  createReconcilationRequest(
    loanId: string,
    options: Models.OriginationWriteApiCreateReconcilationRequestOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  createReconcilationRequest(
    loanId: string,
    options?:
      | Models.OriginationWriteApiCreateReconcilationRequestOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.CreateReconcilationRequestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      createReconcilationRequestOperationSpec,
      callback
    ) as Promise<Models.CreateReconcilationRequestResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateReconcilationMisdirectionResponse>
   */
  createReconcilationMisdirection(
    loanId: string,
    options?: Models.OriginationWriteApiCreateReconcilationMisdirectionOptionalParams
  ): Promise<Models.CreateReconcilationMisdirectionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  createReconcilationMisdirection(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  createReconcilationMisdirection(
    loanId: string,
    options: Models.OriginationWriteApiCreateReconcilationMisdirectionOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  createReconcilationMisdirection(
    loanId: string,
    options?:
      | Models.OriginationWriteApiCreateReconcilationMisdirectionOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.CreateReconcilationMisdirectionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      createReconcilationMisdirectionOperationSpec,
      callback
    ) as Promise<Models.CreateReconcilationMisdirectionResponse>;
  }

  /**
   * @param loanId
   * @param paymentQueryId
   * @param [options] The optional parameters
   * @returns Promise<Models.PaymentQueryStatusChangeResponse>
   */
  paymentQueryStatusChange(
    loanId: string,
    paymentQueryId: string,
    options?: Models.OriginationWriteApiPaymentQueryStatusChangeOptionalParams
  ): Promise<Models.PaymentQueryStatusChangeResponse>;
  /**
   * @param loanId
   * @param paymentQueryId
   * @param callback The callback
   */
  paymentQueryStatusChange(
    loanId: string,
    paymentQueryId: string,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  /**
   * @param loanId
   * @param paymentQueryId
   * @param options The optional parameters
   * @param callback The callback
   */
  paymentQueryStatusChange(
    loanId: string,
    paymentQueryId: string,
    options: Models.OriginationWriteApiPaymentQueryStatusChangeOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  paymentQueryStatusChange(
    loanId: string,
    paymentQueryId: string,
    options?:
      | Models.OriginationWriteApiPaymentQueryStatusChangeOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.PaymentQueryStatusChangeResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        paymentQueryId,
        options
      },
      paymentQueryStatusChangeOperationSpec,
      callback
    ) as Promise<Models.PaymentQueryStatusChangeResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.AssignMisdirectionResponse>
   */
  assignMisdirection(
    loanId: string,
    options?: Models.OriginationWriteApiAssignMisdirectionOptionalParams
  ): Promise<Models.AssignMisdirectionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  assignMisdirection(loanId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  assignMisdirection(
    loanId: string,
    options: Models.OriginationWriteApiAssignMisdirectionOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  assignMisdirection(
    loanId: string,
    options?:
      | Models.OriginationWriteApiAssignMisdirectionOptionalParams
      | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.AssignMisdirectionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      assignMisdirectionOperationSpec,
      callback
    ) as Promise<Models.AssignMisdirectionResponse>;
  }

  /**
   * @param loanId
   * @param paymentQueryId
   * @param [options] The optional parameters
   * @returns Promise<Models.AssignRequestResponse>
   */
  assignRequest(
    loanId: string,
    paymentQueryId: string,
    options?: Models.OriginationWriteApiAssignRequestOptionalParams
  ): Promise<Models.AssignRequestResponse>;
  /**
   * @param loanId
   * @param paymentQueryId
   * @param callback The callback
   */
  assignRequest(loanId: string, paymentQueryId: string, callback: msRest.ServiceCallback<Models.GuidApiResponse>): void;
  /**
   * @param loanId
   * @param paymentQueryId
   * @param options The optional parameters
   * @param callback The callback
   */
  assignRequest(
    loanId: string,
    paymentQueryId: string,
    options: Models.OriginationWriteApiAssignRequestOptionalParams,
    callback: msRest.ServiceCallback<Models.GuidApiResponse>
  ): void;
  assignRequest(
    loanId: string,
    paymentQueryId: string,
    options?: Models.OriginationWriteApiAssignRequestOptionalParams | msRest.ServiceCallback<Models.GuidApiResponse>,
    callback?: msRest.ServiceCallback<Models.GuidApiResponse>
  ): Promise<Models.AssignRequestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        paymentQueryId,
        options
      },
      assignRequestOperationSpec,
      callback
    ) as Promise<Models.AssignRequestResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setLockDate(options?: Models.OriginationWriteApiSetLockDateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setLockDate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setLockDate(
    options: Models.OriginationWriteApiSetLockDateOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setLockDate(
    options?: Models.OriginationWriteApiSetLockDateOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setLockDateOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateAllCustomersOnDisk(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateAllCustomersOnDisk(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAllCustomersOnDisk(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateAllCustomersOnDisk(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateAllCustomersOnDiskOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addNote(
    applicationId: string,
    options?: Models.OriginationWriteApiAddNoteOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addNote(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addNote(
    applicationId: string,
    options: Models.OriginationWriteApiAddNoteOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addNote(
    applicationId: string,
    options?: Models.OriginationWriteApiAddNoteOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addNoteOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addNoteMessage(
    applicationId: string,
    options?: Models.OriginationWriteApiAddNoteMessageOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addNoteMessage(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addNoteMessage(
    applicationId: string,
    options: Models.OriginationWriteApiAddNoteMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addNoteMessage(
    applicationId: string,
    options?: Models.OriginationWriteApiAddNoteMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addNoteMessageOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param noteId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeNote(applicationId: string, noteId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param noteId
   * @param callback The callback
   */
  removeNote(applicationId: string, noteId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param noteId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeNote(
    applicationId: string,
    noteId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeNote(
    applicationId: string,
    noteId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        noteId,
        options
      },
      removeNoteOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  cleanAllNotes(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  cleanAllNotes(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  cleanAllNotes(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  cleanAllNotes(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      cleanAllNotesOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  cleanAllNotesOnDays(
    options?: Models.OriginationWriteApiCleanAllNotesOnDaysOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  cleanAllNotesOnDays(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  cleanAllNotesOnDays(
    options: Models.OriginationWriteApiCleanAllNotesOnDaysOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  cleanAllNotesOnDays(
    options?: Models.OriginationWriteApiCleanAllNotesOnDaysOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      cleanAllNotesOnDaysOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  editNotes(
    applicationId: string,
    options?: Models.OriginationWriteApiEditNotesOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  editNotes(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  editNotes(
    applicationId: string,
    options: Models.OriginationWriteApiEditNotesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  editNotes(
    applicationId: string,
    options?: Models.OriginationWriteApiEditNotesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      editNotesOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setPartnerBankDetails(
    partnerId: string,
    options?: Models.OriginationWriteApiSetPartnerBankDetailsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  setPartnerBankDetails(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  setPartnerBankDetails(
    partnerId: string,
    options: Models.OriginationWriteApiSetPartnerBankDetailsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setPartnerBankDetails(
    partnerId: string,
    options?: Models.OriginationWriteApiSetPartnerBankDetailsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      setPartnerBankDetailsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addPartnerAnnouncement(
    options?: Models.OriginationWriteApiAddPartnerAnnouncementOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addPartnerAnnouncement(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addPartnerAnnouncement(
    options: Models.OriginationWriteApiAddPartnerAnnouncementOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addPartnerAnnouncement(
    options?: Models.OriginationWriteApiAddPartnerAnnouncementOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addPartnerAnnouncementOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removePartnerAnnouncement(
    options?: Models.OriginationWriteApiRemovePartnerAnnouncementOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removePartnerAnnouncement(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removePartnerAnnouncement(
    options: Models.OriginationWriteApiRemovePartnerAnnouncementOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removePartnerAnnouncement(
    options?: Models.OriginationWriteApiRemovePartnerAnnouncementOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removePartnerAnnouncementOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setPartnerLogo(
    partnerId: string,
    options?: Models.OriginationWriteApiSetPartnerLogoOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  setPartnerLogo(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  setPartnerLogo(
    partnerId: string,
    options: Models.OriginationWriteApiSetPartnerLogoOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setPartnerLogo(
    partnerId: string,
    options?: Models.OriginationWriteApiSetPartnerLogoOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      setPartnerLogoOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addPartnerPaidCommission(
    partnerId: string,
    options?: Models.OriginationWriteApiAddPartnerPaidCommissionOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  addPartnerPaidCommission(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  addPartnerPaidCommission(
    partnerId: string,
    options: Models.OriginationWriteApiAddPartnerPaidCommissionOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addPartnerPaidCommission(
    partnerId: string,
    options?: Models.OriginationWriteApiAddPartnerPaidCommissionOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      addPartnerPaidCommissionOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatePartnerAPI(
    partnerId: string,
    options?: Models.OriginationWriteApiUpdatePartnerAPIOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  updatePartnerAPI(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerAPI(
    partnerId: string,
    options: Models.OriginationWriteApiUpdatePartnerAPIOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updatePartnerAPI(
    partnerId: string,
    options?: Models.OriginationWriteApiUpdatePartnerAPIOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      updatePartnerAPIOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatePartnerBusinessName(
    partnerId: string,
    options?: Models.OriginationWriteApiUpdatePartnerBusinessNameOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  updatePartnerBusinessName(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerBusinessName(
    partnerId: string,
    options: Models.OriginationWriteApiUpdatePartnerBusinessNameOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updatePartnerBusinessName(
    partnerId: string,
    options?: Models.OriginationWriteApiUpdatePartnerBusinessNameOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      updatePartnerBusinessNameOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  rebuildPartnerPaidCommission(partnerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  rebuildPartnerPaidCommission(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  rebuildPartnerPaidCommission(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  rebuildPartnerPaidCommission(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      rebuildPartnerPaidCommissionOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  rebuildAllPartnerPaidCommissions(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  rebuildAllPartnerPaidCommissions(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  rebuildAllPartnerPaidCommissions(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  rebuildAllPartnerPaidCommissions(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      rebuildAllPartnerPaidCommissionsOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setPaidStatusPartnerPaidCommission(
    partnerId: string,
    options?: Models.OriginationWriteApiSetPaidStatusPartnerPaidCommissionOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  setPaidStatusPartnerPaidCommission(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  setPaidStatusPartnerPaidCommission(
    partnerId: string,
    options: Models.OriginationWriteApiSetPaidStatusPartnerPaidCommissionOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setPaidStatusPartnerPaidCommission(
    partnerId: string,
    options?: Models.OriginationWriteApiSetPaidStatusPartnerPaidCommissionOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      setPaidStatusPartnerPaidCommissionOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removePartnerPaidCommission(
    partnerId: string,
    options?: Models.OriginationWriteApiRemovePartnerPaidCommissionOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  removePartnerPaidCommission(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  removePartnerPaidCommission(
    partnerId: string,
    options: Models.OriginationWriteApiRemovePartnerPaidCommissionOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removePartnerPaidCommission(
    partnerId: string,
    options?: Models.OriginationWriteApiRemovePartnerPaidCommissionOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      removePartnerPaidCommissionOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AddPartnersResponse>
   */
  addPartners(options?: Models.OriginationWriteApiAddPartnersOptionalParams): Promise<Models.AddPartnersResponse>;
  /**
   * @param callback The callback
   */
  addPartners(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addPartners(
    options: Models.OriginationWriteApiAddPartnersOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  addPartners(
    options?: Models.OriginationWriteApiAddPartnersOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.AddPartnersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addPartnersOperationSpec,
      callback
    ) as Promise<Models.AddPartnersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdatePartnersResponse>
   */
  updatePartners(
    options?: Models.OriginationWriteApiUpdatePartnersOptionalParams
  ): Promise<Models.UpdatePartnersResponse>;
  /**
   * @param callback The callback
   */
  updatePartners(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartners(
    options: Models.OriginationWriteApiUpdatePartnersOptionalParams,
    callback: msRest.ServiceCallback<string[]>
  ): void;
  updatePartners(
    options?: Models.OriginationWriteApiUpdatePartnersOptionalParams | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.UpdatePartnersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updatePartnersOperationSpec,
      callback
    ) as Promise<Models.UpdatePartnersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdatePartner1Response>
   */
  updatePartner1(
    options?: Models.OriginationWriteApiUpdatePartner1OptionalParams
  ): Promise<Models.UpdatePartner1Response>;
  /**
   * @param callback The callback
   */
  updatePartner1(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartner1(
    options: Models.OriginationWriteApiUpdatePartner1OptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updatePartner1(
    options?: Models.OriginationWriteApiUpdatePartner1OptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdatePartner1Response> {
    return this.sendOperationRequest(
      {
        options
      },
      updatePartner1OperationSpec,
      callback
    ) as Promise<Models.UpdatePartner1Response>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdatePartnerCommissionResponse>
   */
  updatePartnerCommission(
    partnerId: string,
    options?: Models.OriginationWriteApiUpdatePartnerCommissionOptionalParams
  ): Promise<Models.UpdatePartnerCommissionResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  updatePartnerCommission(partnerId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerCommission(
    partnerId: string,
    options: Models.OriginationWriteApiUpdatePartnerCommissionOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  updatePartnerCommission(
    partnerId: string,
    options?: Models.OriginationWriteApiUpdatePartnerCommissionOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.UpdatePartnerCommissionResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      updatePartnerCommissionOperationSpec,
      callback
    ) as Promise<Models.UpdatePartnerCommissionResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ResetPasswordResponse>
   */
  resetPassword(options?: Models.OriginationWriteApiResetPasswordOptionalParams): Promise<Models.ResetPasswordResponse>;
  /**
   * @param callback The callback
   */
  resetPassword(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  resetPassword(
    options: Models.OriginationWriteApiResetPasswordOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  resetPassword(
    options?: Models.OriginationWriteApiResetPasswordOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.ResetPasswordResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      resetPasswordOperationSpec,
      callback
    ) as Promise<Models.ResetPasswordResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.SendLink1Response>
   */
  sendLink1(
    partnerId: string,
    options?: Models.OriginationWriteApiSendLink1OptionalParams
  ): Promise<Models.SendLink1Response>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  sendLink1(partnerId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendLink1(
    partnerId: string,
    options: Models.OriginationWriteApiSendLink1OptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  sendLink1(
    partnerId: string,
    options?: Models.OriginationWriteApiSendLink1OptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.SendLink1Response> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      sendLink1OperationSpec,
      callback
    ) as Promise<Models.SendLink1Response>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resendSendLink1(
    partnerId: string,
    options?: Models.OriginationWriteApiResendSendLink1OptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  resendSendLink1(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  resendSendLink1(
    partnerId: string,
    options: Models.OriginationWriteApiResendSendLink1OptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  resendSendLink1(
    partnerId: string,
    options?: Models.OriginationWriteApiResendSendLink1OptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      resendSendLink1OperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendLinkForExisting(
    partnerId: string,
    options?: Models.OriginationWriteApiSendLinkForExistingOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  sendLinkForExisting(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendLinkForExisting(
    partnerId: string,
    options: Models.OriginationWriteApiSendLinkForExistingOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  sendLinkForExisting(
    partnerId: string,
    options?: Models.OriginationWriteApiSendLinkForExistingOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      sendLinkForExistingOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disablePartner(options?: Models.OriginationWriteApiDisablePartnerOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  disablePartner(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  disablePartner(
    options: Models.OriginationWriteApiDisablePartnerOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  disablePartner(
    options?: Models.OriginationWriteApiDisablePartnerOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      disablePartnerOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removePartner(options?: Models.OriginationWriteApiRemovePartnerOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removePartner(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removePartner(
    options: Models.OriginationWriteApiRemovePartnerOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removePartner(
    options?: Models.OriginationWriteApiRemovePartnerOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removePartnerOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setAgentSupport(options?: Models.OriginationWriteApiSetAgentSupportOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setAgentSupport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setAgentSupport(
    options: Models.OriginationWriteApiSetAgentSupportOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setAgentSupport(
    options?: Models.OriginationWriteApiSetAgentSupportOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setAgentSupportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setEditAgentSupport(
    options?: Models.OriginationWriteApiSetEditAgentSupportOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setEditAgentSupport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setEditAgentSupport(
    options: Models.OriginationWriteApiSetEditAgentSupportOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setEditAgentSupport(
    options?: Models.OriginationWriteApiSetEditAgentSupportOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setEditAgentSupportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setDisplayAdvances(
    options?: Models.OriginationWriteApiSetDisplayAdvancesOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setDisplayAdvances(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setDisplayAdvances(
    options: Models.OriginationWriteApiSetDisplayAdvancesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setDisplayAdvances(
    options?: Models.OriginationWriteApiSetDisplayAdvancesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setDisplayAdvancesOperationSpec,
      callback
    );
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addAccreditation(
    id: string,
    options?: Models.OriginationWriteApiAddAccreditationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  addAccreditation(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  addAccreditation(
    id: string,
    options: Models.OriginationWriteApiAddAccreditationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addAccreditation(
    id: string,
    options?: Models.OriginationWriteApiAddAccreditationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      addAccreditationOperationSpec,
      callback
    );
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadW9Form(
    id: string,
    options?: Models.OriginationWriteApiUploadW9FormOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  uploadW9Form(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadW9Form(
    id: string,
    options: Models.OriginationWriteApiUploadW9FormOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadW9Form(
    id: string,
    options?: Models.OriginationWriteApiUploadW9FormOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      uploadW9FormOperationSpec,
      callback
    );
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadBankStatement(
    id: string,
    options?: Models.OriginationWriteApiUploadBankStatementOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  uploadBankStatement(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadBankStatement(
    id: string,
    options: Models.OriginationWriteApiUploadBankStatementOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadBankStatement(
    id: string,
    options?: Models.OriginationWriteApiUploadBankStatementOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      uploadBankStatementOperationSpec,
      callback
    );
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadBlankInvoice(
    id: string,
    options?: Models.OriginationWriteApiUploadBlankInvoiceOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  uploadBlankInvoice(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadBlankInvoice(
    id: string,
    options: Models.OriginationWriteApiUploadBlankInvoiceOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadBlankInvoice(
    id: string,
    options?: Models.OriginationWriteApiUploadBlankInvoiceOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      uploadBlankInvoiceOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setPartnerRoles(
    partnerId: string,
    options?: Models.OriginationWriteApiSetPartnerRolesOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  setPartnerRoles(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  setPartnerRoles(
    partnerId: string,
    options: Models.OriginationWriteApiSetPartnerRolesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setPartnerRoles(
    partnerId: string,
    options?: Models.OriginationWriteApiSetPartnerRolesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      setPartnerRolesOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removePartnerRoles(
    partnerId: string,
    options?: Models.OriginationWriteApiRemovePartnerRolesOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  removePartnerRoles(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  removePartnerRoles(
    partnerId: string,
    options: Models.OriginationWriteApiRemovePartnerRolesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removePartnerRoles(
    partnerId: string,
    options?: Models.OriginationWriteApiRemovePartnerRolesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      removePartnerRolesOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addPartnerDailyReport(partnerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  addPartnerDailyReport(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  addPartnerDailyReport(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  addPartnerDailyReport(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      addPartnerDailyReportOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatePartnerDailyReportEmail(
    partnerId: string,
    options?: Models.OriginationWriteApiUpdatePartnerDailyReportEmailOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  updatePartnerDailyReportEmail(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerDailyReportEmail(
    partnerId: string,
    options: Models.OriginationWriteApiUpdatePartnerDailyReportEmailOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updatePartnerDailyReportEmail(
    partnerId: string,
    options?: Models.OriginationWriteApiUpdatePartnerDailyReportEmailOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      updatePartnerDailyReportEmailOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatePartnerReportFrequency(
    partnerId: string,
    options?: Models.OriginationWriteApiUpdatePartnerReportFrequencyOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  updatePartnerReportFrequency(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerReportFrequency(
    partnerId: string,
    options: Models.OriginationWriteApiUpdatePartnerReportFrequencyOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updatePartnerReportFrequency(
    partnerId: string,
    options?: Models.OriginationWriteApiUpdatePartnerReportFrequencyOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      updatePartnerReportFrequencyOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removePartnerDailyReport(partnerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  removePartnerDailyReport(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  removePartnerDailyReport(
    partnerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  removePartnerDailyReport(
    partnerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      removePartnerDailyReportOperationSpec,
      callback
    );
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadPartnerLogo(
    partnerId: string,
    options?: Models.OriginationWriteApiUploadPartnerLogoOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  uploadPartnerLogo(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadPartnerLogo(
    partnerId: string,
    options: Models.OriginationWriteApiUploadPartnerLogoOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadPartnerLogo(
    partnerId: string,
    options?: Models.OriginationWriteApiUploadPartnerLogoOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      uploadPartnerLogoOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableAllPartnerDisplayAdvances(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  enableAllPartnerDisplayAdvances(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  enableAllPartnerDisplayAdvances(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  enableAllPartnerDisplayAdvances(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      enableAllPartnerDisplayAdvancesOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setShowAgentCommunications(
    options?: Models.OriginationWriteApiSetShowAgentCommunicationsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setShowAgentCommunications(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setShowAgentCommunications(
    options: Models.OriginationWriteApiSetShowAgentCommunicationsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setShowAgentCommunications(
    options?: Models.OriginationWriteApiSetShowAgentCommunicationsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setShowAgentCommunicationsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setHideAgentApplication(
    options?: Models.OriginationWriteApiSetHideAgentApplicationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setHideAgentApplication(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setHideAgentApplication(
    options: Models.OriginationWriteApiSetHideAgentApplicationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setHideAgentApplication(
    options?: Models.OriginationWriteApiSetHideAgentApplicationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setHideAgentApplicationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setShowPartnerTodo(
    options?: Models.OriginationWriteApiSetShowPartnerTodoOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setShowPartnerTodo(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setShowPartnerTodo(
    options: Models.OriginationWriteApiSetShowPartnerTodoOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setShowPartnerTodo(
    options?: Models.OriginationWriteApiSetShowPartnerTodoOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setShowPartnerTodoOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setShowPartnerBusinessNameTodo(
    options?: Models.OriginationWriteApiSetShowPartnerBusinessNameTodoOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setShowPartnerBusinessNameTodo(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setShowPartnerBusinessNameTodo(
    options: Models.OriginationWriteApiSetShowPartnerBusinessNameTodoOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setShowPartnerBusinessNameTodo(
    options?: Models.OriginationWriteApiSetShowPartnerBusinessNameTodoOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setShowPartnerBusinessNameTodoOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  slackPartnerChannel(
    options?: Models.OriginationWriteApiSlackPartnerChannelOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  slackPartnerChannel(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  slackPartnerChannel(
    options: Models.OriginationWriteApiSlackPartnerChannelOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  slackPartnerChannel(
    options?: Models.OriginationWriteApiSlackPartnerChannelOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      slackPartnerChannelOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  mSTeamsPartnerChannel(
    options?: Models.OriginationWriteApiMSTeamsPartnerChannelOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  mSTeamsPartnerChannel(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  mSTeamsPartnerChannel(
    options: Models.OriginationWriteApiMSTeamsPartnerChannelOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  mSTeamsPartnerChannel(
    options?: Models.OriginationWriteApiMSTeamsPartnerChannelOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      mSTeamsPartnerChannelOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllReportDashboardSetting(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllReportDashboardSetting(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllReportDashboardSetting(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllReportDashboardSetting(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllReportDashboardSettingOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setReportDashboardSetting(
    options?: Models.OriginationWriteApiSetReportDashboardSettingOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setReportDashboardSetting(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setReportDashboardSetting(
    options: Models.OriginationWriteApiSetReportDashboardSettingOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setReportDashboardSetting(
    options?: Models.OriginationWriteApiSetReportDashboardSettingOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setReportDashboardSettingOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateReportDashboardSettingResponse>
   */
  updateReportDashboardSetting(
    options?: Models.OriginationWriteApiUpdateReportDashboardSettingOptionalParams
  ): Promise<Models.UpdateReportDashboardSettingResponse>;
  /**
   * @param callback The callback
   */
  updateReportDashboardSetting(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateReportDashboardSetting(
    options: Models.OriginationWriteApiUpdateReportDashboardSettingOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  updateReportDashboardSetting(
    options?: Models.OriginationWriteApiUpdateReportDashboardSettingOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.UpdateReportDashboardSettingResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateReportDashboardSettingOperationSpec,
      callback
    ) as Promise<Models.UpdateReportDashboardSettingResponse>;
  }

  /**
   * @param reportId
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteReportDashboardSettingResponse>
   */
  deleteReportDashboardSetting(
    reportId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.DeleteReportDashboardSettingResponse>;
  /**
   * @param reportId
   * @param callback The callback
   */
  deleteReportDashboardSetting(reportId: string, callback: msRest.ServiceCallback<Models.ProblemDetails>): void;
  /**
   * @param reportId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteReportDashboardSetting(
    reportId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ProblemDetails>
  ): void;
  deleteReportDashboardSetting(
    reportId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ProblemDetails>,
    callback?: msRest.ServiceCallback<Models.ProblemDetails>
  ): Promise<Models.DeleteReportDashboardSettingResponse> {
    return this.sendOperationRequest(
      {
        reportId,
        options
      },
      deleteReportDashboardSettingOperationSpec,
      callback
    ) as Promise<Models.DeleteReportDashboardSettingResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPlatformAgreementSettingsResponse>
   */
  getPlatformAgreementSettings(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPlatformAgreementSettingsResponse>;
  /**
   * @param callback The callback
   */
  getPlatformAgreementSettings(callback: msRest.ServiceCallback<Models.PlatformAgreementSettings>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPlatformAgreementSettings(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PlatformAgreementSettings>
  ): void;
  getPlatformAgreementSettings(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PlatformAgreementSettings>,
    callback?: msRest.ServiceCallback<Models.PlatformAgreementSettings>
  ): Promise<Models.GetPlatformAgreementSettingsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPlatformAgreementSettingsOperationSpec,
      callback
    ) as Promise<Models.GetPlatformAgreementSettingsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setPlatformAgreementSettings(
    options?: Models.OriginationWriteApiSetPlatformAgreementSettingsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setPlatformAgreementSettings(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setPlatformAgreementSettings(
    options: Models.OriginationWriteApiSetPlatformAgreementSettingsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setPlatformAgreementSettings(
    options?: Models.OriginationWriteApiSetPlatformAgreementSettingsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setPlatformAgreementSettingsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setCommunicationTemplateSettings(
    options?: Models.OriginationWriteApiSetCommunicationTemplateSettingsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setCommunicationTemplateSettings(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setCommunicationTemplateSettings(
    options: Models.OriginationWriteApiSetCommunicationTemplateSettingsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setCommunicationTemplateSettings(
    options?: Models.OriginationWriteApiSetCommunicationTemplateSettingsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setCommunicationTemplateSettingsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setSMSTemplateSettings(
    options?: Models.OriginationWriteApiSetSMSTemplateSettingsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setSMSTemplateSettings(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setSMSTemplateSettings(
    options: Models.OriginationWriteApiSetSMSTemplateSettingsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setSMSTemplateSettings(
    options?: Models.OriginationWriteApiSetSMSTemplateSettingsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setSMSTemplateSettingsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setPartnerCommunicationTemplateSettings(
    options?: Models.OriginationWriteApiSetPartnerCommunicationTemplateSettingsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setPartnerCommunicationTemplateSettings(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setPartnerCommunicationTemplateSettings(
    options: Models.OriginationWriteApiSetPartnerCommunicationTemplateSettingsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setPartnerCommunicationTemplateSettings(
    options?:
      | Models.OriginationWriteApiSetPartnerCommunicationTemplateSettingsOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setPartnerCommunicationTemplateSettingsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setAgentCommunicationTemplateSettings(
    options?: Models.OriginationWriteApiSetAgentCommunicationTemplateSettingsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setAgentCommunicationTemplateSettings(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setAgentCommunicationTemplateSettings(
    options: Models.OriginationWriteApiSetAgentCommunicationTemplateSettingsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setAgentCommunicationTemplateSettings(
    options?:
      | Models.OriginationWriteApiSetAgentCommunicationTemplateSettingsOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setAgentCommunicationTemplateSettingsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addCancelReason(options?: Models.OriginationWriteApiAddCancelReasonOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addCancelReason(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addCancelReason(
    options: Models.OriginationWriteApiAddCancelReasonOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addCancelReason(
    options?: Models.OriginationWriteApiAddCancelReasonOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addCancelReasonOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeCancelReason(
    options?: Models.OriginationWriteApiRemoveCancelReasonOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removeCancelReason(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removeCancelReason(
    options: Models.OriginationWriteApiRemoveCancelReasonOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeCancelReason(
    options?: Models.OriginationWriteApiRemoveCancelReasonOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removeCancelReasonOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setAdminCommunicationPanelSetting(
    options?: Models.OriginationWriteApiSetAdminCommunicationPanelSettingOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setAdminCommunicationPanelSetting(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setAdminCommunicationPanelSetting(
    options: Models.OriginationWriteApiSetAdminCommunicationPanelSettingOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setAdminCommunicationPanelSetting(
    options?: Models.OriginationWriteApiSetAdminCommunicationPanelSettingOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setAdminCommunicationPanelSettingOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  communicationAdded(
    options?: Models.OriginationWriteApiCommunicationAddedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  communicationAdded(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  communicationAdded(
    options: Models.OriginationWriteApiCommunicationAddedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  communicationAdded(
    options?: Models.OriginationWriteApiCommunicationAddedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      communicationAddedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  communicationRemoved(
    options?: Models.OriginationWriteApiCommunicationRemovedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  communicationRemoved(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  communicationRemoved(
    options: Models.OriginationWriteApiCommunicationRemovedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  communicationRemoved(
    options?: Models.OriginationWriteApiCommunicationRemovedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      communicationRemovedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  offerCompleted(options?: Models.OriginationWriteApiOfferCompletedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  offerCompleted(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  offerCompleted(
    options: Models.OriginationWriteApiOfferCompletedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  offerCompleted(
    options?: Models.OriginationWriteApiOfferCompletedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      offerCompletedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  offerFailed(options?: Models.OriginationWriteApiOfferFailedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  offerFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  offerFailed(
    options: Models.OriginationWriteApiOfferFailedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  offerFailed(
    options?: Models.OriginationWriteApiOfferFailedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      offerFailedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankingSystemConnected(
    options?: Models.OriginationWriteApiBankingSystemConnectedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankingSystemConnected(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankingSystemConnected(
    options: Models.OriginationWriteApiBankingSystemConnectedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  bankingSystemConnected(
    options?: Models.OriginationWriteApiBankingSystemConnectedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankingSystemConnectedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankfeedSystemConnected(
    options?: Models.OriginationWriteApiBankfeedSystemConnectedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankfeedSystemConnected(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankfeedSystemConnected(
    options: Models.OriginationWriteApiBankfeedSystemConnectedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  bankfeedSystemConnected(
    options?: Models.OriginationWriteApiBankfeedSystemConnectedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankfeedSystemConnectedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankingSystemFailed(
    options?: Models.OriginationWriteApiBankingSystemFailedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankingSystemFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankingSystemFailed(
    options: Models.OriginationWriteApiBankingSystemFailedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  bankingSystemFailed(
    options?: Models.OriginationWriteApiBankingSystemFailedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankingSystemFailedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankfeedSystemFailed(
    options?: Models.OriginationWriteApiBankfeedSystemFailedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankfeedSystemFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankfeedSystemFailed(
    options: Models.OriginationWriteApiBankfeedSystemFailedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  bankfeedSystemFailed(
    options?: Models.OriginationWriteApiBankfeedSystemFailedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankfeedSystemFailedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingSystemConnected(
    options?: Models.OriginationWriteApiAccountingSystemConnectedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingSystemConnected(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingSystemConnected(
    options: Models.OriginationWriteApiAccountingSystemConnectedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  accountingSystemConnected(
    options?: Models.OriginationWriteApiAccountingSystemConnectedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingSystemConnectedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingCompanySystemConnected(
    options?: Models.OriginationWriteApiAccountingCompanySystemConnectedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingCompanySystemConnected(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingCompanySystemConnected(
    options: Models.OriginationWriteApiAccountingCompanySystemConnectedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  accountingCompanySystemConnected(
    options?: Models.OriginationWriteApiAccountingCompanySystemConnectedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingCompanySystemConnectedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingCompanySystemConnectedWithDetails(
    options?: Models.OriginationWriteApiAccountingCompanySystemConnectedWithDetailsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingCompanySystemConnectedWithDetails(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingCompanySystemConnectedWithDetails(
    options: Models.OriginationWriteApiAccountingCompanySystemConnectedWithDetailsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  accountingCompanySystemConnectedWithDetails(
    options?:
      | Models.OriginationWriteApiAccountingCompanySystemConnectedWithDetailsOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingCompanySystemConnectedWithDetailsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingSystemFailed(
    options?: Models.OriginationWriteApiAccountingSystemFailedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingSystemFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingSystemFailed(
    options: Models.OriginationWriteApiAccountingSystemFailedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  accountingSystemFailed(
    options?: Models.OriginationWriteApiAccountingSystemFailedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingSystemFailedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankingSystemAccountsReady(
    options?: Models.OriginationWriteApiBankingSystemAccountsReadyOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankingSystemAccountsReady(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankingSystemAccountsReady(
    options: Models.OriginationWriteApiBankingSystemAccountsReadyOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  bankingSystemAccountsReady(
    options?: Models.OriginationWriteApiBankingSystemAccountsReadyOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankingSystemAccountsReadyOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankfeedSystemAccountsReady(
    options?: Models.OriginationWriteApiBankfeedSystemAccountsReadyOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankfeedSystemAccountsReady(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankfeedSystemAccountsReady(
    options: Models.OriginationWriteApiBankfeedSystemAccountsReadyOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  bankfeedSystemAccountsReady(
    options?: Models.OriginationWriteApiBankfeedSystemAccountsReadyOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankfeedSystemAccountsReadyOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankfeedSystemMFAReady(
    options?: Models.OriginationWriteApiBankfeedSystemMFAReadyOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankfeedSystemMFAReady(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankfeedSystemMFAReady(
    options: Models.OriginationWriteApiBankfeedSystemMFAReadyOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  bankfeedSystemMFAReady(
    options?: Models.OriginationWriteApiBankfeedSystemMFAReadyOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankfeedSystemMFAReadyOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  organisationCreated(
    options?: Models.OriginationWriteApiOrganisationCreatedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  organisationCreated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  organisationCreated(
    options: Models.OriginationWriteApiOrganisationCreatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  organisationCreated(
    options?: Models.OriginationWriteApiOrganisationCreatedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      organisationCreatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  organisationFailed(
    options?: Models.OriginationWriteApiOrganisationFailedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  organisationFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  organisationFailed(
    options: Models.OriginationWriteApiOrganisationFailedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  organisationFailed(
    options?: Models.OriginationWriteApiOrganisationFailedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      organisationFailedOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationCanBeCompleted(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  applicationCanBeCompleted(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationCanBeCompleted(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  applicationCanBeCompleted(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      applicationCanBeCompletedOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationCanNotBeCompleted(
    applicationId: string,
    options?: Models.OriginationWriteApiApplicationCanNotBeCompletedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  applicationCanNotBeCompleted(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationCanNotBeCompleted(
    applicationId: string,
    options: Models.OriginationWriteApiApplicationCanNotBeCompletedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  applicationCanNotBeCompleted(
    applicationId: string,
    options?: Models.OriginationWriteApiApplicationCanNotBeCompletedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      applicationCanNotBeCompletedOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  loanCreated(
    applicationId: string,
    options?: Models.OriginationWriteApiLoanCreatedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  loanCreated(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  loanCreated(
    applicationId: string,
    options: Models.OriginationWriteApiLoanCreatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  loanCreated(
    applicationId: string,
    options?: Models.OriginationWriteApiLoanCreatedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      loanCreatedOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationOfferUpdated(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  applicationOfferUpdated(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationOfferUpdated(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  applicationOfferUpdated(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      applicationOfferUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingProcessed(
    options?: Models.OriginationWriteApiAccountingProcessedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingProcessed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingProcessed(
    options: Models.OriginationWriteApiAccountingProcessedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  accountingProcessed(
    options?: Models.OriginationWriteApiAccountingProcessedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingProcessedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingProcessedError(
    options?: Models.OriginationWriteApiAccountingProcessedErrorOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingProcessedError(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingProcessedError(
    options: Models.OriginationWriteApiAccountingProcessedErrorOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  accountingProcessedError(
    options?: Models.OriginationWriteApiAccountingProcessedErrorOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingProcessedErrorOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingQueueStarted(
    options?: Models.OriginationWriteApiAccountingQueueStartedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingQueueStarted(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingQueueStarted(
    options: Models.OriginationWriteApiAccountingQueueStartedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  accountingQueueStarted(
    options?: Models.OriginationWriteApiAccountingQueueStartedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingQueueStartedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingQueueWait(
    options?: Models.OriginationWriteApiAccountingQueueWaitOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingQueueWait(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingQueueWait(
    options: Models.OriginationWriteApiAccountingQueueWaitOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  accountingQueueWait(
    options?: Models.OriginationWriteApiAccountingQueueWaitOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingQueueWaitOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  statementProcessed(
    options?: Models.OriginationWriteApiStatementProcessedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  statementProcessed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  statementProcessed(
    options: Models.OriginationWriteApiStatementProcessedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  statementProcessed(
    options?: Models.OriginationWriteApiStatementProcessedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      statementProcessedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationStatusChanged(
    options?: Models.OriginationWriteApiApplicationStatusChangedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  applicationStatusChanged(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationStatusChanged(
    options: Models.OriginationWriteApiApplicationStatusChangedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  applicationStatusChanged(
    options?: Models.OriginationWriteApiApplicationStatusChangedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      applicationStatusChangedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  contractSigned(options?: Models.OriginationWriteApiContractSignedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  contractSigned(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  contractSigned(
    options: Models.OriginationWriteApiContractSignedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  contractSigned(
    options?: Models.OriginationWriteApiContractSignedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      contractSignedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  refreshEquifax(options?: Models.OriginationWriteApiRefreshEquifaxOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  refreshEquifax(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  refreshEquifax(
    options: Models.OriginationWriteApiRefreshEquifaxOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  refreshEquifax(
    options?: Models.OriginationWriteApiRefreshEquifaxOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      refreshEquifaxOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  refreshEquifaxFailed(
    options?: Models.OriginationWriteApiRefreshEquifaxFailedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  refreshEquifaxFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  refreshEquifaxFailed(
    options: Models.OriginationWriteApiRefreshEquifaxFailedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  refreshEquifaxFailed(
    options?: Models.OriginationWriteApiRefreshEquifaxFailedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      refreshEquifaxFailedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  refreshIllion(options?: Models.OriginationWriteApiRefreshIllionOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  refreshIllion(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  refreshIllion(
    options: Models.OriginationWriteApiRefreshIllionOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  refreshIllion(
    options?: Models.OriginationWriteApiRefreshIllionOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      refreshIllionOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  refreshIllionFailed(
    options?: Models.OriginationWriteApiRefreshIllionFailedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  refreshIllionFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  refreshIllionFailed(
    options: Models.OriginationWriteApiRefreshIllionFailedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  refreshIllionFailed(
    options?: Models.OriginationWriteApiRefreshIllionFailedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      refreshIllionFailedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicantIsReadyForEvaluation(
    options?: Models.OriginationWriteApiApplicantIsReadyForEvaluationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  applicantIsReadyForEvaluation(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  applicantIsReadyForEvaluation(
    options: Models.OriginationWriteApiApplicantIsReadyForEvaluationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  applicantIsReadyForEvaluation(
    options?: Models.OriginationWriteApiApplicantIsReadyForEvaluationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      applicantIsReadyForEvaluationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicationBusiness(
    options?: Models.OriginationWriteApiUpdateApplicationBusinessOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateApplicationBusiness(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicationBusiness(
    options: Models.OriginationWriteApiUpdateApplicationBusinessOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateApplicationBusiness(
    options?: Models.OriginationWriteApiUpdateApplicationBusinessOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateApplicationBusinessOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateIncomeOnReconnect(
    options?: Models.OriginationWriteApiUpdateIncomeOnReconnectOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateIncomeOnReconnect(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateIncomeOnReconnect(
    options: Models.OriginationWriteApiUpdateIncomeOnReconnectOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateIncomeOnReconnect(
    options?: Models.OriginationWriteApiUpdateIncomeOnReconnectOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateIncomeOnReconnectOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationProcessUpdated(
    applicationId: string,
    options?: Models.OriginationWriteApiApplicationProcessUpdatedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  applicationProcessUpdated(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationProcessUpdated(
    applicationId: string,
    options: Models.OriginationWriteApiApplicationProcessUpdatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  applicationProcessUpdated(
    applicationId: string,
    options?: Models.OriginationWriteApiApplicationProcessUpdatedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      applicationProcessUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  noteListUpdated(options?: Models.OriginationWriteApiNoteListUpdatedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  noteListUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  noteListUpdated(
    options: Models.OriginationWriteApiNoteListUpdatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  noteListUpdated(
    options?: Models.OriginationWriteApiNoteListUpdatedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      noteListUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  deleteBankAccountEvent(
    options?: Models.OriginationWriteApiDeleteBankAccountEventOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  deleteBankAccountEvent(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteBankAccountEvent(
    options: Models.OriginationWriteApiDeleteBankAccountEventOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  deleteBankAccountEvent(
    options?: Models.OriginationWriteApiDeleteBankAccountEventOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteBankAccountEventOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sMSEvent(options?: Models.OriginationWriteApiSMSEventOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sMSEvent(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sMSEvent(options: Models.OriginationWriteApiSMSEventOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sMSEvent(
    options?: Models.OriginationWriteApiSMSEventOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sMSEventOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  primaryBankAccountChangedEvent(
    options?: Models.OriginationWriteApiPrimaryBankAccountChangedEventOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  primaryBankAccountChangedEvent(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  primaryBankAccountChangedEvent(
    options: Models.OriginationWriteApiPrimaryBankAccountChangedEventOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  primaryBankAccountChangedEvent(
    options?: Models.OriginationWriteApiPrimaryBankAccountChangedEventOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      primaryBankAccountChangedEventOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  nachaFileUpdateEvent(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  nachaFileUpdateEvent(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  nachaFileUpdateEvent(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  nachaFileUpdateEvent(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      nachaFileUpdateEventOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableDebtorEvent(
    loanId: string,
    options?: Models.OriginationWriteApiEnableDebtorEventOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  enableDebtorEvent(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  enableDebtorEvent(
    loanId: string,
    options: Models.OriginationWriteApiEnableDebtorEventOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  enableDebtorEvent(
    loanId: string,
    options?: Models.OriginationWriteApiEnableDebtorEventOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      enableDebtorEventOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  logoutCustomerNotification(
    options?: Models.OriginationWriteApiLogoutCustomerNotificationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  logoutCustomerNotification(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  logoutCustomerNotification(
    options: Models.OriginationWriteApiLogoutCustomerNotificationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  logoutCustomerNotification(
    options?: Models.OriginationWriteApiLogoutCustomerNotificationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      logoutCustomerNotificationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateCustomerList(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateCustomerList(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCustomerList(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateCustomerList(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateCustomerListOperationSpec,
      callback
    );
  }

  /**
   * @param offerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanBalanceAndSetting(offerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param offerId
   * @param callback The callback
   */
  updateLoanBalanceAndSetting(offerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param offerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanBalanceAndSetting(
    offerId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateLoanBalanceAndSetting(
    offerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        offerId,
        options
      },
      updateLoanBalanceAndSettingOperationSpec,
      callback
    );
  }

  /**
   * @param offerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanSetting(offerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param offerId
   * @param callback The callback
   */
  updateLoanSetting(offerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param offerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanSetting(offerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateLoanSetting(
    offerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        offerId,
        options
      },
      updateLoanSettingOperationSpec,
      callback
    );
  }

  /**
   * @param offerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanBalance(offerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param offerId
   * @param callback The callback
   */
  updateLoanBalance(offerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param offerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanBalance(offerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateLoanBalance(
    offerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        offerId,
        options
      },
      updateLoanBalanceOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateNewTransactions(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateNewTransactions(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateNewTransactions(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateNewTransactions(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateNewTransactionsOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reprocessComplete(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reprocessComplete(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reprocessComplete(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  reprocessComplete(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reprocessCompleteOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateDebtorLists(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateDebtorLists(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateDebtorLists(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateDebtorLists(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateDebtorListsOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableLoanSignal(
    loanId: string,
    options?: Models.OriginationWriteApiEnableLoanSignalOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  enableLoanSignal(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  enableLoanSignal(
    loanId: string,
    options: Models.OriginationWriteApiEnableLoanSignalOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  enableLoanSignal(
    loanId: string,
    options?: Models.OriginationWriteApiEnableLoanSignalOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      enableLoanSignalOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateNewAdvances(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateNewAdvances(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateNewAdvances(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateNewAdvances(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateNewAdvancesOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  auditInvoice(options?: Models.OriginationWriteApiAuditInvoiceOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  auditInvoice(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  auditInvoice(
    options: Models.OriginationWriteApiAuditInvoiceOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  auditInvoice(
    options?: Models.OriginationWriteApiAuditInvoiceOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      auditInvoiceOperationSpec,
      callback
    );
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  customerCreated(customerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  customerCreated(customerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  customerCreated(customerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  customerCreated(
    customerId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      customerCreatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  customerRolesUpdated(
    options?: Models.OriginationWriteApiCustomerRolesUpdatedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  customerRolesUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  customerRolesUpdated(
    options: Models.OriginationWriteApiCustomerRolesUpdatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  customerRolesUpdated(
    options?: Models.OriginationWriteApiCustomerRolesUpdatedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      customerRolesUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  insightNotification(
    options?: Models.OriginationWriteApiInsightNotificationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  insightNotification(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  insightNotification(
    options: Models.OriginationWriteApiInsightNotificationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  insightNotification(
    options?: Models.OriginationWriteApiInsightNotificationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      insightNotificationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  dashboardBankfeedAccountsUpdated(
    options?: Models.OriginationWriteApiDashboardBankfeedAccountsUpdatedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  dashboardBankfeedAccountsUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  dashboardBankfeedAccountsUpdated(
    options: Models.OriginationWriteApiDashboardBankfeedAccountsUpdatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  dashboardBankfeedAccountsUpdated(
    options?: Models.OriginationWriteApiDashboardBankfeedAccountsUpdatedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      dashboardBankfeedAccountsUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  notifyPartnerPasswordResetCompleted(
    options?: Models.OriginationWriteApiNotifyPartnerPasswordResetCompletedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  notifyPartnerPasswordResetCompleted(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  notifyPartnerPasswordResetCompleted(
    options: Models.OriginationWriteApiNotifyPartnerPasswordResetCompletedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  notifyPartnerPasswordResetCompleted(
    options?:
      | Models.OriginationWriteApiNotifyPartnerPasswordResetCompletedOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      notifyPartnerPasswordResetCompletedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatePartnerList(options?: Models.OriginationWriteApiUpdatePartnerListOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updatePartnerList(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerList(
    options: Models.OriginationWriteApiUpdatePartnerListOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updatePartnerList(
    options?: Models.OriginationWriteApiUpdatePartnerListOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updatePartnerListOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateAgentList(options?: Models.OriginationWriteApiUpdateAgentListOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateAgentList(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAgentList(
    options: Models.OriginationWriteApiUpdateAgentListOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateAgentList(
    options?: Models.OriginationWriteApiUpdateAgentListOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateAgentListOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  investorListNotification(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  investorListNotification(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  investorListNotification(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  investorListNotification(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      investorListNotificationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  partnerCommunicationUpdated(
    options?: Models.OriginationWriteApiPartnerCommunicationUpdatedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  partnerCommunicationUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  partnerCommunicationUpdated(
    options: Models.OriginationWriteApiPartnerCommunicationUpdatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  partnerCommunicationUpdated(
    options?: Models.OriginationWriteApiPartnerCommunicationUpdatedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      partnerCommunicationUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  partnerApplicationCommunicationUpdated(
    options?: Models.OriginationWriteApiPartnerApplicationCommunicationUpdatedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  partnerApplicationCommunicationUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  partnerApplicationCommunicationUpdated(
    options: Models.OriginationWriteApiPartnerApplicationCommunicationUpdatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  partnerApplicationCommunicationUpdated(
    options?:
      | Models.OriginationWriteApiPartnerApplicationCommunicationUpdatedOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      partnerApplicationCommunicationUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  agentCommunicationUpdated(
    options?: Models.OriginationWriteApiAgentCommunicationUpdatedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  agentCommunicationUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  agentCommunicationUpdated(
    options: Models.OriginationWriteApiAgentCommunicationUpdatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  agentCommunicationUpdated(
    options?: Models.OriginationWriteApiAgentCommunicationUpdatedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      agentCommunicationUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  agentApplicationCommunicationUpdated(
    options?: Models.OriginationWriteApiAgentApplicationCommunicationUpdatedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  agentApplicationCommunicationUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  agentApplicationCommunicationUpdated(
    options: Models.OriginationWriteApiAgentApplicationCommunicationUpdatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  agentApplicationCommunicationUpdated(
    options?:
      | Models.OriginationWriteApiAgentApplicationCommunicationUpdatedOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      agentApplicationCommunicationUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadMissingTransactionComplete(
    options?: Models.OriginationWriteApiUploadMissingTransactionCompleteOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  uploadMissingTransactionComplete(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadMissingTransactionComplete(
    options: Models.OriginationWriteApiUploadMissingTransactionCompleteOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadMissingTransactionComplete(
    options?: Models.OriginationWriteApiUploadMissingTransactionCompleteOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      uploadMissingTransactionCompleteOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadMissingTransactionFail(
    options?: Models.OriginationWriteApiUploadMissingTransactionFailOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  uploadMissingTransactionFail(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadMissingTransactionFail(
    options: Models.OriginationWriteApiUploadMissingTransactionFailOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  uploadMissingTransactionFail(
    options?: Models.OriginationWriteApiUploadMissingTransactionFailOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      uploadMissingTransactionFailOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankStatementMissingTransactionUpdated(
    options?: Models.OriginationWriteApiBankStatementMissingTransactionUpdatedOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankStatementMissingTransactionUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankStatementMissingTransactionUpdated(
    options: Models.OriginationWriteApiBankStatementMissingTransactionUpdatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  bankStatementMissingTransactionUpdated(
    options?:
      | Models.OriginationWriteApiBankStatementMissingTransactionUpdatedOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankStatementMissingTransactionUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  documentUploadedEvent(
    options?: Models.OriginationWriteApiDocumentUploadedEventOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  documentUploadedEvent(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  documentUploadedEvent(
    options: Models.OriginationWriteApiDocumentUploadedEventOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  documentUploadedEvent(
    options?: Models.OriginationWriteApiDocumentUploadedEventOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      documentUploadedEventOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  createEmptyOfferEvent(
    options?: Models.OriginationWriteApiCreateEmptyOfferEventOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  createEmptyOfferEvent(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createEmptyOfferEvent(
    options: Models.OriginationWriteApiCreateEmptyOfferEventOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  createEmptyOfferEvent(
    options?: Models.OriginationWriteApiCreateEmptyOfferEventOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createEmptyOfferEventOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateAlertNotifications(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateAlertNotifications(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAlertNotifications(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateAlertNotifications(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateAlertNotificationsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAll(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAll(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAll(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAll(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  build(options?: Models.OriginationWriteApiBuildOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  build(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  build(options: Models.OriginationWriteApiBuildOptionalParams, callback: msRest.ServiceCallback<void>): void;
  build(
    options?: Models.OriginationWriteApiBuildOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildApplicationSummary(
    options?: Models.OriginationWriteApiBuildApplicationSummaryOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildApplicationSummary(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildApplicationSummary(
    options: Models.OriginationWriteApiBuildApplicationSummaryOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildApplicationSummary(
    options?: Models.OriginationWriteApiBuildApplicationSummaryOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildApplicationSummaryOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildLoanSummary(options?: Models.OriginationWriteApiBuildLoanSummaryOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildLoanSummary(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildLoanSummary(
    options: Models.OriginationWriteApiBuildLoanSummaryOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildLoanSummary(
    options?: Models.OriginationWriteApiBuildLoanSummaryOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildLoanSummaryOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  communicationRequestString(
    options?: Models.OriginationWriteApiCommunicationRequestStringOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  communicationRequestString(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  communicationRequestString(
    options: Models.OriginationWriteApiCommunicationRequestStringOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  communicationRequestString(
    options?: Models.OriginationWriteApiCommunicationRequestStringOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      communicationRequestStringOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationRequestString(
    options?: Models.OriginationWriteApiApplicationRequestStringOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  applicationRequestString(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationRequestString(
    options: Models.OriginationWriteApiApplicationRequestStringOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  applicationRequestString(
    options?: Models.OriginationWriteApiApplicationRequestStringOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      applicationRequestStringOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationResultRequestString(
    options?: Models.OriginationWriteApiApplicationResultRequestStringOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  applicationResultRequestString(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationResultRequestString(
    options: Models.OriginationWriteApiApplicationResultRequestStringOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  applicationResultRequestString(
    options?: Models.OriginationWriteApiApplicationResultRequestStringOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      applicationResultRequestStringOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  loanRequestString(options?: Models.OriginationWriteApiLoanRequestStringOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  loanRequestString(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  loanRequestString(
    options: Models.OriginationWriteApiLoanRequestStringOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  loanRequestString(
    options?: Models.OriginationWriteApiLoanRequestStringOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      loanRequestStringOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationCommunicationRequestString(
    options?: Models.OriginationWriteApiApplicationCommunicationRequestStringOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  applicationCommunicationRequestString(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationCommunicationRequestString(
    options: Models.OriginationWriteApiApplicationCommunicationRequestStringOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  applicationCommunicationRequestString(
    options?:
      | Models.OriginationWriteApiApplicationCommunicationRequestStringOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      applicationCommunicationRequestStringOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addSupportingDocument(
    applicationId: string,
    options?: Models.OriginationWriteApiAddSupportingDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addSupportingDocument(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addSupportingDocument(
    applicationId: string,
    options: Models.OriginationWriteApiAddSupportingDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addSupportingDocument(
    applicationId: string,
    options?: Models.OriginationWriteApiAddSupportingDocumentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addSupportingDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addSupportingDocumentDirectly(
    applicationId: string,
    options?: Models.OriginationWriteApiAddSupportingDocumentDirectlyOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addSupportingDocumentDirectly(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addSupportingDocumentDirectly(
    applicationId: string,
    options: Models.OriginationWriteApiAddSupportingDocumentDirectlyOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addSupportingDocumentDirectly(
    applicationId: string,
    options?: Models.OriginationWriteApiAddSupportingDocumentDirectlyOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addSupportingDocumentDirectlyOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addSupportingDocumentDirect(
    applicationId: string,
    options?: Models.OriginationWriteApiAddSupportingDocumentDirectOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addSupportingDocumentDirect(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addSupportingDocumentDirect(
    applicationId: string,
    options: Models.OriginationWriteApiAddSupportingDocumentDirectOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addSupportingDocumentDirect(
    applicationId: string,
    options?: Models.OriginationWriteApiAddSupportingDocumentDirectOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addSupportingDocumentDirectOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @deprecated This operation is deprecated. Please do not use it any longer.
   * @returns Promise<msRest.RestResponse>
   */
  generateDocuments(
    applicationId: string,
    options?: Models.OriginationWriteApiGenerateDocumentsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  generateDocuments(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  generateDocuments(
    applicationId: string,
    options: Models.OriginationWriteApiGenerateDocumentsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  generateDocuments(
    applicationId: string,
    options?: Models.OriginationWriteApiGenerateDocumentsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      generateDocumentsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addRequestSupportingDocument(
    options?: Models.OriginationWriteApiAddRequestSupportingDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addRequestSupportingDocument(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addRequestSupportingDocument(
    options: Models.OriginationWriteApiAddRequestSupportingDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addRequestSupportingDocument(
    options?: Models.OriginationWriteApiAddRequestSupportingDocumentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addRequestSupportingDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeRequestSupportingDocument(
    options?: Models.OriginationWriteApiRemoveRequestSupportingDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removeRequestSupportingDocument(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removeRequestSupportingDocument(
    options: Models.OriginationWriteApiRemoveRequestSupportingDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeRequestSupportingDocument(
    options?: Models.OriginationWriteApiRemoveRequestSupportingDocumentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removeRequestSupportingDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeSupportingDocument(
    applicationId: string,
    options?: Models.OriginationWriteApiRemoveSupportingDocumentOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  removeSupportingDocument(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeSupportingDocument(
    applicationId: string,
    options: Models.OriginationWriteApiRemoveSupportingDocumentOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeSupportingDocument(
    applicationId: string,
    options?: Models.OriginationWriteApiRemoveSupportingDocumentOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      removeSupportingDocumentOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addAdminTodo(options?: Models.OriginationWriteApiAddAdminTodoOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addAdminTodo(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addAdminTodo(
    options: Models.OriginationWriteApiAddAdminTodoOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addAdminTodo(
    options?: Models.OriginationWriteApiAddAdminTodoOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addAdminTodoOperationSpec,
      callback
    );
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateAdminTodoResponse>
   */
  updateAdminTodo(
    id: string,
    options?: Models.OriginationWriteApiUpdateAdminTodoOptionalParams
  ): Promise<Models.UpdateAdminTodoResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  updateAdminTodo(id: string, callback: msRest.ServiceCallback<Models.ProblemDetails>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAdminTodo(
    id: string,
    options: Models.OriginationWriteApiUpdateAdminTodoOptionalParams,
    callback: msRest.ServiceCallback<Models.ProblemDetails>
  ): void;
  updateAdminTodo(
    id: string,
    options?: Models.OriginationWriteApiUpdateAdminTodoOptionalParams | msRest.ServiceCallback<Models.ProblemDetails>,
    callback?: msRest.ServiceCallback<Models.ProblemDetails>
  ): Promise<Models.UpdateAdminTodoResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      updateAdminTodoOperationSpec,
      callback
    ) as Promise<Models.UpdateAdminTodoResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteAdminTodoResponse>
   */
  deleteAdminTodo(id: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteAdminTodoResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteAdminTodo(id: string, callback: msRest.ServiceCallback<Models.ProblemDetails>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteAdminTodo(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ProblemDetails>
  ): void;
  deleteAdminTodo(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ProblemDetails>,
    callback?: msRest.ServiceCallback<Models.ProblemDetails>
  ): Promise<Models.DeleteAdminTodoResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      deleteAdminTodoOperationSpec,
      callback
    ) as Promise<Models.DeleteAdminTodoResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addAgentTodo(options?: Models.OriginationWriteApiAddAgentTodoOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addAgentTodo(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addAgentTodo(
    options: Models.OriginationWriteApiAddAgentTodoOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addAgentTodo(
    options?: Models.OriginationWriteApiAddAgentTodoOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addAgentTodoOperationSpec,
      callback
    );
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateAgentTodoResponse>
   */
  updateAgentTodo(
    id: string,
    options?: Models.OriginationWriteApiUpdateAgentTodoOptionalParams
  ): Promise<Models.UpdateAgentTodoResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  updateAgentTodo(id: string, callback: msRest.ServiceCallback<Models.ProblemDetails>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAgentTodo(
    id: string,
    options: Models.OriginationWriteApiUpdateAgentTodoOptionalParams,
    callback: msRest.ServiceCallback<Models.ProblemDetails>
  ): void;
  updateAgentTodo(
    id: string,
    options?: Models.OriginationWriteApiUpdateAgentTodoOptionalParams | msRest.ServiceCallback<Models.ProblemDetails>,
    callback?: msRest.ServiceCallback<Models.ProblemDetails>
  ): Promise<Models.UpdateAgentTodoResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      updateAgentTodoOperationSpec,
      callback
    ) as Promise<Models.UpdateAgentTodoResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteAgentTodoResponse>
   */
  deleteAgentTodo(id: string, options?: msRest.RequestOptionsBase): Promise<Models.DeleteAgentTodoResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deleteAgentTodo(id: string, callback: msRest.ServiceCallback<Models.ProblemDetails>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteAgentTodo(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ProblemDetails>
  ): void;
  deleteAgentTodo(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ProblemDetails>,
    callback?: msRest.ServiceCallback<Models.ProblemDetails>
  ): Promise<Models.DeleteAgentTodoResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      deleteAgentTodoOperationSpec,
      callback
    ) as Promise<Models.DeleteAgentTodoResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addPartnerTodo(options?: Models.OriginationWriteApiAddPartnerTodoOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addPartnerTodo(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addPartnerTodo(
    options: Models.OriginationWriteApiAddPartnerTodoOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addPartnerTodo(
    options?: Models.OriginationWriteApiAddPartnerTodoOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addPartnerTodoOperationSpec,
      callback
    );
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdatePartnerTodoResponse>
   */
  updatePartnerTodo(
    id: string,
    options?: Models.OriginationWriteApiUpdatePartnerTodoOptionalParams
  ): Promise<Models.UpdatePartnerTodoResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  updatePartnerTodo(id: string, callback: msRest.ServiceCallback<Models.ProblemDetails>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerTodo(
    id: string,
    options: Models.OriginationWriteApiUpdatePartnerTodoOptionalParams,
    callback: msRest.ServiceCallback<Models.ProblemDetails>
  ): void;
  updatePartnerTodo(
    id: string,
    options?: Models.OriginationWriteApiUpdatePartnerTodoOptionalParams | msRest.ServiceCallback<Models.ProblemDetails>,
    callback?: msRest.ServiceCallback<Models.ProblemDetails>
  ): Promise<Models.UpdatePartnerTodoResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      updatePartnerTodoOperationSpec,
      callback
    ) as Promise<Models.UpdatePartnerTodoResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DeletePartnerTodoResponse>
   */
  deletePartnerTodo(id: string, options?: msRest.RequestOptionsBase): Promise<Models.DeletePartnerTodoResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deletePartnerTodo(id: string, callback: msRest.ServiceCallback<Models.ProblemDetails>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deletePartnerTodo(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ProblemDetails>
  ): void;
  deletePartnerTodo(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ProblemDetails>,
    callback?: msRest.ServiceCallback<Models.ProblemDetails>
  ): Promise<Models.DeletePartnerTodoResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      deletePartnerTodoOperationSpec,
      callback
    ) as Promise<Models.DeletePartnerTodoResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addPartnerBusinessTodo(
    options?: Models.OriginationWriteApiAddPartnerBusinessTodoOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addPartnerBusinessTodo(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addPartnerBusinessTodo(
    options: Models.OriginationWriteApiAddPartnerBusinessTodoOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addPartnerBusinessTodo(
    options?: Models.OriginationWriteApiAddPartnerBusinessTodoOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addPartnerBusinessTodoOperationSpec,
      callback
    );
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdatePartnerBusinessTodoResponse>
   */
  updatePartnerBusinessTodo(
    id: string,
    options?: Models.OriginationWriteApiUpdatePartnerBusinessTodoOptionalParams
  ): Promise<Models.UpdatePartnerBusinessTodoResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  updatePartnerBusinessTodo(id: string, callback: msRest.ServiceCallback<Models.ProblemDetails>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerBusinessTodo(
    id: string,
    options: Models.OriginationWriteApiUpdatePartnerBusinessTodoOptionalParams,
    callback: msRest.ServiceCallback<Models.ProblemDetails>
  ): void;
  updatePartnerBusinessTodo(
    id: string,
    options?:
      | Models.OriginationWriteApiUpdatePartnerBusinessTodoOptionalParams
      | msRest.ServiceCallback<Models.ProblemDetails>,
    callback?: msRest.ServiceCallback<Models.ProblemDetails>
  ): Promise<Models.UpdatePartnerBusinessTodoResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      updatePartnerBusinessTodoOperationSpec,
      callback
    ) as Promise<Models.UpdatePartnerBusinessTodoResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DeletePartnerBusinessTodoResponse>
   */
  deletePartnerBusinessTodo(
    id: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.DeletePartnerBusinessTodoResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  deletePartnerBusinessTodo(id: string, callback: msRest.ServiceCallback<Models.ProblemDetails>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  deletePartnerBusinessTodo(
    id: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ProblemDetails>
  ): void;
  deletePartnerBusinessTodo(
    id: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ProblemDetails>,
    callback?: msRest.ServiceCallback<Models.ProblemDetails>
  ): Promise<Models.DeletePartnerBusinessTodoResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      deletePartnerBusinessTodoOperationSpec,
      callback
    ) as Promise<Models.DeletePartnerBusinessTodoResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setSite(options?: Models.OriginationWriteApiSetSiteOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setSite(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setSite(options: Models.OriginationWriteApiSetSiteOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setSite(
    options?: Models.OriginationWriteApiSetSiteOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setSiteOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  rebuildCache(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  rebuildCache(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  rebuildCache(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  rebuildCache(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      rebuildCacheOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UploadBackGroudImageResponse>
   */
  uploadBackGroudImage(
    options?: Models.OriginationWriteApiUploadBackGroudImageOptionalParams
  ): Promise<Models.UploadBackGroudImageResponse>;
  /**
   * @param callback The callback
   */
  uploadBackGroudImage(callback: msRest.ServiceCallback<Models.SiteDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadBackGroudImage(
    options: Models.OriginationWriteApiUploadBackGroudImageOptionalParams,
    callback: msRest.ServiceCallback<Models.SiteDetails[]>
  ): void;
  uploadBackGroudImage(
    options?:
      | Models.OriginationWriteApiUploadBackGroudImageOptionalParams
      | msRest.ServiceCallback<Models.SiteDetails[]>,
    callback?: msRest.ServiceCallback<Models.SiteDetails[]>
  ): Promise<Models.UploadBackGroudImageResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      uploadBackGroudImageOperationSpec,
      callback
    ) as Promise<Models.UploadBackGroudImageResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UploadLogoResponse>
   */
  uploadLogo(options?: Models.OriginationWriteApiUploadLogoOptionalParams): Promise<Models.UploadLogoResponse>;
  /**
   * @param callback The callback
   */
  uploadLogo(callback: msRest.ServiceCallback<Models.SiteDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadLogo(
    options: Models.OriginationWriteApiUploadLogoOptionalParams,
    callback: msRest.ServiceCallback<Models.SiteDetails[]>
  ): void;
  uploadLogo(
    options?: Models.OriginationWriteApiUploadLogoOptionalParams | msRest.ServiceCallback<Models.SiteDetails[]>,
    callback?: msRest.ServiceCallback<Models.SiteDetails[]>
  ): Promise<Models.UploadLogoResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      uploadLogoOperationSpec,
      callback
    ) as Promise<Models.UploadLogoResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteBackGroundImageResponse>
   */
  deleteBackGroundImage(
    options?: Models.OriginationWriteApiDeleteBackGroundImageOptionalParams
  ): Promise<Models.DeleteBackGroundImageResponse>;
  /**
   * @param callback The callback
   */
  deleteBackGroundImage(callback: msRest.ServiceCallback<Models.SiteDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteBackGroundImage(
    options: Models.OriginationWriteApiDeleteBackGroundImageOptionalParams,
    callback: msRest.ServiceCallback<Models.SiteDetails[]>
  ): void;
  deleteBackGroundImage(
    options?:
      | Models.OriginationWriteApiDeleteBackGroundImageOptionalParams
      | msRest.ServiceCallback<Models.SiteDetails[]>,
    callback?: msRest.ServiceCallback<Models.SiteDetails[]>
  ): Promise<Models.DeleteBackGroundImageResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteBackGroundImageOperationSpec,
      callback
    ) as Promise<Models.DeleteBackGroundImageResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteLogoResponse>
   */
  deleteLogo(options?: Models.OriginationWriteApiDeleteLogoOptionalParams): Promise<Models.DeleteLogoResponse>;
  /**
   * @param callback The callback
   */
  deleteLogo(callback: msRest.ServiceCallback<Models.SiteDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteLogo(
    options: Models.OriginationWriteApiDeleteLogoOptionalParams,
    callback: msRest.ServiceCallback<Models.SiteDetails[]>
  ): void;
  deleteLogo(
    options?: Models.OriginationWriteApiDeleteLogoOptionalParams | msRest.ServiceCallback<Models.SiteDetails[]>,
    callback?: msRest.ServiceCallback<Models.SiteDetails[]>
  ): Promise<Models.DeleteLogoResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteLogoOperationSpec,
      callback
    ) as Promise<Models.DeleteLogoResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const addAgentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{partnerId}/add-agent',
  urlParameters: [Parameters.partnerId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.Agent
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const editAgentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{partnerId}/edit-agent',
  urlParameters: [Parameters.partnerId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.Agent
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableAgentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{partnerId}/enable-agents',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.agentId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const disableAgentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{partnerId}/disable-agent',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.agentId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeAgentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{partnerId}/remove-agent',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.agentId0, Parameters.force],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeAgentNoteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{partnerId}/remove-agent-note',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.agentId0, Parameters.noteId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const editAgentNoteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{partnerId}/edit-agent-note',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.agentId0, Parameters.noteId0, Parameters.note0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addAgentNoteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{partnerId}/add-agent-note',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.agentId0, Parameters.note0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setShowAgentTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{partnerId}/set-show-agent-todo',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.agentId0, Parameters.show],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setIsMasterOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{partnerId}/set-is-master',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.agentId0, Parameters.isMaster],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{agentId}/send-link',
  urlParameters: [Parameters.agentId1],
  queryParameters: [Parameters.partnerId1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.SendLinkRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const resendSendLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/{agentId}/resend-send-link',
  urlParameters: [Parameters.agentId1],
  queryParameters: [Parameters.partnerId1, Parameters.requestId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const moveAllAgentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/move-all-agents',
  queryParameters: [Parameters.sourceId, Parameters.destinationId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addAgentAnnouncementOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/add-agent-announcement',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.Announcement
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeAgentAnnouncementOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Agent/remove-agent-announcement',
  queryParameters: [Parameters.announcementId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addAlertNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/AlertNotification/add-alert-notification',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.AlertNotificationProjection
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const changeReadStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/AlertNotification/change-read-status/{id}',
  urlParameters: [Parameters.id0],
  queryParameters: [Parameters.isRead],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const markAllReadOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/AlertNotification/mark-all-read',
  queryParameters: [Parameters.classification0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const deleteAlertNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/AlertNotification/delete-alert-notification/{id}',
  urlParameters: [Parameters.id0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const deleteAlertNotificationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/AlertNotification/delete-alert-notifications',
  queryParameters: [Parameters.classification0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const assignEmailClassiicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/AlertNotificationEmailCategory/assign-email-classiications',
  queryParameters: [Parameters.email0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unAssignEmailClassificationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/AlertNotificationEmailCategory/unassign-email-classifications',
  queryParameters: [Parameters.email0, Parameters.classification1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unregisterApplicantOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicant}/unregister-applicant',
  urlParameters: [Parameters.applicant],
  queryParameters: [Parameters.applicantId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addPrimaryApplicantDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicationId}/add-primary-application-details',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.doCheck],
  requestBody: {
    parameterPath: 'body',
    mapper: {
      ...Mappers.PrimaryApplicantAndSecondaryApplicantRequested,
      required: true
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const requestSecondaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicationId}/request-secondary',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.primaryName, Parameters.name0, Parameters.email0, Parameters.phone0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const removeSecondaryRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicationId}/remove-request-secondary',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const addSecondaryApplicantDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicationId}/add-secondary-application-details',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.doCheck],
  requestBody: {
    parameterPath: 'body',
    mapper: {
      ...Mappers.SecondaryApplicant,
      required: true
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const sendSecondaryApplicantNoticeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicationId}/send-secondary-application-notice',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.email1, Parameters.phone1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateApplicantDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicationId}/update-applicant-details',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.applicantId1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.ApplicantProjection
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateApplicantDetailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicationId}/update-applicant-detail',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.applicantId1, Parameters.name0, Parameters.email0, Parameters.phoneNumber],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateApplicantSSNOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicationId}/update-applicant-ssn',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.applicantId1, Parameters.ssn],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateApplicantDriversLicenceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicationId}/update-applicant-drivers-licence',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [
    Parameters.applicantId1,
    Parameters.name0,
    Parameters.dateOfBirth,
    Parameters.address,
    Parameters.state,
    Parameters.city,
    Parameters.licenceNumber
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateApplicantEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicationId}/update-applicant-email',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.applicantId1, Parameters.email0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateApplicantDateOfBirthOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Applicant/{applicationId}/update-applicant-date-of-birth',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.applicantId1, Parameters.dateOfBirth],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const changeApplicantToPrimaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'change-applicant-type-to-primary',
  queryParameters: [Parameters.applicantId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const changeApplicantToSecondaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Applicant/change-applicant-type-to-secondary',
  queryParameters: [Parameters.applicantId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const cleanAlertsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/clean-alert',
  queryParameters: [Parameters.alert],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setPartnerApplicationStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/set-partner-application-status',
  queryParameters: [Parameters.applicationId1, Parameters.details],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const requestActionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: '{applicationId}/request-action',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.code, Parameters.details],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const testApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/test-application',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: Mappers.DecisionDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const sendBankDataRefreshOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/send-bank-refresh-data',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendBankDataRefreshOnStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/send-bank-refresh-data-on-status',
  queryParameters: [Parameters.source],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/send-message',
  queryParameters: [Parameters.messageType],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Dictionary',
        value: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const processContractOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/process-contract',
  queryParameters: [
    Parameters.organisationId0,
    Parameters.offerId0,
    Parameters.applicationId1,
    Parameters.existingLimit,
    Parameters.proposedLimit
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const generatePartnerReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/generate-partner-report',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendSimpleMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/send-simple-message',
  queryParameters: [Parameters.messageType, Parameters.message],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendApplicationReadyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/send-application-ready',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const buildContractOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/build-contract',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const completeApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/complete-application',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const resetLoginOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/reset-login',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const createApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/create-application',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.StartApplicationRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const createTempApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/create-temp-application',
  queryParameters: [Parameters.name1],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationAndCustomerResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const createApplicationAndCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/create-application-and-customer',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CreateApplicationAndCustomerRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationAndCustomerResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const createApplicationAndCustomerAndSupportDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/create-application-and-customer-with-support-documents',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CreateApplicationAndCustomerAndSupportDocumentsRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationAndCustomerResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const uploadSupportDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/upload-support-documents',
  queryParameters: [Parameters.applicationId1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'DocumentRequest'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const createApplicationAndCustomerAndApplicantAndSupportDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/create-application-and-customer-and-applicant-with-support-documents',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CreateApplicationAndCustomerAndApplicantAndSupportDocumentsRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationAndCustomerAndApplicantResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const createApplicationAndCustomerWithApplicantOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/create-application-and-customer-with-applicant',
  queryParameters: [Parameters.doCheck],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CreateApplicationAndCustomerWithApplicantRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationAndCustomerAndApplicantResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/update-customer',
  queryParameters: [Parameters.applicationId1, Parameters.customerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updatePartnerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/update-partner',
  queryParameters: [Parameters.applicationId1, Parameters.partnerId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateBusinessNameAndDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/update-business-name-and-details',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.businessName],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Dictionary',
        value: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateBusinessNameOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/update-business-name',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.businessName],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateOfferReadyDateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/update-offer-ready-date',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.offerReadyDate],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateBusinessDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/update-business-details',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Dictionary',
        value: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/update-application',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.UpdateApplicationRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const changeStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/change-status',
  queryParameters: [Parameters.applicationId1, Parameters.status0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateOfferOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/update-offer',
  queryParameters: [Parameters.applicationId1, Parameters.offerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateLoginStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/update-login-status',
  queryParameters: [Parameters.applicationId1, Parameters.status1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const sendCustomerApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/send-customer-application',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const registerCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/register-communication',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.isPartner],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.Communication
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const readCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/read-communication',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unregisterCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/unregister-communication/{communicationId}',
  urlParameters: [Parameters.applicationId0, Parameters.communicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendConnectionLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/send-connecting-link',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const cancelApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/cancel',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.reason, Parameters.cancelReasonId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const submittedApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/submitted',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.sms, Parameters.email1, Parameters.comment],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const linkBankingDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/link-banking-data',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.BankingData
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadBankStatementsDocumentIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/upload-bank-statements-document-id',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.documentId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const linkAccoutingDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/link-accounting-data',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.host],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.AccountingData
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const completedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/completed',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.sms, Parameters.email1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateApplicationCurrentProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/update-application-current-process',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.applicantId1, Parameters.status2],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const referOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/refer',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.sms, Parameters.email1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const confirmBankAccountsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/confirm-banking-accounts',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Number'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const connectBankfeedAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/connect-bankfeed-account',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.BankingData
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const connectYodleeBankfeedAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/connect-yodlee-bankfeed-account',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: 'body',
    mapper: {
      ...Mappers.BankAccountConnectionDetails,
      required: true
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const connectPlaidBankfeedAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/connect-plaid-bankfeed-account',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: 'body',
    mapper: {
      ...Mappers.BankAccountConnectionDetails,
      required: true
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const confirmBankfeedAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/confirm-bankfeed-account',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.BankAccount
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const confirmBankfeedAccountsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/confirm-bankfeed-accounts',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'BankAccount'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const confirmBankfeedYodleeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/confirm-bankfeed-yodlee',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const confirmBankfeedPlaidOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/confirm-bankfeed-plaid',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendApplicationOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/send-application-otp',
  queryParameters: [Parameters.number],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const sendLoginOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/send-login-otp',
  queryParameters: [Parameters.email0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendResetPasswordOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/send-reset-password-otp',
  queryParameters: [Parameters.email0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const changePasswordOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/change-password',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.PasswordResetModel
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const confirmBankfeedMFADetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/confirm-bankfeed-mfa',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.uri, Parameters.yodlee],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Dictionary',
        value: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const contractCompleteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/contract-complete',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.applicantId1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CompleteContractRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updatedContractCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/updated-contract-completed',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CompleteContractRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const generateOfferOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/generate-offer',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.accounting, Parameters.banking, Parameters.notification, Parameters.manual],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const commissionMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/commission',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.secured],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.Commission
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const connectAccountingCompanyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/connect-company',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.companyUri],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const connectAccountingCompanyWithDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/connect-company-with-details',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Dictionary',
        value: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addLoanCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/add-loan-customer',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.customerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateLoanCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/update-loan-customer',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.oldCustomerId, Parameters.newCustomerId],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const removeLoanCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/remove-loan-customer',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.customerId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const removePartnerExternalLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/remove-partner-external-link',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.partnerId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeAgentExternalLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/remove-agent-external-link',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.agentId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setPrimaryAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/primary-bank-accounts',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.OriginationBankAccount
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/update-communication',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CommunicationProjectionExtension
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addAuxiliaryBankAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/add-auxiliary-bank-account',
  queryParameters: [Parameters.organisationId1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.OriginationBankAccount
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconnectPlaidBankfeedAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/reconnect-plaid-bankfeed-account',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconnectAuxiliaryBankAccountsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/reconnect-auxiliary-bankfeed-accounts',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconnectYodleeBankfeedAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/reconnect-yodlee-bankfeed-account',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const logoutCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/logout-customer',
  queryParameters: [Parameters.customerId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const bankAccountConnectionSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/bankaccount-connection-settings',
  queryParameters: [Parameters.isYodlee, Parameters.isPlaid, Parameters.enablePlaidMultibank],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const deleteApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Application/delete-application',
  queryParameters: [Parameters.shouldDelete],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const sendAddAccountOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/send-add-account-otp',
  queryParameters: [Parameters.email0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendCustomerAuditOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/send-customer-audit',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.message],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendSalesTeamCalculationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/send-sales-team-calculation',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendCustomerOfferOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/send-customer-offer',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addUpdateAgentOnApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/add-update-agent-on-application',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.agentId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const resendUnderReviewApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/resend-under-review-application',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const createEmptyOfferOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/create-empty-offer',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const draftApplicationAccessOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/draft-application-access',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const markAsFraudOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/mark-as-fraud',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.fraud, Parameters.fraudReason],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendUnderReviewKycNoteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/send-under-review-kyc-note',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.status3, Parameters.limit, Parameters.note1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getApplicationBankBalanceSnapShotOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Application/{applicationId}/get-application-bank-balance-snapshot',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationBankBalanceSnapShot
    },
    500: {},
    default: {}
  },
  serializer
};

const captureApplicationBankBalanceSnapShotOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/capture-application-bank-balance-snapshot',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setManualReviewOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/ApplicationAutoApproval/{applicationId}/set-manual-review',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.status1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const deleteNoteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/ApplicationInsight/delete-note',
  queryParameters: [Parameters.noteId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const testApplicationOrganisationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/test-application-organisation',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reprocessApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/reprocess-applications',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const expireOfferReadyApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/expire-offer-ready-applications',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reprocessOnStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/reprocess-on-status',
  queryParameters: [Parameters.partnerId1, Parameters.source],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const ensureLoansAreCorrectStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/ensure-loans-are-correct-status',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const ensureLoansAreCorrectFunderOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/ensure-loans-are-correct-funder',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reprocessWaitingRoomOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/reprocess-waiting-room',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const resendEmailBankStatementMissingTransactionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/resend-email-bankstatement-missingtransaction',
  urlParameters: [Parameters.organisationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const sendToCustomerEmailBankStatementMissingTransactionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/sendtocustomer-email-bankstatement-missingtransaction',
  urlParameters: [Parameters.organisationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const sendCommunicationBankStatementMissingTransactionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/send-communication-bankstatement-missingtransaction',
  urlParameters: [Parameters.organisationId1],
  queryParameters: [Parameters.isPartner],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const addBankStatementMissingTransactionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/add-bankstatement-missingtransaction',
  urlParameters: [Parameters.organisationId1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.PlexeOrganisationServiceModelsTransactionMatrix
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteBankStatementMissingTransactionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/bankstatement/{organisationId}/add-bankstatement-missingtransaction',
  urlParameters: [Parameters.organisationId1],
  queryParameters: [Parameters.missingTransactionId],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadBankStatementDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/upload-bankstatement-document',
  urlParameters: [Parameters.organisationId1],
  queryParameters: [Parameters.externalSubmitted, Parameters.fileDateStr],
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadNewBankStatementDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/upload-new-bankstatement-document',
  urlParameters: [Parameters.organisationId1],
  queryParameters: [Parameters.fileName, Parameters.externalSubmitted],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'ByteArray'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadBankStatementDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/upload-bankstatement-documents',
  urlParameters: [Parameters.organisationId1],
  queryParameters: [Parameters.externalSubmitted],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'TransactionDataFile'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteBankStatementDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/bankstatement/{organisationId}/delete-bankstatement-document',
  urlParameters: [Parameters.organisationId1],
  queryParameters: [Parameters.fileName],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadBankStatementMissingTransactionDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/upload-bankstatement-missingtransaction-document',
  urlParameters: [Parameters.organisationId1],
  queryParameters: [Parameters.missingTransactionId],
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteBankStatementMissingTransactionDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/bankstatement/{organisationId}/delete-bankstatement-missingtransaction-document',
  urlParameters: [Parameters.organisationId1],
  queryParameters: [Parameters.fileName],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const bankStatementUploadCompleteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/bankstatement-upload-complete',
  urlParameters: [Parameters.organisationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const processThirdPartyBankStatementsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/process-third-party-bankstatements',
  urlParameters: [Parameters.organisationId1],
  queryParameters: [Parameters.isGeneral, Parameters.thirdParty],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadThirdPartyTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/upload-third-party-transactions',
  urlParameters: [Parameters.organisationId1],
  queryParameters: [Parameters.isGeneral, Parameters.thirdParty],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadAllThirdPartyTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/bankstatement/{organisationId}/upload-all-ocrolus-transactions',
  urlParameters: [Parameters.organisationId1],
  queryParameters: [Parameters.thirdParty],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setChargeOffOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/ChargeOff/{loanId}/set-charge-off',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.chargeOffDate],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const testChargeOffOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/ChargeOff/{loanId}/test-charge-off',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const testAllChargeOffOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/ChargeOff/test-all-charge-off',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const buildManualCheckListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/CheckList/{applicationId}/build-manual-check-list',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateManualCheckListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/CheckList/{applicationId}/update-manual-check-item',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.checkListId, Parameters.value],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const buildSettlementCheckListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/CheckList/{applicationId}/build-settlement-check-list',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateSettlementCheckListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/CheckList/{applicationId}/update-settlement-check-item',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.checkListId, Parameters.value],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addUpdateAgentCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Communication/add-update-agent-communication',
  queryParameters: [Parameters.sendToPartner],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CommunicationProjectionExtension
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const readAgentCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Communication/{agentId}/read-agent-application-communication',
  urlParameters: [Parameters.agentId1],
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const deleteAgentCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Communication/{agentId}/delete-agent-communication',
  urlParameters: [Parameters.agentId1],
  queryParameters: [Parameters.communicationId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addUpdatePartnerCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Communication/add-update-partner-communication',
  queryParameters: [Parameters.sendToAgent],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CommunicationProjectionExtension
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const readPartnerCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Communication/{partnerId}/read-partner-communication',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const readPartnerCommunication1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Communication/{partnerId}/read-partner-application-communication',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const deletePartnerCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Communication/{partnerId}/delete-partner-communication',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.communicationId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const forwardNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Communication/forward-communication',
  queryParameters: [
    Parameters.organisationId0,
    Parameters.notificationId0,
    Parameters.toIt,
    Parameters.toOps,
    Parameters.toCredit,
    Parameters.partnerId1,
    Parameters.agentId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setCommunicationRuleOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/CommunicationRule/set-communication-rule',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CommunicationRule
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setCommunicationRulesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/CommunicationRule/set-communication-rules',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'CommunicationRule'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteCommunicationRuleOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/CommunicationRule/delete-communication-rule',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CommunicationRule
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateCommunicationRuleOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/CommunicationRule/update-communication-rule',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CommunicationRule
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    default: {}
  },
  serializer
};

const setCustomerConnectionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Config/{customerId}/{connectionId}/set-customer-connection',
  urlParameters: [Parameters.customerId1, Parameters.connectionId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const testCustomerConnectionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Config/test-on-loan-id',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const resetCustomerConnectionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Config/{connectionId}/reset-customer-connection',
  urlParameters: [Parameters.connectionId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const startupConfigOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Config/startup-config',
  queryParameters: [Parameters.isSms],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableAccountingSystemOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Config/enable-accounting-system',
  queryParameters: [Parameters.enable0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableApplicantImageCaptureOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Config/enable-applicant-image-capture',
  queryParameters: [Parameters.enable0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addContactOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Contact/{applicationId}/add-contact',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.Contact
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeContactOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Contact/{applicationId}/remove-contact/{contactId}',
  urlParameters: [Parameters.applicationId0, Parameters.contactId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const editContactNotesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Contact/{applicationId}/edit-contact-notes/{contactId}',
  urlParameters: [Parameters.applicationId0, Parameters.contactId],
  queryParameters: [Parameters.notes],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const editContactStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Contact/{applicationId}/edit-contact-status/{contactId}',
  urlParameters: [Parameters.applicationId0, Parameters.contactId],
  queryParameters: [Parameters.status1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addCustomerAnnouncementOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Customer/add-customer-announcement',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.Announcement
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeCustomerAnnouncementOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Customer/remove-customer-announcement',
  queryParameters: [Parameters.announcementId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addCustomersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/customers',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'RegisterCustomerRequest'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Uuid'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const addCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/customer',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.RegisterCustomerRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const sendOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/customer/{customerId}/sendotp',
  urlParameters: [Parameters.customerId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const resetPassswordOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Customer/reset-password',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateCustomer1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Customer/update-customer',
  queryParameters: [Parameters.id1, Parameters.firstName, Parameters.lastName, Parameters.mobile, Parameters.email0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const disableCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Customer/disable',
  queryParameters: [Parameters.id2],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setUserRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Customer/{customerId}/set-user-roles',
  urlParameters: [Parameters.customerId1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeUserRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Customer/{customerId}/remove-user-roles',
  urlParameters: [Parameters.customerId1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const disableUnusedCustomersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Customer/disable-unused-customers',
  queryParameters: [Parameters.dryRun],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const disableClosedLoanCustomersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Customer/disable-closed-loan-customers',
  queryParameters: [Parameters.dryRun],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateDashboardInsightsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Dashboard/update-insight',
  queryParameters: [Parameters.applicationId1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'Insight'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateDashboardOnLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Dashboard/update-dashboard-on-loan',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateDashboardOnAllLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Dashboard/update-dashboard-on-all-loans',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateAccountDashboardOnAllLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Dashboard/update-account-on-all-loans',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addDisplayMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/add-display-message',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.DisplayMessageModal
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeDisplayMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/remove-display-message',
  queryParameters: [Parameters.id3],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const dismissDisplayMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/dismiss-display-message',
  queryParameters: [Parameters.id3, Parameters.loanId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addQuestionMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/add-question-message',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.QuestionMessageModal
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeQuestionMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/remove-question-message',
  queryParameters: [Parameters.id3],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const repliedQuestionMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/replied-question-message',
  queryParameters: [Parameters.id3, Parameters.loanId1, Parameters.answer],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addUpdateSystemMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/add-update-system-message',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.SystemMessageModal
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeSystemMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/DisplayMessage/remove-system-message',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/upload-document',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.externalSubmitted],
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadDocumentByArrayOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/upload-document-by-array',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.filename, Parameters.externalSubmitted],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'ByteArray'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/upload-documents',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.externalSubmitted],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'TransactionDataFile'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const deleteDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/{applicationId}/delete-document',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.filename],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const requestEarlyRepaymentDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: '{loanId}/request-early-repayment',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    default: {}
  },
  serializer
};

const requestExtraRepaymentDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: '{loanId}/request-extra-repayment',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.amount],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    default: {}
  },
  serializer
};

const sendIssueDefaultEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Email/{loanId}/send-issue-default',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.SendIssueDefaultModel
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadBaseSupportDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/upload-base-support-document',
  queryParameters: [Parameters.name0, Parameters.filename],
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateBaseSupportDocumentForApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/update-base-support-document-for-application',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const overrideContractOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/override-contract',
  queryParameters: [Parameters.applicationId1],
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadW9SampleDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/upload-w9-sample-document',
  queryParameters: [Parameters.name0],
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addTestFileOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Application/add-test-file',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const deleteTestFileOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Application/delete-test-file',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const intelligenceSearchAndAddOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Intelligence/intelligence-search-add',
  queryParameters: [Parameters.applicationId1, Parameters.searchString],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const intelligenceRebuildOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Intelligence/intelligence-rebuild',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const intelligenceSearchFlagOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Intelligence/intelligence-search-flag',
  queryParameters: [Parameters.applicationId1, Parameters.link, Parameters.flag],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const intelligenceSearchRemoveOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Intelligence/intelligence-search-remove',
  queryParameters: [Parameters.applicationId1, Parameters.link],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const intelligenceSearchRemoveManyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Intelligence/intelligence-search-remove-many',
  queryParameters: [Parameters.applicationId1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const addInvestorOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Investor/investor',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.RegisterInvestorRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const turnOnChangeOfFacilityOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/turn-on-change-of-facility',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addStaleOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/add-stale-override',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateFeesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/update-fees',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'TransactionDetail'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateTransactionDateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-transaction-date',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.transactionId, Parameters.newdate],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateTransactionDateNumberOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-transaction-date-number',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.transactionId, Parameters.year, Parameters.month, Parameters.day],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateTransactionDebtorOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-transaction-debtor',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.transactionId, Parameters.debtor],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateTransactionDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-transaction-details',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.transactionId, Parameters.details, Parameters.allowModification],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateTransactionDetailsAmountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-transaction-details-amount',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.transactionId, Parameters.details, Parameters.amount, Parameters.allowModification],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const registerTransactionsSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/register-transaction-summary',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'TransactionProjection'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidIEnumerableApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const setTransactionValueOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/set-transaction-value',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.transactionId, Parameters.amount],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const unregisterTransactionSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/unregister-transaction-summary',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.transactionId, Parameters.notification],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const registerSyntheticPaymentSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/register-synthetic-payment-summary',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.transactionId],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const registerSyntheticPaymentFullOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/register-synthetic-payment-full',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.transactionId,
    Parameters.debtor,
    Parameters.details,
    Parameters.amount,
    Parameters.dateParameter
  ],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const reconcileTransactionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/reconcile-transaction',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.transactionId, Parameters.reconciled],
  responses: {
    200: {
      bodyMapper: Mappers.StringApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const unregisterSyntheticPaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/unregister-synthetic-transaction-summary',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.transactionId,
    Parameters.debtor,
    Parameters.details,
    Parameters.amount,
    Parameters.dateParameter
  ],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const deleteSyntheticPaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/delete-synthetic-transaction',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.sythenticTransactionId],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const cleanAllAdvanceAdjustmentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/advance-adjustment-clean-all',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const buildAdvanceAdjustmentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/advance-adjustment-build',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const buildAllAdvanceAdjustmentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/advance-adjustment-build-all',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const processBankTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/process-bank-transactions',
  queryParameters: [Parameters.dateTime],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const registerAdvancesSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/register-advance-summary',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'AdvanceProjection'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceProjectionIEnumerableApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const registerAdvanceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/register-advance',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.AdvanceProjection
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceProjectionApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const registerAdjustmentAdvanceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/register-adjustment-advance',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.dateParameter,
    Parameters.amount,
    Parameters.advanceRate,
    Parameters.fixedRepayment,
    Parameters.fixedRepaymentFee,
    Parameters.upfrontFee,
    Parameters.trailingFee,
    Parameters.drawingFee,
    Parameters.type,
    Parameters.term
  ],
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceProjectionApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const unregisterAdvancesSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/unregister-advance-summary',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawStartDateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-withdraw-startdate',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.startDate],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateAdvanceDateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-advance-date',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.startDate],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateAdvanceRateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-advance-rate',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.rate],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateAdvanceDateNumberOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-advance-date-number',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.year, Parameters.month, Parameters.day],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawWeeklyRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-withdraw-weeklyrepayment',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.weeklyrepayment],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawTrailingCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-withdraw-trailing-commission',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.trailing],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawLegacyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-withdraw-legacy',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.legacy],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawUpfrontFeeCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-withdraw-upfront-fee-commission',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.upfrontFee],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawAgentFeeCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-withdraw-agent-fee-commission',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.upfrontFee],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawDrawFeeCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-withdraw-draw-fee-commission',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.drawFee],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateAdvanceLastRepaymentDateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-advance-last-repayment-date',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.lastRepayment],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawAsPercentageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/withdraw-as-percentage',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawPercentageRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawAsPercentageOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/withdraw-as-percentage-override',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawPercentageRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceProjectionApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawAsPercentageOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-withdraw-as-percentage-override',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawPercentageRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawAsInvoicePaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/withdraw-as-invoice-payment',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawInvoiceRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawAsInvoicePaymentOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/withdraw-as-invoice-payment-override',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawInvoiceRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceProjectionApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawAsInvoicePaymentOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-withdraw-as-invoice-payment-override',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawInvoiceRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawFixedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/withdraw-as-fixed',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawFixedRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawFixedOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/withdraw-as-fixed-override',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawFixedRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceProjectionApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawFixedOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-withdraw-as-fixed-override',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawFixedRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawLineOfCreditOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/withdraw-as-line-of-credit',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawLineOfCreditRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawLineOfCreditOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/withdraw-as-line-of-credit-override',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawLineOfCreditRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceProjectionApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawLineOfCreditOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-withdraw-as-line-of-credit-override',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.WithdrawLineOfCreditRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const registerPaymentArrangementOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/payment-arrangement',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.advanceId0,
    Parameters.startDate,
    Parameters.endDate,
    Parameters.amount,
    Parameters.fullAllocation,
    Parameters.principleSplit,
    Parameters.interestSplit
  ],
  responses: {
    200: {
      bodyMapper: Mappers.StringApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const unregisterPaymentArrangementOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/unregister-payment-arrangement',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.paymentArrangementId],
  responses: {
    200: {
      bodyMapper: Mappers.StringApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const earlyRepayAdvanceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/early-repay-advance',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const registerRepaymentDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/register-repayment-details',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'RepaymentAdjustmentProjection'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const registerEarlyRepaymentDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/register-early-repayment-details',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId1],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const registerSkipRepaymentDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/register-skip-repayment-details',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId1],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const unregisterRepaymentDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/unregister-repayment-details',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.repaymentId],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const addCommissionToAdvanceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/add-commission-to-advance',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.advanceId0, Parameters.amount],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const payCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/pay-commission/{advanceId}',
  urlParameters: [Parameters.loanId0, Parameters.advanceId2],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const commissionMethod1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/commission',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CommissionSet
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableDebtorOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/enable-debtor',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.debtorId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableDebtorServiceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/enable-debtor-service',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.enabled0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableShowAllDebtorOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/enable-show-all-debtor',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.enabled0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateBankIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-bank-id',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.bankId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateFunderOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-funder',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.funder0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/enable',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.enabled1, Parameters.reason],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateLoanCustomerDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-customer',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.customerId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateBusinessNameDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-business-name',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.businessName],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateBalanceOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-balance-override',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.balanceOverride],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateLoanStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-health',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.health],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconnectAccountingDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/reconnect-accounting-data',
  queryParameters: [Parameters.host, Parameters.loanId2],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.AccountingData
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reprocessAccountingDataCommandOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/reprocess-loan',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const disconnectAccountingSystemOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/disconnect-accounting-system',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const processPaymentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/process-payments',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const closeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/close',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.defaulted],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const switchLoanTypeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/switch-loan-type',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.loanType],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const openOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/open',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const archivedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/archived',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.archive],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateLoanPartnerDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/update-partner',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.partnerId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addChangeToFacilityOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/add-change-to-facility',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.newAmount],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeChangeToFacilityOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/remove-change-to-facility',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const createLoanOnApplicationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/create-on-application-id',
  queryParameters: [Parameters.applicationId1, Parameters.funder1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const registerBankAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/register-bank-account',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.BankDetails
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateRevolvingFacilityOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/revolving-facility',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.revolving, Parameters.local],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendOtp1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/sendotp',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const registerLoanNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/register-notification',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.Notification
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const readLoanNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/{notificationId}/read-notification',
  urlParameters: [Parameters.loanId0, Parameters.notificationId1],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const dailyUpdateOnLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/daily-reprocess-for-loan',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const disconnectBankAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/disconnect-bank-account',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateFlaggedStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/update-flagged-status',
  queryParameters: [Parameters.loanId1, Parameters.flagged, Parameters.reason, Parameters.dateParameter],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateExclusionStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/update-exclusion-status',
  queryParameters: [Parameters.loanId1, Parameters.exclude, Parameters.reason],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unregisterLoanFeeTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/unregister-loan-fee-transactions',
  queryParameters: [Parameters.dateParameter],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const dailyUpdateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanProcessing/daily-reprocess',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadFlaggedLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/LoanProcessing/upload-flagged-loans',
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateAllLoanBalancesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanProcessing/update-all-loan-balances',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeAllEstFeeRequestsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanProcessing/remove-all-est-fee-requests',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const checkLoanApplicationStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanProcessing/check-loan-application-status',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateNotAssignedTransactionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanProcessing/update-not-assigned-transactions',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanProcessing/remove-transactions',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeExtraEstFeeTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/LoanProcessing/remove-extra-est-transactions',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const preformHealthCheckOnAllOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/LoanProcessing/health-check-all',
  queryParameters: [Parameters.allowChange, Parameters.updateReason, Parameters.sendNotification, Parameters.manual],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'HealthRuleDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const preformHealthCheckOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/LoanProcessing/{loanId}/health-check',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.allowChange, Parameters.updateReason, Parameters.sendNotification],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'HealthCheckRule'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const preformHealthCheckDetailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/LoanProcessing/{loanId}/health-check-detail',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.allowChange, Parameters.updateReason, Parameters.sendNotification],
  responses: {
    200: {
      bodyMapper: Mappers.HealthRuleDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const dailyHealthCheckOnAllOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/LoanProcessing/daily-health-check-all',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const cleanAllHealthCheckMessagesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/LoanProcessing/clean-all-health-check-messages',
  queryParameters: [Parameters.allowChange, Parameters.updateReason, Parameters.sendNotification],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const cleanHealthCheckMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/LoanProcessing/clean-health-check-message',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const performHealthCheckOnAllCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/LoanProcessing/health-check-all-csv',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const generateAndSendHealthCheckCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/LoanProcessing/generate-and-send-health-check-csv',
  queryParameters: [Parameters.zip, Parameters.notification],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const autoMatchingProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/auto-payment-processing',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationAllPaymentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/reconciliation-all-payment',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'PaymentDetails'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationPaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/reconciliation-payment',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.paymentId,
    Parameters.syntheticPaymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/reconciliation-repayment',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.syntheticPaymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount,
    Parameters.transactionId
  ],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationTransfersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/reconciliation-transfers',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationExternalCreditOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/reconciliation-external-credit',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationInvoicePaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/reconciliation-invoice-payment',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount,
    Parameters.transactionId
  ],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationAllPaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/unreconciliation-all-payment',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'PaymentDetails'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationPaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/unreconciliation-payment',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.paymentId,
    Parameters.syntheticPaymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/unreconciliation-repayment',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.paymentId,
    Parameters.syntheticPaymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationInvoicePaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/unreconciliation-invoice-payment',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.paymentId,
    Parameters.syntheticPaymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationTransfersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/unreconciliation-transfers',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.paymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationExternalCreditOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/unreconciliation-external-credit',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.paymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const createReconcilationRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/create-reconciliation-request',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.ReconciliationDetails
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const createReconcilationMisdirectionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/create-reconciliation-misdirection',
  urlParameters: [Parameters.loanId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.ReconciliationDetails
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const paymentQueryStatusChangeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/payment-query-status-change/{paymentQueryId}',
  urlParameters: [Parameters.loanId0, Parameters.paymentQueryId1],
  queryParameters: [Parameters.status0, Parameters.notes, Parameters.updateReconcilationStatus],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const assignMisdirectionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/assign-misdirection',
  urlParameters: [Parameters.loanId0],
  queryParameters: [
    Parameters.transactionId,
    Parameters.dateParameter,
    Parameters.details,
    Parameters.debtors,
    Parameters.amount
  ],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Uuid'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const assignRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Loan/{loanId}/assign-request/{paymentQueryId}',
  urlParameters: [Parameters.loanId0, Parameters.paymentQueryId1],
  queryParameters: [
    Parameters.transactionId,
    Parameters.dateParameter,
    Parameters.details,
    Parameters.debtors,
    Parameters.amount
  ],
  responses: {
    200: {
      bodyMapper: Mappers.GuidApiResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const setLockDateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/LockDate/set-lock-date',
  queryParameters: [Parameters.dateParameter],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateAllCustomersOnDiskOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Lookup/update-all-customers-on-disk',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const addNoteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Notes/{applicationId}/add-note',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.Note
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addNoteMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Notes/{applicationId}/add-note-message',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeNoteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Notes/{applicationId}/remove-notes/{noteId}',
  urlParameters: [Parameters.applicationId0, Parameters.noteId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const cleanAllNotesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Notes/clean-all-notes',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const cleanAllNotesOnDaysOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Notes/clean-all-notes-on-days',
  queryParameters: [Parameters.days],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const editNotesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Notes/{applicationId}/edit-notes',
  urlParameters: [Parameters.applicationId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.Note
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setPartnerBankDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{partnerId}/set-partner-bank-details',
  urlParameters: [Parameters.partnerId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.PartnerBankDetails
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addPartnerAnnouncementOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/add-partner-announcement',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.Announcement
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removePartnerAnnouncementOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/remove-partner-announcement',
  queryParameters: [Parameters.announcementId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setPartnerLogoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{partnerId}/set-partner-logo',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.filename, Parameters.fileType],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'ByteArray'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addPartnerPaidCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: '{partnerId}/add-partner-paid-commission',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [
    Parameters.details,
    Parameters.customer,
    Parameters.depositDate,
    Parameters.amount,
    Parameters.funded
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updatePartnerAPIOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: '{partnerId}/update-partner-api-status',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.enabled1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updatePartnerBusinessNameOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: '{partnerId}/update-partner-business-name',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.name0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const rebuildPartnerPaidCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: '{partnerId}/rebuild-partner-paid-commission',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const rebuildAllPartnerPaidCommissionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'rebuild-all-partner-paid-commissions',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setPaidStatusPartnerPaidCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: '{partnerId}/set-paid-status-partner-paid-commission',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.partnerCommissionId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removePartnerPaidCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: '{partnerId}/remove-partner-paid-commission',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.partnerCommissionId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addPartnersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/partners',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'RegisterPartnerRequest'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Uuid'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updatePartnersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'PUT',
  path: 'api/partners',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'Composite',
            className: 'RegisterPartnerRequest'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Uuid'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updatePartner1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'PUT',
  path: 'api/partner',
  queryParameters: [Parameters.partnerId2],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.RegisterPartnerRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updatePartnerCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Partner/{partnerId}/update-partner-commission',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [
    Parameters.locTrailing,
    Parameters.unsecuredTrailing,
    Parameters.securedTrailing,
    Parameters.upfront
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const resetPasswordOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/reset-password',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const sendLink1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{partnerId}/send-link',
  urlParameters: [Parameters.partnerId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.SendLinkRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Uuid'
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const resendSendLink1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{partnerId}/resend-send-link',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.requestId],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendLinkForExistingOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{partnerId}/send-link-for-existing',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const disablePartnerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/disable',
  queryParameters: [Parameters.id2],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removePartnerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/remove-partner',
  queryParameters: [Parameters.id2],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setAgentSupportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/set-agent-support',
  queryParameters: [Parameters.partnerId1, Parameters.support],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setEditAgentSupportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/set-edit-agent-support',
  queryParameters: [Parameters.partnerId1, Parameters.isEdit],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setDisplayAdvancesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/set-display-advances',
  queryParameters: [Parameters.partnerId1, Parameters.isDisplayAdvances],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addAccreditationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{Id}/add-accreditation',
  urlParameters: [Parameters.id1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.PartnerAccreditationRequest
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadW9FormOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{Id}/upload-w9-form',
  urlParameters: [Parameters.id1],
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadBankStatementOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{Id}/upload-bank-statement',
  urlParameters: [Parameters.id1],
  formDataParameters: [Parameters.uploadedFiles],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadBlankInvoiceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{Id}/upload-blank-invoice',
  urlParameters: [Parameters.id1],
  formDataParameters: [Parameters.uploadedFiles],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setPartnerRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{partnerId}/set-partner-roles',
  urlParameters: [Parameters.partnerId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removePartnerRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{partnerId}/remove-user-roles',
  urlParameters: [Parameters.partnerId0],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addPartnerDailyReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{partnerId}/add-partner-daily-report',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updatePartnerDailyReportEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{partnerId}/update-partner-daily-report-email',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.email0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updatePartnerReportFrequencyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{partnerId}/update-partner-report-frequency',
  urlParameters: [Parameters.partnerId0],
  queryParameters: [Parameters.frequency],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removePartnerDailyReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{partnerId}/remove-partner-daily-report',
  urlParameters: [Parameters.partnerId0],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadPartnerLogoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/{partnerId}/upload-partner-logo',
  urlParameters: [Parameters.partnerId0],
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableAllPartnerDisplayAdvancesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/enable-all-partner-display-advances',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setShowAgentCommunicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/set-show-agent-communications',
  queryParameters: [Parameters.partnerId1, Parameters.show],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setHideAgentApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/set-hide-agent-application',
  queryParameters: [Parameters.partnerId1, Parameters.hide],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setShowPartnerTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/set-show-partner-todo',
  queryParameters: [Parameters.partnerId1, Parameters.show],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setShowPartnerBusinessNameTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Partner/set-show-partner-business-name-todo',
  queryParameters: [Parameters.partnerId1, Parameters.show],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const slackPartnerChannelOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/PartnerChannel/slack-partner-channel',
  queryParameters: [Parameters.partnerId1, Parameters.name0, Parameters.url],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const mSTeamsPartnerChannelOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/PartnerChannel/msteams-partner-channel',
  queryParameters: [Parameters.partnerId1, Parameters.name0, Parameters.url],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllReportDashboardSettingOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/ReportDashboardSetting/buil-all-report-dashboard-setting',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setReportDashboardSettingOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/ReportDashboardSetting/set-report-dashboard-setting',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.ReportDashboard
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateReportDashboardSettingOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/ReportDashboardSetting/update-report-dashboard-setting',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.ReportDashboard
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    default: {}
  },
  serializer
};

const deleteReportDashboardSettingOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/ReportDashboardSetting/{reportId}/delete-report-dashboard-setting',
  urlParameters: [Parameters.reportId],
  responses: {
    200: {},
    400: {
      bodyMapper: Mappers.ProblemDetails
    },
    404: {
      bodyMapper: Mappers.ProblemDetails
    },
    default: {
      bodyMapper: Mappers.ProblemDetails
    }
  },
  serializer
};

const getPlatformAgreementSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Setting/get-platform-agreement-settings',
  responses: {
    200: {
      bodyMapper: Mappers.PlatformAgreementSettings
    },
    default: {}
  },
  serializer
};

const setPlatformAgreementSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Setting/set-platform-agreement-settings',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.PlatformAgreementSettings
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setCommunicationTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Setting/set-communication-template-settings',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CommunicationTemplateSetting
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setSMSTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Setting/set-sms-template-settings',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CommunicationTemplateSetting
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setPartnerCommunicationTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Setting/set-partnercommunication-template-settings',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CommunicationTemplateSetting
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setAgentCommunicationTemplateSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Setting/set-agentcommunication-template-settings',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CommunicationTemplateSetting
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const addCancelReasonOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Setting/add-cancel-reason',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.CancelReason
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const removeCancelReasonOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Setting/remove-cancel-reason',
  queryParameters: [Parameters.reasonId],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const setAdminCommunicationPanelSettingOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Setting/set-admin-communication-panel-setting',
  queryParameters: [Parameters.isEnable],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const communicationAddedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'communication-added',
  queryParameters: [
    Parameters.applicationId1,
    Parameters.notificationId0,
    Parameters.message,
    Parameters.user,
    Parameters.createdOnUtc
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const communicationRemovedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'communication-removed',
  queryParameters: [
    Parameters.applicationId1,
    Parameters.notificationId0,
    Parameters.message,
    Parameters.user,
    Parameters.createdOnUtc
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const offerCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'offer-completed',
  queryParameters: [Parameters.offerId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const offerFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'offer-failed',
  queryParameters: [Parameters.offerId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const bankingSystemConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'banking-system-connected',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const bankfeedSystemConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'bankfeed-system-connected',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const bankingSystemFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'banking-system-failed',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const bankfeedSystemFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'bankfeed-system-failed',
  queryParameters: [Parameters.applicationId1, Parameters.message],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const accountingSystemConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'accounting-system-connected',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const accountingCompanySystemConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'accounting-system-company-connected',
  queryParameters: [Parameters.applicationId1, Parameters.companies],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const accountingCompanySystemConnectedWithDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'accounting-system-company-connected-with-details',
  queryParameters: [Parameters.applicationId1, Parameters.response],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const accountingSystemFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'accounting-system-failed',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const bankingSystemAccountsReadyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'banking-system-accounts-ready',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const bankfeedSystemAccountsReadyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'bankfeed-system-accounts-ready',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const bankfeedSystemMFAReadyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'bankfeed-system-mfa-ready',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const organisationCreatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'organisation-created',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const organisationFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'organisation-failed',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const applicationCanBeCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{applicationId}/application-can-be-completed',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const applicationCanNotBeCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{applicationId}/application-can-not-be-completed',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.message],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const loanCreatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{applicationId}/loan-created',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const applicationOfferUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{applicationId}/application-offer-updated',
  urlParameters: [Parameters.applicationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const accountingProcessedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'accounting-processed',
  queryParameters: [Parameters.organisationId0, Parameters.message, Parameters.count],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const accountingProcessedErrorOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'accounting-processed-error',
  queryParameters: [Parameters.organisationId0, Parameters.message, Parameters.url, Parameters.provider],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const accountingQueueStartedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'accounting-process-started',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const accountingQueueWaitOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'accounting-process-queued',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const statementProcessedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'statement-processed',
  queryParameters: [Parameters.organisationId0, Parameters.message, Parameters.count],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const applicationStatusChangedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'application-status-change',
  queryParameters: [Parameters.applicationId1, Parameters.customerId0, Parameters.partnerId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const contractSignedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'contract-signed',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const refreshEquifaxOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'refresh-equifax',
  queryParameters: [Parameters.applicantId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const refreshEquifaxFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'refresh-equifax-failed',
  queryParameters: [Parameters.applicantId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const refreshIllionOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'refresh-illion',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const refreshIllionFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'refresh-illion-failed',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const applicantIsReadyForEvaluationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'applicant-is-ready-for-evaluation',
  queryParameters: [Parameters.applicantId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateApplicationBusinessOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'update-application-business',
  queryParameters: [Parameters.applicationId1, Parameters.message],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateIncomeOnReconnectOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'update-income-on-reconnect',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const applicationProcessUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{applicationId}/application-process-updated',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.currentProcess],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const noteListUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'note-list-updated',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const deleteBankAccountEventOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'delete-bankaccount-event',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const sMSEventOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'sms-event',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const primaryBankAccountChangedEventOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'primary-bank-account-changed-event',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const nachaFileUpdateEventOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'nacha-file-update-event',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const enableDebtorEventOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{loanId}/enable-debtor',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.debtorId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const logoutCustomerNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'logout-customer-notification',
  queryParameters: [Parameters.customerId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateCustomerListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'update-customer-list',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateLoanBalanceAndSettingOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{offerId}/update-balance-and-setting',
  urlParameters: [Parameters.offerId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateLoanSettingOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{offerId}/update-loan-setting',
  urlParameters: [Parameters.offerId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateLoanBalanceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{offerId}/update-loan-balance',
  urlParameters: [Parameters.offerId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateNewTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{loanId}/update-transactions',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const reprocessCompleteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{loanId}/reprocess-complete',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateDebtorListsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{loanId}/debtor-updated',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const enableLoanSignalOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{loanId}/enable-loan-signal',
  urlParameters: [Parameters.loanId0],
  queryParameters: [Parameters.enable1, Parameters.reason],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateNewAdvancesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: '{loanId}/update-advances',
  urlParameters: [Parameters.loanId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const auditInvoiceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'audit-invoice',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const customerCreatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/customer/{customerId}/created',
  urlParameters: [Parameters.customerId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const customerRolesUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'customer-roles-updated',
  queryParameters: [Parameters.customerId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const insightNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'insight-notification',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const dashboardBankfeedAccountsUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'dashboard-bankfeed-accounts-updated',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const notifyPartnerPasswordResetCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'notify-partner-password-reset',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'String'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updatePartnerListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'update-partner-list',
  queryParameters: [Parameters.partnerId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateAgentListOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'update-agent-list',
  queryParameters: [Parameters.agentId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const investorListNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'investor-list-notification',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const partnerCommunicationUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'partner-communication-updated-notification',
  queryParameters: [Parameters.partnerId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const partnerApplicationCommunicationUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'partner-application-communication-updated-notification',
  queryParameters: [Parameters.partnerId1, Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const agentCommunicationUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'agent-communication-updated-notification',
  queryParameters: [Parameters.agentId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const agentApplicationCommunicationUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'agent-application-communication-updated-notification',
  queryParameters: [Parameters.agentId0, Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadMissingTransactionCompleteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'upload-missingtransaction-complete',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const uploadMissingTransactionFailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'upload-missingtransaction-fail',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const bankStatementMissingTransactionUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'bankstatement-missingtransaction-updated',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const documentUploadedEventOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'document-uploaded-event',
  queryParameters: [Parameters.applicationId1, Parameters.fileName],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const createEmptyOfferEventOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'create-empty-offer-event',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateAlertNotificationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'update-alert-notifications',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/StaleFinancialData/build-all',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/StaleFinancialData/build',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildApplicationSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Summary/build-application-summary',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildLoanSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Summary/build-loan-summary',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const communicationRequestStringOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/SummaryRequest/communication-request-string',
  queryParameters: [Parameters.request],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const applicationRequestStringOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/SummaryRequest/application-request-string',
  queryParameters: [Parameters.request],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const applicationResultRequestStringOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/SummaryRequest/application-result-request-string',
  queryParameters: [Parameters.request],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const loanRequestStringOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/SummaryRequest/loan-request-string',
  queryParameters: [Parameters.request],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const applicationCommunicationRequestStringOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/SummaryRequest/application-communication-request-string',
  queryParameters: [Parameters.request],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const addSupportingDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/SupportingDocument/{applicationId}/add-supporting-document',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.supportingDocumentId, Parameters.fileType, Parameters.fileName, Parameters.isSecondary],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'ByteArray'
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addSupportingDocumentDirectlyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/SupportingDocument/{applicationId}/add-supporting-document-directly',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.fileType, Parameters.fileName, Parameters.details, Parameters.isSecondary],
  formDataParameters: [Parameters.fileData],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addSupportingDocumentDirectOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/SupportingDocument/{applicationId}/add-supporting-document-direct',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.supportingDocumentId, Parameters.isSecondary],
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const generateDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/SupportingDocument/{applicationId}/generate-document',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.isSecondary],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addRequestSupportingDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/SupportingDocument/add-request-supporting-document',
  queryParameters: [Parameters.title, Parameters.forSecondary],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeRequestSupportingDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/SupportingDocument/remove-request-supporting-document',
  queryParameters: [Parameters.id3],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeSupportingDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/SupportingDocument/{applicationId}/remove-supporting-document',
  urlParameters: [Parameters.applicationId0],
  queryParameters: [Parameters.documentId1, Parameters.locationId, Parameters.isSecondary],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addAdminTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Todo/add-admin-todo',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.TodoItem
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateAdminTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'PUT',
  path: 'api/Todo/update-admin-todo/{id}',
  urlParameters: [Parameters.id4],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.TodoItem
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    204: {},
    400: {
      bodyMapper: Mappers.ProblemDetails
    },
    404: {
      bodyMapper: Mappers.ProblemDetails
    },
    default: {
      bodyMapper: Mappers.ProblemDetails
    }
  },
  serializer
};

const deleteAdminTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Todo/delete-admin-todo/{id}',
  urlParameters: [Parameters.id4],
  responses: {
    200: {},
    400: {
      bodyMapper: Mappers.ProblemDetails
    },
    404: {
      bodyMapper: Mappers.ProblemDetails
    },
    default: {
      bodyMapper: Mappers.ProblemDetails
    }
  },
  serializer
};

const addAgentTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Todo/add-agent-todo',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.TodoItem
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateAgentTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'PUT',
  path: 'api/Todo/update-agent-todo/{id}',
  urlParameters: [Parameters.id4],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.TodoItem
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    204: {},
    400: {
      bodyMapper: Mappers.ProblemDetails
    },
    404: {
      bodyMapper: Mappers.ProblemDetails
    },
    default: {
      bodyMapper: Mappers.ProblemDetails
    }
  },
  serializer
};

const deleteAgentTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Todo/delete-agent-todo/{id}',
  urlParameters: [Parameters.id4],
  responses: {
    200: {},
    400: {
      bodyMapper: Mappers.ProblemDetails
    },
    404: {
      bodyMapper: Mappers.ProblemDetails
    },
    default: {
      bodyMapper: Mappers.ProblemDetails
    }
  },
  serializer
};

const addPartnerTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Todo/add-partner-todo',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.TodoItem
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updatePartnerTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'PUT',
  path: 'api/Todo/update-partner-todo/{id}',
  urlParameters: [Parameters.id4],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.TodoItem
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    204: {},
    400: {
      bodyMapper: Mappers.ProblemDetails
    },
    404: {
      bodyMapper: Mappers.ProblemDetails
    },
    default: {
      bodyMapper: Mappers.ProblemDetails
    }
  },
  serializer
};

const deletePartnerTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Todo/delete-partner-todo/{id}',
  urlParameters: [Parameters.id4],
  responses: {
    200: {},
    400: {
      bodyMapper: Mappers.ProblemDetails
    },
    404: {
      bodyMapper: Mappers.ProblemDetails
    },
    default: {
      bodyMapper: Mappers.ProblemDetails
    }
  },
  serializer
};

const addPartnerBusinessTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Todo/add-partner-business-todo',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.TodoItem
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updatePartnerBusinessTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'PUT',
  path: 'api/Todo/update-partner-business-todo/{id}',
  urlParameters: [Parameters.id4],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.TodoItem
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    204: {},
    400: {
      bodyMapper: Mappers.ProblemDetails
    },
    404: {
      bodyMapper: Mappers.ProblemDetails
    },
    default: {
      bodyMapper: Mappers.ProblemDetails
    }
  },
  serializer
};

const deletePartnerBusinessTodoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'DELETE',
  path: 'api/Todo/delete-partner-business-todo/{id}',
  urlParameters: [Parameters.id4],
  responses: {
    200: {},
    400: {
      bodyMapper: Mappers.ProblemDetails
    },
    404: {
      bodyMapper: Mappers.ProblemDetails
    },
    default: {
      bodyMapper: Mappers.ProblemDetails
    }
  },
  serializer
};

const setSiteOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/WhiteLabel/set-site',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.SiteDetails
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const rebuildCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/WhiteLabel/rebuild-white-label-cache',
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadBackGroudImageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/WhiteLabel/upload-backgroundimage',
  queryParameters: [Parameters.partnerId1, Parameters.fileName],
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'SiteDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const uploadLogoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/WhiteLabel/upload-logo',
  queryParameters: [Parameters.partnerId1, Parameters.fileName],
  formDataParameters: [Parameters.uploadedFile],
  contentType: 'multipart/form-data',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'SiteDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const deleteBackGroundImageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/WhiteLabel/delete-backgroundimage',
  queryParameters: [Parameters.partnerId1, Parameters.fileName],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'SiteDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const deleteLogoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/WhiteLabel/delete-logo',
  queryParameters: [Parameters.partnerId1, Parameters.fileName],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'SiteDetails'
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

export {
  OriginationWriteApi,
  OriginationWriteApiContext,
  Models as OriginationWriteApiModels,
  Mappers as OriginationWriteApiMappers
};
