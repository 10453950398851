<div class="d-flex flex-column gap-large w-100 justify-content-center app-loader-component" style="height: 100vh;">
  <div class="d-flex flex-column justify-content-between align-items-center gap-small">
    <span class="title">Please wait</span>
    <span class="text-medium">{{ message }}</span>
  </div>
  <app-plexe-loader *ngIf="!progressBarData"></app-plexe-loader>
  <div class="w-100" *ngIf="progressBarData">
    <div class="start-progress-bar">
      <div class="start-progress-line" [style.width.%]="customeProgressBarValue"></div>
    </div>
  </div>
  <div class="mt-40">
    <button class="plexe-btn-primary" *ngIf="showButton" (click)="navHome()">
      Home
    </button>
  </div>
</div>
