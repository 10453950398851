import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SystemService } from '@app/core/services/system.service';
import { ApplicationService } from '@app/core/services/application.service';
import { AuthenticationService } from '../../core/services/authentication.service';
import { UserService } from '@app/core/services/user.service';
import { LoanService } from '@app/core/services/loan.service';
import { DataStore, DataState } from '@app/DataStore';

@Injectable()
export class ProfileResolverService implements Resolve<any> {
  constructor(
    private systemService: SystemService,
    private applicationService: ApplicationService,
    private loanService: LoanService,
    private userService: UserService,
    public authenticationService: AuthenticationService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const appData = this.authenticationService.getSelectedAppData();

    if (!DataStore.application) {
      this.systemService.addLoadingPromise(this.applicationService.loadApplication(appData.id));
    }
    if (!DataStore.customerData.loans && appData.loanId) {
      const id = appData.customerId ?? this.authenticationService.credentials.id;
      this.systemService.addLoadingPromise(this.userService.loadCustomerLoans(id));
    }

    // if (DataStore.customerData.displayedLoan.details.state !== DataState.Loaded) {
    //     this.systemService.addLoadingPromise(this.loanService.loadLoanDetails(appData.id));
    // }
  }
}
