<!-- <app-system-notification></app-system-notification> -->
<app-plexe-loader [isMainLoader]="true" *ngIf="isLoading"></app-plexe-loader>
<div
  class="container-fluid login-page-wrapper d-flex flex-column justify-content-between gap-large text"
  *ngIf="!isInit"
  [@fadeInOut]
>
  <div class="d-flex flex-column gap-smallest align-items-center mt-5">
    <img src="assets/icons/Logo.svg" alt="logo" class="logo" />
    <span class="title text-center">Empower your business to grow</span>
  </div>
  <div class="d-flex flex-column justify-content-center align-items-center gap-small">
    <form
      novalidate
      (ngSubmit)="login()"
      [formGroup]="loginForm"
      *ngIf="!isSmsLogin; else smsBlock"
      class="d-flex flex-column align-items-center gap-medium w-100"
    >
      <div class="form-floating w-100 maxWidth">
        <input
          data-lpignore="true"
          type="email"
          class="form-control bg-transparent py-4"
          placeholder="Email"
          formControlName="email"
          (input)="clearErrors()"
        />
        <label for="email">Email</label>
        <div
          class="text-danger position-absolute f-12"
          *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors"
        >
          <span *ngIf="loginForm.controls.email.errors.required">Email is required</span>
          <span *ngIf="loginForm.controls.email.errors.email">Please enter valid email id</span>
        </div>
        <ng-container *ngIf="!loginForm.controls.email.errors">
          <div class="text-danger position-absolute f-12" *ngIf="invalidMobileNumber">
            <span>Email is invalid or not found. Please try again.</span>
          </div>
          <div class="text-danger position-absolute f-12" *ngIf="failedCode">
            <span>Failed to send code. Please try again.</span>
          </div>
        </ng-container>
      </div>
      <button
        type="submit"
        class="btn btn-primary d-flex align-items-center gap-smallest"
        [disabled]="loginForm.invalid || isLoading"
      >
        <span class="spinner-border spinner-border-sm" *ngIf="isLoading"></span>Login via SMS
      </button>
    </form>
    <ng-template #smsBlock>
      <div class="d-flex flex-column gap-small">
        <div class="d-flex align-items-center gap-smallest">
          <span class="code-title">We sent an SMS to </span>
          <span class="sentto"
            >+1 {{ loginForm.value.mobileNumber | mask: (isUSRegion ? '(000) 000-0000' : '00 000 000') }}</span
          >
        </div>
        <code-input
          #codeInput
          [isCodeHidden]="false"
          [codeLength]="4"
          (codeCompleted)="goToComplete($event)"
          [initialFocusField]="0"
          autocomplete="new-password"
        >
        </code-input>

        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="text-danger" *ngIf="failed" [@fadeInOut]>
            <span>Invalid code. Please try again.</span>
          </div>
          <div class="resend">
            <span>Didn't receive the code?</span>
            <a class="btn-link" [class.disabled]="timeLeft > 0" (click)="resend()">Resend</a>
            <span *ngIf="timeLeft > 0"
              >in <span class="time-left">{{ timeDisplay }}</span></span
            >
          </div>
        </div>
      </div>
    </ng-template>
    <a routerLink="/login" (click)="loginWithPassword()">Login with password</a>
    <span class="text-center">Don't have an account? <a routerLink="/application">Sign up</a></span>
    <span class="text-center">Forgot your password? <a routerLink="/forgot-password">Forgot password</a></span>
  </div>
  <div class="d-flex flex-column footer justify-content-center align-items-center gap-smallest">
    <span>If you need help</span>
    <a [href]="contactUsUrl" target="_blank">Contact us <i class="bi bi-caret-right-fill"></i></a>
  </div>
</div>
