import { NgModule, Optional, SkipSelf } from '@angular/core';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { SignalrService } from '@app/core/services/signalr.service';
import { RouteReusableStrategy } from '@app/core/route-reusable-strategy';
import { LoadDataComponent } from '@app/core/load-data/load-data.component';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';
import { SharedModule } from '@app/shared/shared.module';
import { ApplicationService } from '@app/core/services/application.service';
import { CreditService } from '@app/core/services/credit.service';
import { LoanService } from '@app/core/services/loan.service';
import { SystemService } from '@app/core/services/system.service';
import { UserService } from '@app/core/services/user.service';
import { DataService } from '@app/core/services/data.service';
import { ApplicationInsightsService } from '@app/core/services/application-insights.service';
import { HomePageRedirectGuard } from '@app/core/guards/home-page-redirect.guard';
import { OriginationWriteApi } from './proxy/Write/originationWriteApi';
import { OrganisationService } from './services/organisation.service';
import { DashboardService } from './services/dashboard.service';

@NgModule({
  imports: [RouterModule, SharedModule],
  declarations: [LoadDataComponent],
  providers: [
    AppNavigatorService,
    ApplicationService,
    CreditService,
    LoanService,
    SystemService,
    UserService,
    DataService,
    OrganisationService,
    HomePageRedirectGuard,
    DashboardService,
    {
      provide: ApplicationInsightsService,
      useFactory: ApplicationInsightsFactory,
      deps: [Router]
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    },
    {
      provide: SignalrService,
      useFactory: SignalRServiceFactory,
      deps: [OriginationWriteApi]
    }
  ],
  exports: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}

export function SignalRServiceFactory(writeApi: OriginationWriteApi) {
  return new SignalrService(window['appConfig']['writeApiUrl'], writeApi);
}

export function ApplicationInsightsFactory(router: Router) {
  return new ApplicationInsightsService(router);
}
