import { Component, Input, OnInit } from '@angular/core';
import { DataStore } from '@app/DataStore';
import { LoaderService } from '@app/shared/loader/loader.service';
import { ToastrService } from 'ngx-toastr';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';
import { ApplicationService } from '@app/core/services/application.service';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class PublicHeaderComponent implements OnInit {
  @Input() showLoginButton = false;
  @Input() showCancelButton = true;
  @Input() addMobileCss = false;

  isMobileSize = false;
  whitelable = false;
  contactUsUrl = `${window['appConfig']['contactUsUrl']}`;
  homeUrl = window['appConfig']['homeUrl'];
  constructor(
    private loaderService: LoaderService,
    private toastrService: ToastrService,
    private appNavigatorService: AppNavigatorService,
    private applicationService: ApplicationService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    this.checkWhiteLable();
  }

  get duringCancelableApplication() {
    return (
      !!DataStore.application &&
      DataStore.application.status !== 'UnderReview' &&
      DataStore.application.status !== 'ApplicationCompleted'
    );
  }

  get appMessage() {
    return DataStore.systemMessage;
  }

  ngOnInit() {}

  cancelApplication() {
    this.loaderService.setMessage('Canceling application...');
    this.loaderService.setStatus(true);

    this.applicationService
      .cancelApplication(DataStore.application.id)
      .then(() => new Promise(resolve => setTimeout(resolve, 2000)))
      .then(_ => {
        return this.appNavigatorService.navigateDefault();
      })
      .catch(() => this.toastrService.error('Failed to cancel application'))
      .then(() => this.loaderService.setStatus(false));
  }

  logout() {
    this.authenticationService.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  private checkWhiteLable() {
    this.whitelable = JSON.parse(localStorage.getItem('whitelabel_enabled'));
  }

  onClickMenuItem() {
    // alert("test");
    const navbarCollapse = document.getElementById('navbarSupportedContent');
    if (navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  }

  get isAuthenticated() {
    return this.authenticationService.isAuthenticatedValid();
  }
}
